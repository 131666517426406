export const LoginArtwork = require('./login-artwork.svg').default
export const Logo = require('./logo.png')
export const Checked = require('./ic-checked.svg').default
export const Unchecked = require('./ic-unchecked.svg').default
export const Bill = require('./ic-bill.svg').default
export const BillActive = require('./ic-bill-active.svg').default
export const Reviews = require('./ic-reviews.svg').default
export const ReviewsActive = require('./ic-reviews-active.svg').default
export const ChartWhite = require('./ic-chart-white.svg').default
export const Down = require('./ic-down.svg').default
export const FoodDelivery = require('./ic-food-delivery.svg').default
export const FoodDeliveryActive =
  require('./ic-food-delivery-active.svg').default
export const HelpIcon = require('./ic-help-icon.svg').default
export const Home = require('./ic-home.svg').default
export const HomeActive = require('./ic-home-active.svg').default
export const Info = require('./info.svg').default
export const Insight = require('./ic-insight.svg').default
export const InsightActive = require('./ic-insight-active.svg').default
export const MenuIcon = require('./ic-menu-icon.svg').default
export const MoneyBagWhite = require('./ic-money-bag-white.svg').default
export const MoneyBag = require('./ic-money-bag.svg').default
export const MoneyBagActive = require('./ic-money-bag-active.svg').default
export const Notification = require('./ic-notification.svg').default
export const Payment = require('./ic-payment.svg').default
export const PaymentActive = require('./ic-payment-active.svg').default
export const QRCode = require('./ic-QRcode.svg').default
export const QRCodeActive = require('./ic-QRcode-active.svg').default
export const Report = require('./ic-report.svg').default
export const ReportActive = require('./ic-report-active.svg').default
export const RestaurantMenu = require('./ic-restaurant-menu.svg').default
export const RestaurantMenuActive =
  require('./ic-restaurant-menu-active.svg').default
export const Setting = require('./ic-setting.svg').default
export const SettingActive = require('./ic-setting-active.svg').default
export const TrayWhite = require('./ic-tray-white.svg').default
export const UserFill = require('./ic-user-fill.svg').default
export const UserWhite = require('./ic-user-white.svg').default
export const User = require('./ic-user.svg').default
export const UserActive = require('./ic-user-active.svg').default
export const Chart1 = require('./chart1.png')
export const Chart2 = require('./chart2.png')
export const Chart3 = require('./chart3.png')
export const Chart4 = require('./chart4.png')
export const UsermenuDownarrow = require('./ic-usermenu-downarrow.svg').default
export const PlusWhite = require('./ic-plus-white.svg').default
export const CsvFile = require('./ic-csv-file.svg').default
export const Filter = require('./ic-filter.svg').default
export const Calendar = require('./ic-calendar.svg').default
export const FileUploadIcon = require('./ic-file-upload.svg').default
export const Move = require('./ic-move.svg').default
export const Edit = require('./ic-edit.svg').default
export const Delete = require('./ic-delete.svg').default
export const Inactivate = require('./inactive.png')
export const List = require('./ic-list.svg').default
export const MobileFrame = require('./mobile-frame.png')
export const More = require('./ic-more.svg').default
export const Close = require('./ic-close.svg').default
export const PDF = require('./ic-pdf.svg').default
export const DeleteGrey = require('./ic-delete-grey.svg').default
export const AddSmall = require('./ic-add-small.svg').default
export const SortDescending = require('./ic-sort-desc.svg').default
export const SortAscending = require('./ic-sort-asc.svg').default
export const MoreSmall = require('./ic-more-small.svg').default
export const AddUser = require('./ic-add-user.svg').default
export const BackArrow = require('./ic-back-arrow.svg').default
export const RestaurantLogo = require('./restaurant-logo.png')
export const View = require('./ic-view.svg').default
export const Download = require('./ic-download.svg').default
export const DownloadDark = require('./ic-download-dark.svg').default
export const DownloadWhite = require('./ic-download-white.svg').default
export const MobileLogo = require('./mobile-logo.png')
export const Barcode = require('./barcode.png')
export const BarcodeSmall = require('./barcode-small.png')
export const Establishments = require('./ic-establishments.svg').default
export const Location = require('./ic-location.svg').default
export const Guest = require('./ic-guest.svg').default
export const GuestActive = require('./ic-guest-active.svg').default
export const AddUserBold = require('./ic-add-user-bold.svg').default
export const MenuClone = require('./ic-menu-clone.svg').default
export const Warning = require('./ic-warning.svg').default
export const AddPrimary = require('./ic-plus-primary.svg').default
export const Search = require('./ic-search.svg').default
export const CircularText = require('./circular-text.svg').default
export const ArrowBlue = require('./ic-arrow-blue.svg').default
export const ArrowBlueSmall = require('./ic-arrow-blue-small.svg').default
export const RestaurantMenuLarge =
  require('./ic-restaurant-menu-large.svg').default
export const EstablishmentsActive =
  require('./ic-establishments-active.svg').default
export const Link = require('./ic-link.svg').default
export const ArrowDown = require('./arrow-down.svg').default
export const BarcodeNew = require('./barcode-new.png')
export const FTBrandBlack = require('./ft-brand-black.png')
export const FastabLogo = require('./fastab-logo.png')
export const BulkQr = require('./bulkqrtemp.svg').default
export const PayoutActive = require('./ic-payout-active.svg').default
export const Payout = require('./ic-payout.svg').default
export const Pos = require('./pos.svg').default
export const PosColor = require('./pos-color.svg').default
export const LogoColor = require('./logo-color.svg').default
export const LogoOutline = require('./logo-outline.svg').default
export const ReportMenu = require('./ic-report-menu.svg').default
export const ReportMenuActive = require('./ic-report-active.svg').default
export const GenerateDisbursement =
  require('./generate-disbursement.svg').default
export const DownloadDisbursement =
  require('./download-disbursement.svg').default
export const Disabled = require('./disabled.svg').default
export const Enabled = require('./enabled.svg').default
export const PaymentOn = require('./ic-fill-check.svg').default
export const PaymentOff = require('./ic-fill-close.svg').default
export const FilterNew = require('./ic-filter-new.svg').default
export const ExportNew = require('./ic-export-new.svg').default
export const StarNormal = require('./ic-star-normal.svg').default
export const StarFilled = require('./ic-star-filled.svg').default
export const ServiceUp = require('./fi_bar-chart-1.svg').default
export const ServiceDown = require('./fi_bar-chart-2.svg').default
export const TimeIcon = require('./ic-time.svg').default
export const AllPlatformIcon = require('./ic-all-platform.svg').default
export const StarIcon = require('./ic-all-star.svg').default
export const FastabLogoText = require('./fastab-logo-text.svg').default
export const FastabLogoIcon = require('./fastab-logo-icon.svg').default
export const GoogleLogo = require('./google-logo.svg').default
export const ArrowRight = require('./arrow-right.svg').default
export const IcPrev = require('./ic-prev.svg').default
export const IcNext = require('./ic-next.svg').default
export const IcPromoCode = require('./promocode.svg').default
export const IcPromoCodeHover = require('./promocode-hover.svg').default
export const IcEnablePromoCode = require('./Enable-Promo-Code.svg').default
export const IcDisablePromoCode = require('./Disable-Promo-Code.svg').default
export const AlertImage = require('./ic-alert.svg').default
export const Comments = require('./comments.svg').default
export const Revenue = require('./ic-revenue.svg').default
export const Chart5 = require('./chart5.png')
export const Chart6 = require('./chart6.png')
export const StarRating = require('./ic-star-rating.svg').default
export const Review = require('./ic-review.svg').default
export const DonutChart1 = require('./donutChart1.png')
export const DonutChart2 = require('./donutChart2.png')
export const DonutChart3 = require('./donutChart3.png')
export const DonutChart4 = require('./donutChart4.png')
export const DonutChart5 = require('./donutChart5.png')
export const DonutChart6 = require('./donutChart6.png')
export const Table = require('./ic-table.svg').default
export const RefundImg = require('./refund-svg.svg').default
export const CopyText = require('./copy-link.svg').default
export const SquareUser = require('./ic-square-user.svg').default
export const SquareUserActive = require('./ic-square-user-active.svg').default
export const UserPrimary = require('./ic-user-primary.svg').default
export const CoinPrimary = require('./ic-coin-primary.svg').default
export const GuestLocationMap = require('./guest-location-map.png')
export const Calendar2 = require('./ic-calendar-2.svg').default
export const Upload = require('./ic-upload.svg').default
export const SearchGrey = require('./ic-search-grey.svg').default
export const UserGrey = require('./ic-user-grey.svg').default
export const LocationGrey = require('./ic-location-grey.svg').default
export const LinkPrimary = require('./ic-link-primary.svg').default
export const Campaign1 = require('./campaign1.png')
export const Campaign2 = require('./campaign2.png')
export const Campaign3 = require('./campaign3.png')
export const CloseSmall = require('./ic-close-small.svg').default
export const LargeLogoIcon = require('./large-logo-icon.png')
export const CaretDown = require('./ic-caret-down.svg').default
export const DragIcon = require('./ic-drag.svg').default
export const TextIcon = require('./ic-text.svg').default
export const ProductIcon = require('./ic-product.svg').default
export const SocialLinksIcon = require('./ic-social-links.svg').default
export const ImagesIcon = require('./ic-images.svg').default
export const SpacerIcon = require('./ic-spacer.svg').default
export const DividerIcon = require('./ic-divider.svg').default
export const TableIcon = require('./ic-table.svg').default
export const ButtonIcon = require('./ic-button.svg').default
export const HeaderBarIcon = require('./ic-header-bar.svg').default
export const ColumnsIcon = require('./ic-columns.svg').default
export const SectionIcon = require('./ic-section.svg').default
export const RowsIcon = require('./ic-rows.svg').default
export const Star = require('./ic-star.svg').default
export const Copy = require('./ic-copy.svg').default
export const DeleteSmall = require('./ic-delete-small.svg').default
export const ContentBuilderImage = require('./content-builder-image.png')
export const Facebook = require('./ic-facebook.svg').default
export const Linkedin = require('./ic-linkedin.svg').default
export const Instagram = require('./ic-instagram.svg').default
export const OutlineCheck = require('./ic-outline-check.svg').default
export const Card = require('./ic-card.svg').default
export const Map = require('./map.png')
export const GMRestaurant = require('./gm-restaurant.png')
export const CircleS = require('./circle-s.png')
export const SendMsg = require('./send-svg.svg').default
export const InstagramSVG = require('./insgramSvg.svg').default
export const ThreadsSVG = require('./ThreadsSVG.svg').default
export const FacebookSVG = require('./facebookSVG.svg').default
export const LinkedinSVG = require('./linkedInSVG.svg').default
export const socialMediaSVG = require('./socialMedia.svg').default
export const XSVG = require('./Xsvg.svg').default
export const IHOP = require('./ihop.png')
export const NewsLetterTemplate = require('./newsLetterTemplate.png')
export const PromotionTemplate = require('./promotionTemplate.png')
export const SimpleTemplate = require('./simpleTemplate.png')
export const WelcomeTemplate = require('./welcomeTemplate.png')
export const BlankTemplate = require('./blank.png')
