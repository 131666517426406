/* eslint-disable no-nested-ternary */
import { Collapse, Grid, Menu, MenuItem } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import Aos from 'aos'
import {
  DeleteGrey,
  Disabled,
  Download,
  DownloadDark,
  Enabled,
  ExportNew,
  FilterNew,
  PaymentOff,
  PaymentOn,
  PlusWhite,
} from 'assets/images'
import AddEditTableDialog from 'components/AddEditTableDialog/Index'
import BulkQrCodeDialog from 'components/BulkQrCodeDialog/Index'
import Button from 'components/Button/Index'
import EnableDisablePaymentDialog from 'components/EnableDisablePaymentDialog/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import Layout from 'components/Layout/Layout'
import QRCodeDialog from 'components/QRCodeDialog/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import { DialogOptions } from 'enums/Common'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux/es/exports'
import { toast } from 'react-toastify'
import qrService from 'services/qr.service'

Aos.init({
  duration: 600,
  once: true,
})

function QRCodes() {
  const [filterToggle, setFilterToggle] = useState(false)
  const [qrCodeList, setQrCodeList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [establishmentId, setEstablishmentId] = useState(0)
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'restaurantName',
        sort: 'asc',
      },
    ],
  })
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [filterTableName, setFilterTableName] = useState('')
  const [baseQr, setBaseFOrQr] = useState('')
  const [restaurantLogo, setRestaurantLogo] = useState('')
  const [tableId, setTableId] = useState(0)
  const [isPaymentEnableDisable, setIsPaymentEnableDisable] = useState(false)
  const [paymentId, setPaymentId] = useState(0)
  // QRCode dialog
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false)
  const [openBulkQRCodeDialog, setOpenBulkQRCodeDialog] = useState(false)
  const closeQRCodeDialog = () => {
    setOpenQRCodeDialog(false)
  }
  const closeBulkQRCodeDialog = () => {
    setOpenBulkQRCodeDialog(false)
  }

  const [openEnableDisableAlert, setOpenEnableDisableAlert] = useState(false)
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false)
  const closeEnableDisablePaymentsDialog = () => {
    setOpenEnableDisableAlert(false)
  }
  // action-menu
  const [actionAnchorEl, setActionAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const FILTER_PAYMENT_STATUS = { YES: 1, NO: 2, ALL: 0 }
  const openActionMenu = Boolean(actionAnchorEl)
  const getPaymentStatus = (paymentStatusId: number) => {
    if (paymentStatusId === 0) {
      return null
    }
    if (paymentStatusId === 1) {
      return true
    }
    return false
  }
  const getQrCodeList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      tableName: string,
      paymentStatusId: number
    ) => {
      try {
        const data = await qrService.getAllQrCodes({
          pageNo,
          pageSize,
          restaurantId:
            selectedRestaurantId === null ? 0 : selectedRestaurantId,
          sortGridModels: sortOrder.map((d) => {
            return {
              field: d.field,
              sort: d.sort === 'asc' ? 1 : 0,
            }
          }),
          tableName,
          isPaymentOn: getPaymentStatus(paymentStatusId),
          establishmentId:
            selectedEstablishmentId === null ? 0 : selectedEstablishmentId,
        })
        setQrCodeList(data.data.data.data)
        // if (selectedEstablishmentId !== null && selectedEstablishmentId !== 0) {
        //   let myArray = []
        //   myArray = data.data.data.data.filter(
        //     (ar) => ar.establishmentId === selectedEstablishmentId
        //   )
        //   setQrCodeList(
        //     myArray.sort((a, b) => {
        //       return a.defaultSortOrder - b.defaultSortOrder
        //     })
        //   )
        // } else {
        //   setQrCodeList(
        //     data.data.data.data.sort((a, b) => {
        //       return a.defaultSortOrder - b.defaultSortOrder
        //     })
        //   )
        // }
        // if (totalRecords === 0) {
        setTotalRecords(data.data.data.totalRecords)
        // }
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedEstablishmentId]
  )

  const handleEnableDisablePaymentChange = (isEnable: boolean) => {
    if (selectionModel.length === 0) {
      toast.error(
        `Please select Table(s) to ${isEnable ? 'enable' : 'disable'} Payments.`
      )
    } else {
      setIsPaymentEnableDisable(isEnable)
      setOpenEnableDisableAlert(true)
    }
  }

  // AddEditTable dialog
  const [openAddEditTableDialog, setOpenAddEditTableDialog] = useState(false)
  const closeAddEditTableDialog = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLElement>,
    option?: DialogOptions
  ) => {
    setOpenAddEditTableDialog(false)
    if (option === DialogOptions.Yes) {
      getQrCodeList(
        dataGridOptions.pageNumber,
        dataGridOptions.pageSize,
        dataGridOptions.sortOrder,
        filterTableName,
        FILTER_PAYMENT_STATUS.ALL
      )
    }
  }

  useEffect(() => {
    getQrCodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      filterTableName,
      FILTER_PAYMENT_STATUS.ALL
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId, selectedEstablishmentId])

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const onSelectionModelChange = (selectionModelData: GridSelectionModel) => {
    setSelectionModel(selectionModelData)
    if (selectionModelData.length !== 0) {
      setIsPaymentEnabled(true)
    } else {
      setIsPaymentEnabled(false)
    }
  }

  // const actionMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setActionAnchorEl(event.currentTarget)
  // }
  const actionMenuClose = () => {
    setActionAnchorEl(null)
  }

  const [qrCodeUrl, setUrl] = useState('')
  const [tableName, setTableName] = useState('')
  const [isDemoTabPage, setIsDemoTabPage] = useState(false)
  const handleOpenQrdialog = async (d: number) => {
    const currentTable = qrCodeList.find((data) => data.tableId === d)
    let generatedUrl = `${process.env.REACT_APP_ESTABLISHMENT_QR_URL}/?pId=${
      currentTable.posTableId
    }&tn=${currentTable.name.replace(' ', '_')}&tid=${
      currentTable.tableId
    }&eId=${currentTable.establishmentId}`
    if (currentTable.isDemoTab) {
      generatedUrl += `&isDemoTab=true`
    }
    setUrl(generatedUrl)
    setTableName(currentTable.name)
    setTableId(currentTable.tableId)
    const currTableId = Number(currentTable.tableId)
    const byteArraySticker = await qrService.getQrCodeBytes({
      tableId: currTableId,
      isDemoTab: currentTable.isDemoTab,
    })
    setIsDemoTabPage(currentTable.isDemoTab)
    setEstablishmentId(currentTable.establishmentId)
    setBaseFOrQr(byteArraySticker.data.data.tableURL)
    setOpenQRCodeDialog(true)
    setRestaurantLogo(byteArraySticker.data.data.restaurantLogo)
  }

  const GoToTab = async (d: number) => {
    const currentTable = qrCodeList.find((data) => data.tableId === d)
    let generatedUrl = `${process.env.REACT_APP_ESTABLISHMENT_QR_URL}/?pId=${
      currentTable.posTableId
    }&tn=${currentTable.name.replace(' ', '_')}&tid=${
      currentTable.tableId
    }&eId=${currentTable.establishmentId}`
    if (currentTable.isDemoTab) {
      generatedUrl += `&isDemoTab=true`
    }
    window.open(generatedUrl, '_blank').focus()
  }
  const getQRCodeColDef = (): GridColDef[] => {
    return [
      {
        field: 'restaurantName',
        headerName: 'Restaurant Name',
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'establishmentName',
        headerName: 'Location Name',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'name',
        headerName: 'Table',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'tableCode',
        headerName: 'Table Code',
        flex: 1,
        minWidth: 150,
      },
      {
        align: 'center',
        headerAlign: 'center',
        field: 'isPaymentOn',
        headerName: 'Payment Enabled',
        renderCell: (d: GridRenderCellParams<boolean>) => {
          return d.value === false ? (
            <img src={PaymentOff} alt="Download" />
          ) : (
            <img src={PaymentOn} alt="Download" />
          )
        },
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'tableId',
        disableColumnMenu: true,
        headerName: 'Action',
        sortable: false,
        renderCell: (d: GridRenderCellParams<number>) => {
          return (
            <div className="action-buttons">
              <Button
                className="icon-btn small rounded light-bordered"
                variant="text"
                color="inherit"
                title="Download"
                onClick={() => {
                  handleOpenQrdialog(d.value)
                }}
              >
                <img src={DownloadDark} alt="Download" />
              </Button>
              <Button
                className="icon-btn small rounded light-bordered"
                variant="text"
                color="inherit"
                title="Open Table"
                onClick={() => {
                  GoToTab(d.value)
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.22202 19.778C4.6859 20.2425 5.23699 20.6108 5.84364 20.8617C6.45029 21.1126 7.10054 21.2412 7.75702 21.24C8.41365 21.2411 9.06404 21.1126 9.67085 20.8617C10.2777 20.6108 10.8289 20.2425 11.293 19.778L14.121 16.949L12.707 15.535L9.87902 18.364C9.31549 18.925 8.55269 19.24 7.75752 19.24C6.96235 19.24 6.19955 18.925 5.63602 18.364C5.07453 17.8007 4.75923 17.0378 4.75923 16.2425C4.75923 15.4472 5.07453 14.6843 5.63602 14.121L8.46502 11.293L7.05102 9.879L4.22202 12.707C3.28583 13.6455 2.76007 14.9169 2.76007 16.2425C2.76007 17.5681 3.28583 18.8395 4.22202 19.778V19.778ZM19.778 11.293C20.7137 10.3543 21.2392 9.08292 21.2392 7.7575C21.2392 6.43208 20.7137 5.16072 19.778 4.222C18.8396 3.28581 17.5681 2.76005 16.2425 2.76005C14.9169 2.76005 13.6455 3.28581 12.707 4.222L9.87902 7.051L11.293 8.465L14.121 5.636C14.6846 5.07499 15.4474 4.76003 16.2425 4.76003C17.0377 4.76003 17.8005 5.07499 18.364 5.636C18.9255 6.19927 19.2408 6.96217 19.2408 7.7575C19.2408 8.55283 18.9255 9.31573 18.364 9.879L15.535 12.707L16.949 14.121L19.778 11.293Z"
                    fill="#737373"
                  />
                  <path
                    d="M8.46401 16.95L7.04901 15.536L15.536 7.05L16.95 8.465L8.46401 16.95Z"
                    fill="#737373"
                  />
                </svg>
              </Button>
            </div>
          )
        },
        flex: 1,
        minWidth: 40,
      },
    ]
  }

  const filterTableNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterTableName(e.target.value)
  }

  const onFilterClear = () => {
    // setFilterToggle(false)
    setFilterTableName('')
    setPaymentId(0)
    getQrCodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      '',
      FILTER_PAYMENT_STATUS.ALL
    )
  }

  const onFilterApply = async () => {
    getQrCodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      filterTableName,
      paymentId
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  const downloadFile = async () => {
    const data = await qrService.downloadCsv({
      tableName: filterTableName,
      restaurantId: selectedRestaurantId,
      establishmentId: selectedEstablishmentId,
      isPaymentOn: getPaymentStatus(paymentId),
    })

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'QrCode'
    exportCSVFile(headers, data.data.data, fileTitle)
  }
  const ValidateSelection = async () => {
    if (selectionModel.length === 0) {
      toast.error('Please select QR Code(s) to download.')
    } else {
      const res = await qrService.getRestaurantLogo({
        tableId: +selectionModel[0],
      })
      setRestaurantLogo(res.data.data.restaurantLogo)
      setEstablishmentId(res.data.data.establishmentId)
      setOpenBulkQRCodeDialog(true)
    }
  }
  const handleContinueEvent = () => {
    getQrCodeList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      filterTableName,
      paymentId
    )
  }
  return (
    <>
      {/* Layout Start */}
      <Layout title="QR Codes | FasTab">
        {/* page-top start */}
        <div className="page-top long-right-content">
          <h3 className="page-title">QR Codes</h3>
          <div className="right">
            {/* <div className="toggle-payment-switch">
              <Switch
                placement="start"
                name="active"
                checked={isSwitchChecked}
                // onChange={(e) => handleEnableDisablePaymentChange(e)}
                defaultChecked={false}
              />
            </div> */}
            {/* <Button
              variant="contained"
              color="primary"
              title="Add/Update Table"
              size="small"
              onClick={() => {
                setOpenAddEditTableDialog(true)
              }}
            >
              <img src={PlusWhite} alt="ADD" />
              <span>Add/Update Table</span>
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              title="Enable Payment"
              size="small"
              className="btn-h-40"
              disabled={!isPaymentEnabled}
              onClick={() => handleEnableDisablePaymentChange(true)}
            >
              <img src={Enabled} alt="Enable" />
              <span>Enable Payment</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Disable Payment"
              size="small"
              className="btn-h-40"
              disabled={!isPaymentEnabled}
              onClick={() => handleEnableDisablePaymentChange(false)}
            >
              <img src={Disabled} alt="Disable" />
              <span>Disable Payment</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              title="Filter"
              disableFocusRipple
              size="small"
              className="btn-h-40"
              onClick={() => {
                setFilterTableName('')
                setFilterToggle(!filterToggle)
              }}
            >
              <img src={FilterNew} alt="Filter" />
              <span>Filter</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              size="small"
              title="Export"
              className="btn-h-40"
              onClick={() => downloadFile()}
            >
              <img src={ExportNew} alt="Filter" />
              <span>Export</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              size="small"
              title="Download"
              className="btn-h-40"
              onClick={() => ValidateSelection()}
            >
              <img src={Download} alt="Download" />
              <span>Download</span>
            </Button>
            {/* <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="More"
              onClick={actionMenuOpen}
              className="icon-btn action-btn"
            >
              <img src={MoreSmall} alt="More" />
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              title="Add/Update Table"
              size="small"
              onClick={() => {
                setOpenAddEditTableDialog(true)
              }}
            >
              <img src={PlusWhite} alt="ADD" />
              <span>Add/Update Table</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        {/* filter-panel start */}
        <Collapse in={filterToggle}>
          <div className="filter-panel">
            <div className="card">
              <Grid container spacing={5}>
                <Grid item lg={3} sm={6} xs={12}>
                  <div className="form-group">
                    <Textfield
                      label="Table Name"
                      variant="outlined"
                      value={filterTableName}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => filterTableNameChange(e)}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} md={6} sm={12}>
                  <div className="form-group">
                    <Select
                      label="Payment"
                      items={[
                        {
                          key: 'All',
                          value: FILTER_PAYMENT_STATUS.ALL,
                        },
                        {
                          key: 'Yes',
                          value: FILTER_PAYMENT_STATUS.YES,
                        },
                        {
                          key: 'No',
                          value: FILTER_PAYMENT_STATUS.NO,
                        },
                      ]}
                      formikValue={paymentId}
                      handleSelectValue={(item) => {
                        setPaymentId(item)
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="btn-list">
                <Button
                  variant="contained"
                  color="primary"
                  title="Apply"
                  onClick={onFilterApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  title="Clear All"
                  onClick={onFilterClear}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
        {/* filter-panel end */}

        <div className="card mb-30 p-0" data-aos="fade-up">
          {/* data-table start */}
          <FasTabGrid
            uniqueId="tableId"
            columnsData={getQRCodeColDef()}
            checkboxSelection
            gridData={qrCodeList}
            totalRecords={totalRecords}
            onGridPageChange={onPageChange}
            onGridPageSizeChange={onPageSizeChange}
            onGridSortChange={onSortChange}
            onSelectionModelChange={onSelectionModelChange}
          />
        </div>
        {/* data-table end */}
      </Layout>
      {/* Layout End */}

      {/* QRCode dialog */}
      <QRCodeDialog
        open={openQRCodeDialog}
        onClose={closeQRCodeDialog}
        url={qrCodeUrl}
        baseQr={baseQr}
        tableName={tableName}
        tableId={tableId}
        restaurantLogo={restaurantLogo}
        establishmentId={establishmentId}
        isDemoTab={isDemoTabPage}
      />
      <BulkQrCodeDialog
        open={openBulkQRCodeDialog}
        onClose={closeBulkQRCodeDialog}
        selectedTables={selectionModel}
        handleClose={closeBulkQRCodeDialog}
        establishmentId={establishmentId}
        restaurantLogo={restaurantLogo}
        isDemoTab={isDemoTabPage}
      />
      <EnableDisablePaymentDialog
        open={openEnableDisableAlert}
        onClose={closeEnableDisablePaymentsDialog}
        selectedTables={selectionModel}
        isPaymentOn={isPaymentEnableDisable}
        reloadList={handleContinueEvent}
      />

      {/* AddEditTable dialog */}
      <AddEditTableDialog
        open={openAddEditTableDialog}
        onClose={closeAddEditTableDialog}
      />

      {/* page-top action menu */}
      <Menu
        anchorEl={actionAnchorEl}
        open={openActionMenu}
        onClose={actionMenuClose}
        className="primary-menu action-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          title="Filter"
          onClick={() => {
            actionMenuClose()
            setFilterToggle(!filterToggle)
          }}
        >
          <img src={FilterNew} alt="Filter" />
          <span>Filter</span>
        </MenuItem>
        <MenuItem title="Export" onClick={actionMenuClose}>
          <img src={ExportNew} alt="File" />
          <span>Export</span>
        </MenuItem>
        <MenuItem title="Delete" onClick={actionMenuClose}>
          <img src={DeleteGrey} alt="Delete" />
          <span>Delete</span>
        </MenuItem>
        <MenuItem title="Download" onClick={actionMenuClose}>
          <img src={Download} alt="Download" />
          <span>Download</span>
        </MenuItem>
      </Menu>
    </>
  )
}

export default QRCodes
