/* eslint-disable no-unused-vars */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Textfield from 'components/Textfield/Index'
import { useFormik } from 'formik'
import { ICountry } from 'interfaces/country'
import React, { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import countryService from 'services/country.service'
import { resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  editMode?: boolean
  country?: ICountry
  closeDialog?: (event: boolean) => void
  getNewList?: () => void
}
function AddEditCountryDialog(props: dialogProps) {
  const { open, onClose, editMode, closeDialog, getNewList, country } = props

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      countryName: yup.string().required('Country Name is required'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (formdata: ICountry) => {
    if (editMode) {
      const [data] = await resolveHTTP(countryService.UpdateCountry(formdata))

      if (data) {
        toast.success('Country Updated Successfully')
        closeDialog(false)
        getNewList()
      }
    } else {
      const [data] = await resolveHTTP(
        countryService.AddCountry({ countryName: formdata.countryName })
      )
      if (data) {
        toast.success('Country Added Successfully')
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formik.resetForm()
        closeDialog(false)
        getNewList()
      }
    }
    // eslint-disable-next-line no-unused-expressions
  }

  const formik = useFormik<ICountry>({
    initialValues: {
      countryId: 0,
      countryName: '',
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })

  const handleClose = () => {
    formik.resetForm()
  }

  useEffect(() => {
    if (editMode) {
      formik.setFieldValue('countryId', country.countryId)
      formik.setFieldValue('countryName', country.countryName)
    } else {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Dialog
        className="primary-dialog user-dialog"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
          handleClose()
          onClose(e)
        }}
      >
        <DialogTitle>
          <h5 className="bold">
            {editMode ? 'Update Country' : 'Add Country'}{' '}
          </h5>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              onClose(e)
              handleClose()
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="pb-0">
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="text"
                label="Country Name"
                name="countryName"
                value={formik.values?.countryName}
                onChange={formik.handleChange}
                error={
                  !!formik.errors.countryName && formik.touched.countryName
                }
                helperText={
                  !!formik.errors.countryName && formik.touched.countryName
                    ? formik.errors.countryName
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
          </DialogContent>
          {/* dialog-content end */}
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title={editMode ? 'Update' : 'Add'}
                type="submit"
              >
                {editMode ? 'Update' : 'Add'}
              </Button>

              <Button
                onClick={(e) => {
                  onClose(e)
                  handleClose()
                }}
                title="Cancel"
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default AddEditCountryDialog
