/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import { useSelector } from 'react-redux'
import { startOfDay, endOfDay } from 'date-fns'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import DateFilter from 'components/Dashboard/DateFilter'
import DashboardView from 'components/Dashboard/Index'
import Button from 'components/Button/Index'
import { ROLE_SECRET_KEY } from 'utility/constants'
import CryptoJS from 'crypto-js'
import userService from 'services/user.service'
import Layout from 'components/Layout/Layout'

AOS.init({
  duration: 600,
  once: true,
})
// document.body.classList.add('show-loader')
// setTimeout(() => {
//   document.body.classList.remove('show-loader')
// }, 5000)

function Dashboard() {
  const yesterday = startOfDay(new Date())
  yesterday.setDate(yesterday.getDate() - 1)
  const dayBeforeyesterday = startOfDay(new Date())
  dayBeforeyesterday.setDate(dayBeforeyesterday.getDate() - 2)
  const yest = [yesterday, endOfDay(yesterday)]
  const dBeforeYest = [dayBeforeyesterday, endOfDay(dayBeforeyesterday)]
  const [selectedDates, setSelectedDates] = useState(yest)
  const [prevselectedDates, setPrevSelectedDates] = useState(dBeforeYest)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const [impersonateOn, setImpersonateOn] = useState(
    localStorage.getItem('ImpersonateText') === 'Out Impersonate'
  )
  const [daysInBetween, setDaysInBetween] = useState(0)
  const [showDateClander, setShowDateCalender] = useState(false)
  const [isLoadPage, setLoadPage] = useState(true)

  const updateComparisonDates = (dates) => {
    const newStart = new Date(dates[0])
    const newEnd = new Date(dates[1])

    const timeDifferenceInMilliseconds = newEnd.getTime() - newStart.getTime()
    const timeDifferenceInDays = Math.round(
      timeDifferenceInMilliseconds / (1000 * 3600 * 24)
    )

    setDaysInBetween(timeDifferenceInDays === 1 ? 0 : timeDifferenceInDays)

    const lastMonthStartDate = new Date(newStart)
    lastMonthStartDate.setDate(newStart.getDate() - timeDifferenceInDays)

    const lastMonthEndDate = new Date(newEnd)
    lastMonthEndDate.setDate(newEnd.getDate() - timeDifferenceInDays)

    setPrevSelectedDates([lastMonthStartDate, lastMonthEndDate])
  }

  function updateDate(dates: Date[]) {
    setSelectedDates(dates)
    updateComparisonDates(dates)
  }

  function updateComparisonDate(dates: Date[]) {
    setPrevSelectedDates(dates)
  }

  console.log(daysInBetween)

  const IsAdmin =
    Number(
      CryptoJS.AES.decrypt(
        localStorage.getItem('roleId') === null ||
          localStorage.getItem('roleId') === undefined
          ? ''
          : localStorage.getItem('roleId'),
        ROLE_SECRET_KEY
      )?.toString(CryptoJS.enc.Utf8)
    ) === 1

  if (IsAdmin) {
    localStorage.setItem('isNeedToShowImpersonate', 'true')
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setShowDateCalender(localStorage.getItem('ShowDateCalender') === 'true')
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    updateComparisonDate(prevselectedDates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevselectedDates])

  // const handleChange = (event) => {
  //   setTextValue(event.target.value)
  // }

  const updateImpersonate = async (paramImpersonate) => {
    const lsRoleList = await userService.getRoleList()
    let newTempRoleId
    if (paramImpersonate) {
      newTempRoleId = lsRoleList.data.data.find(
        (x) => x.roleName === 'RestaurantAdmin'
      ).roleId
      localStorage.setItem('ImpersonateText', 'Out Impersonate')
    } else {
      newTempRoleId = lsRoleList.data.data.find(
        (x) => x.roleName === 'Admin'
      ).roleId
      localStorage.setItem('ImpersonateText', 'Impersonate')
    }
    const encrypted = CryptoJS.AES.encrypt(
      newTempRoleId.toString(),
      ROLE_SECRET_KEY
    )
    localStorage.setItem('roleId', encrypted.toString())
    if (!isLoadPage) {
      window.location.reload()
    }
  }
  useEffect(() => {
    if (localStorage.getItem('isNeedToShowImpersonate') === 'true') {
      updateImpersonate(impersonateOn)
    }
    setLoadPage(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateOn])

  const triggerStorageEffect = () => {
    const handleStorageChange = () => {
      setShowDateCalender(localStorage.getItem('ShowDateCalender') === 'true')
    }
    handleStorageChange()
  }

  return (
    <>
      <Layout title="Dashboard | FasTab">
        <></>
        {/* wrapper start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Dashboard</h3>
            <div className="dashboard-header-main">
              <div className="dashboard-header">
                {showDateClander && (
                  <>
                    <div className="right dashboard-input-main">
                      <DateFilter
                        setFilterSelectedDate={updateDate}
                        clear={false}
                        displayText={null}
                        isDashboard
                        isCompare={false}
                        daysInBetween={null}
                        onClose={() => {
                          console.log('close')
                        }}
                      />
                    </div>
                    <span
                      style={{
                        margin: '5px',
                        font: 'inherit',
                        color: '#737373',
                      }}
                      className="compare-to-text"
                    >
                      compared to
                    </span>
                    <div className="right dashboard-input-main">
                      <DateFilter
                        setFilterSelectedDate={updateComparisonDate}
                        clear={false}
                        displayText={prevselectedDates}
                        isDashboard={false}
                        isCompare
                        daysInBetween={daysInBetween}
                        onClose={() => {
                          console.log('close')
                        }}
                      />
                    </div>
                  </>
                )}
                <div>
                  {localStorage.getItem('isNeedToShowImpersonate') ===
                    'true' && (
                    <Button
                      variant="contained"
                      color="primary"
                      title={impersonateOn ? 'Cancel' : 'Impersonate'}
                      onClick={() => setImpersonateOn(!impersonateOn)}
                      className="ml-5 impersonate-button"
                    >
                      {impersonateOn ? 'Cancel' : 'Impersonate'}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* page-top end */}
          {selectedRestaurantId > 0 ? (
            <DashboardView
              startDate={selectedDates[0]}
              endDate={selectedDates[1]}
              prevStartDate={prevselectedDates[0]}
              prevEndDate={prevselectedDates[1]}
              impersonateOn={impersonateOn}
              showsDate={triggerStorageEffect}
            />
          ) : (
            <div>
              <section className="main-content no-data">
                <p>Please Select Restaurant And Location To View Dashboard</p>
              </section>
            </div>
          )}
        </section>
        {/* wrapper end */}
      </Layout>

      {/* <Loader /> */}
    </>
  )
}

export default Dashboard
