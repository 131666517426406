import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IGMAnalyticsHeatMapResponse,
  IGMAnalyticsRequest,
  IGMAnalyticsResponse,
  IGMAnalyticsHeatMapDataResponse,
  IGMAnalyticsHeatMapDataRequest,
  IGMGuestRequest,
  IGMGuestResponse,
  ISearchResponse,
  // IGMAddCampaignRequest,
  IGMEmailListRequest,
  IGMEmailListResponse,
  IGMAddCampaignRequest,
  IGMCampaignListRequest,
  IGMCampaignListResponse,
  ICampaignIdRequest,
  IGetCampaignForEditResponse,
  ISendGridEmailVerifyRequest,
  ISendGridEmailVerifyResponse,
  ISendGridTokenVerifyRequest,
} from 'interfaces/guestmarketing'
import httpClient from './base-service'

const endPointBaseURL = `/GuestMarketing`

const getAnalyticsData = async (
  requestBody: IGMAnalyticsRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGMAnalyticsResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGMAnalyticsResponse>>(
    `${endPointBaseURL}/get-gm-analytics-details`,
    requestBody,
    { hideLoader: false }
  )

const getAnalyticsHeatMapData = async (
  requestBody: IGMAnalyticsRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGMAnalyticsHeatMapResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<IGMAnalyticsHeatMapResponse[]>>(
    `${endPointBaseURL}/get-gm-analytics-heatmap`,
    requestBody,
    { hideLoader: false }
  )

const getAnalyticsHeatMapDetails = async (
  requestBody: IGMAnalyticsHeatMapDataRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<IGMAnalyticsHeatMapDataResponse>>
> =>
  httpClient.post<IApiSuccessResponse<IGMAnalyticsHeatMapDataResponse>>(
    `${endPointBaseURL}/get-gm-analytics-heatmap-data`,
    requestBody,
    { hideLoader: false }
  )

const getGuestPageData = async (
  requestBody: IGMGuestRequest
): Promise<
  AxiosResponse<IApiSuccessResponse<ISearchResponse<IGMGuestResponse[]>>>
> =>
  httpClient.post<IApiSuccessResponse<ISearchResponse<IGMGuestResponse[]>>>(
    `${endPointBaseURL}/get-gm-guest-details`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const uploadGuestData = async (
  requestBody: FormData
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/gm-upload-guest-data`,
    requestBody,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      hideLoader: false,
    }
  )

const addCampaign = async (
  requestBody: IGMAddCampaignRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-campaign`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const getEmailList = async (
  requestBody: IGMEmailListRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGMEmailListResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGMEmailListResponse>>(
    `${endPointBaseURL}/get-campaign-email-list`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const getCampaignList = async (
  requestBody: IGMCampaignListRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGMCampaignListResponse[]>>> =>
  httpClient.post<IApiSuccessResponse<IGMCampaignListResponse[]>>(
    `${endPointBaseURL}/get-campaign-list`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const deleteCampaign = async (
  requestBody: ICampaignIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.delete<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/delete-campaign`,
    {
      headers: {},
      data: requestBody,
      hideLoader: false,
    }
  )

const inactivateCampaign = async (
  requestBody: ICampaignIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/inactivate-campaign`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const getCampaignForEdit = async (
  requestBody: ICampaignIdRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGetCampaignForEditResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGetCampaignForEditResponse>>(
    `${endPointBaseURL}/get-campaign-by-id`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const editCampaign = async (
  requestBody: IGMAddCampaignRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/edit-campaign`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const verifySenderEmail = async (
  requestBody: ISendGridEmailVerifyRequest
): Promise<AxiosResponse<IApiSuccessResponse<ISendGridEmailVerifyResponse>>> =>
  httpClient.post<IApiSuccessResponse<ISendGridEmailVerifyResponse>>(
    `${endPointBaseURL}/verify-sender-email`,
    requestBody,
    {
      hideLoader: false,
    }
  )

const verifySenderWithToken = async (
  requestBody: ISendGridTokenVerifyRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/verify-sender-with-token`,
    requestBody,
    {
      hideLoader: false,
    }
  )

export default {
  getAnalyticsData,
  getAnalyticsHeatMapData,
  getAnalyticsHeatMapDetails,
  getGuestPageData,
  uploadGuestData,
  getEmailList,
  addCampaign,
  getCampaignList,
  deleteCampaign,
  inactivateCampaign,
  getCampaignForEdit,
  editCampaign,
  verifySenderEmail,
  verifySenderWithToken,
}
