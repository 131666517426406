import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Card, Close, OutlineCheck } from 'assets/images'
import Button from 'components/Button/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import React from 'react'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
}
function UpgradeGuestMarketingDialog(props: dialogProps) {
  const { open, onClose } = props
  const [selectValue, setSelectValue] = React.useState(1)

  return (
    <>
      {/* upgrade guest marketing dialog start */}
      <Dialog
        className="primary-dialog upgrade-guest-marketing-dialog"
        maxWidth="xl"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle className="pb-0">
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="left">
            <div className="highlighted-text">
              <span>NEW Guest Marketing Experience </span>
            </div>
            <h6>Upgrade to Guest Marketing.</h6>
            <ul>
              <li>
                <img src={OutlineCheck} alt="Checkmark" />
                <span>See detailed profiles of your guests.</span>
              </li>
              <li>
                <img src={OutlineCheck} alt="Checkmark" />
                <span>Set automated email campaigns.</span>
              </li>
              <li>
                <img src={OutlineCheck} alt="Checkmark" />
                <span>Find high-spending areas.</span>
              </li>
              <li>
                <img src={OutlineCheck} alt="Checkmark" />
                <span>Upload your existing contact list.</span>
              </li>
            </ul>
          </div>
          <div className="right">
            <div className="price">
              <h6>$199.99</h6>
              <div className="highlighted-text">
                <span>-20% </span>
              </div>
            </div>
            <p>Billed Monthly, original price is $249.00</p>

            <div className="form-group">
              <Select
                handleSelectValue={(value) => {
                  setSelectValue(value)
                }}
                formikValue={selectValue}
                items={[
                  {
                    key: 'Card',
                    value: 1,
                  },
                  {
                    key: 'Net Banking',
                    value: 2,
                  },
                  {
                    key: 'Cash',
                    value: 3,
                  },
                ]}
                label="Payment Method"
              />
            </div>
            <div className="form-group">
              <Textfield
                variant="outlined"
                label="Credit Card Number"
                withAdornment
                adormentComponent={<img src={Card} alt="card" />}
              />
            </div>
            <Grid container spacing={5}>
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Textfield variant="outlined" label="Expiry" />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Textfield variant="outlined" label="CVV" />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Select
                    handleSelectValue={(value) => {
                      setSelectValue(value)
                    }}
                    formikValue={selectValue}
                    items={[
                      {
                        key: 'Manitoba',
                        value: 1,
                      },
                      {
                        key: 'Manitoba',
                        value: 2,
                      },
                      {
                        key: 'Manitoba',
                        value: 3,
                      },
                    ]}
                    label="Province"
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Textfield variant="outlined" label="Postal Code" />
                </div>
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" title="Get Access Now">
              Get Access Now!
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* upgrade guest marketing dialog end */}
    </>
  )
}

export default UpgradeGuestMarketingDialog
