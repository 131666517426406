/* eslint-disable no-lonely-if */
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROLE_SECRET_KEY } from 'utility/constants'
import CryptoJS from 'crypto-js'
import authService from 'services/auth.service'
import { toast } from 'react-toastify'

interface IProps {
  Component: React.ComponentType
  allowAll?: boolean
  allowRestaurantStaff?: boolean
  isNotAllowedAfterLogin?: boolean
}

export default function PrivateRoute(props: IProps) {
  const { Component, allowAll, allowRestaurantStaff, isNotAllowedAfterLogin } =
    props
  const [roleId, setRoleId] = React.useState(0)
  const navigate = useNavigate()
  const storageData = localStorage.length
  React.useEffect(() => {
    const token = localStorage.getItem('loginAuthToken')
    const refresh = localStorage.getItem('RefreshToken')
    if (
      (!refresh && authService.isTokenExpired(token)) ||
      localStorage.length === 0
    ) {
      if (!refresh && authService.isTokenExpired(token)) {
        toast.error('User is not autorized')
      }
      localStorage.clear()
      if (!isNotAllowedAfterLogin) {
        navigate('/')
      }
    }
    if (localStorage.getItem('roleId') && isNotAllowedAfterLogin) {
      navigate(-1)
    }
    if (localStorage.length === 0) {
      if (!isNotAllowedAfterLogin) {
        navigate('/')
      }
    } else if (localStorage.getItem('roleId')) {
      setRoleId(
        +CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        )?.toString(CryptoJS.enc.Utf8)
      )
      if (
        localStorage.getItem('isGMEnable') !== 'true' &&
        +CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        )?.toString(CryptoJS.enc.Utf8) !== 1 &&
        !allowAll &&
        !allowRestaurantStaff &&
        localStorage.getItem('roleId')
      ) {
        navigate('/unauthorized')
      }
      if (
        localStorage.getItem('isGMEnable') !== 'true' &&
        (+CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        ).toString(CryptoJS.enc.Utf8) === 6 ||
          +CryptoJS.AES.decrypt(
            localStorage.getItem('roleId'),
            ROLE_SECRET_KEY
          )?.toString(CryptoJS.enc.Utf8) === 7) &&
        allowRestaurantStaff
      ) {
        navigate('/unauthorized')
      }
    } else {
      if (!isNotAllowedAfterLogin) {
        navigate('/')
      }
    }
  }, [
    navigate,
    roleId,
    allowAll,
    storageData,
    allowRestaurantStaff,
    isNotAllowedAfterLogin,
  ])

  return (
    <>
      <Component />
    </>
  )
}
