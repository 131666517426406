import Button from 'components/Button/Index'
import React from 'react'
import { Helmet } from 'react-helmet'

function Error() {
  return (
    <>
      <Helmet>
        <title>Error 404 | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      {/* main-content start */}
      <section className="main-content error-message">
        <h1>404</h1>
        <h2>Oops, This Page Could Not Be Found.</h2>
        <p>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </p>
        <Button
          variant="contained"
          isTypeLink
          to="/dashboard"
          color="primary"
          title="Back To Home"
        >
          Back To Home
        </Button>
      </section>
      {/* main-content end */}
      {/* wrapper end */}
    </>
  )
}

export default Error
