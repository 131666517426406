import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Checkbox from 'components/Checkbox/Index'
import Datepicker from 'components/Datepicker/Index'
import Textfield from 'components/Textfield/Index'
import React from 'react'
import { Link } from 'react-router-dom'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
}
function LeadGeneratorFormDialog(props: dialogProps) {
  const { open, onClose } = props

  return (
    <>
      {/* lead generator form dialog start */}
      <Dialog
        className="primary-dialog full-height lead-generator-form-dialog"
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle className="pb-0">
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="form">
            <h4 className="title">Form Content</h4>
            <p className="subtext">Enter your details below to continue</p>
            <div className="form-group">
              <Textfield variant="outlined" label="First Name" />
            </div>
            <div className="form-group">
              <Textfield variant="outlined" label="Email" />
            </div>
            <div className="form-group">
              <Datepicker datePickerlabel="Birthday" />
            </div>
            <div className="form-group privacy-policy-link">
              <Checkbox name="rememberMe" label="I’ve read and agree to" />
              <Link to="/" className="primary-link" title="Privacy Policy.">
                Privacy Policy.
              </Link>
            </div>
            <Button variant="contained" color="primary" title="Submit">
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* lead generator form dialog end */}
    </>
  )
}

export default LeadGeneratorFormDialog
