import { Container } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import Button from 'components/Button/Index'
import twoFactor from 'services/two.factor'
import { toast } from 'react-toastify'
import { ITwoFactor } from 'interfaces/two-factor'
import { MobileLogo } from 'assets/images'
import authService from 'services/auth.service'

function OtpVerification() {
  const [otp, setOtp] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const [phnNumber] = useState((location.state as ITwoFactor)?.phoneNumber)
  const [userData] = useState((location.state as ITwoFactor)?.data)
  console.log(location.state, userData)

  const handleChange = (code: string) => setOtp(code)
  const sendOTP = useCallback(async () => {
    const data = await twoFactor.sendCodeOnPhone({
      phoneNumber: phnNumber,
    })
    if (!data?.data?.data?.success) {
      toast.error('Please try again')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  window.addEventListener('popstate', () => {
    localStorage.clear()
  })

  const handleVerify = async () => {
    const result = await twoFactor.verifyCodeSentOnPhone({
      phoneNumber: phnNumber,
      verificationCode: otp,
    })
    if (result?.data?.data?.success) {
      await authService.setLoginDetails({
        token: userData.token,
        refreshToken: userData.refreshToken,
        firstName: userData.firstName,
        lastName: userData.lastName,
        loginId: userData.loginId,
        lastActive: userData.lastActive,
        isGuestMarketingEnable: userData.isGuestMarketingEnable,
      })
      navigate('/dashboard')
    } else {
      toast.error('Verification code does not match')
    }
    setOtp('')
  }
  useEffect(() => {
    if (userData && phnNumber) {
      sendOTP()
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Helmet>
        <title>Otp Verification | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="formm-wrapper">
        <Container maxWidth="md">
          {/* otp-verification start */}
          <div className="card bordered otp-verification">
            <img src={MobileLogo} alt="FasTab" className="from-mobile-logo" />
            <div className="sm-card">
              <h3>Verification</h3>
              <h6>
                Enter the verification code, we just sent you on your registered
                mobile number.
              </h6>
              <div className="otp-input-wrapper">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum
                  separator={<span> - </span>}
                  className="otp-input"
                  shouldAutoFocus
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                title="Verify"
                onClick={handleVerify}
              >
                <span>Verify</span>
              </Button>
              <p>
                Didn’t receive the code?
                <Link
                  to="/two-factor"
                  onClick={sendOTP}
                  className="primary-link"
                  title="Resend Code"
                >
                  Resend Code
                </Link>
              </p>
            </div>
          </div>
          {/* otp-verification end */}
        </Container>
      </section>
      {/* wrapper end */}
    </>
  )
}

export default OtpVerification
