import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  ICountry,
  ICountryAdd,
  ICountryListRequest,
  ICountryListResponse,
} from 'interfaces/country'
import httpClient from './base-service'

const endPointBaseURL = `/country`

const getAllCountry = async (
  requestBody: ICountryListRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICountryListResponse>>> =>
  httpClient.post<IApiSuccessResponse<ICountryListResponse>>(
    `${endPointBaseURL}/get-country-list`,
    requestBody
  )

const AddCountry = async (
  requestBody: ICountryAdd
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-country`,
    requestBody
  )

const UpdateCountry = async (
  requestBody: ICountryAdd
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-country`,
    requestBody
  )

const getCountries = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ICountry[]>>
> =>
  httpClient.get<IApiSuccessResponse<ICountry[]>>(
    `${endPointBaseURL}/get-all-country`
  )

export default { getAllCountry, UpdateCountry, getCountries, AddCountry }
