import { Checkbox, ListItemText, MenuItem, TextField } from '@mui/material'
import { Checked, Unchecked } from 'assets/images'
import { Search } from 'components/Designer/assets/images'
import React, { useEffect, useMemo, useState } from 'react'

interface props {
  items: Array<{ key: number | string; value: number }>
  label?: string
  errorText?: string
  showError?: boolean
  placeholder?: boolean
  placeholderValue?: string
  disabled?: boolean
  searchAble?: boolean
  createAble?: boolean
  handleSelectValue?: (event: string[]) => void
  name?: string
  formikValue?: number
  defaultValue?: string[]
}

function MultiSelect({
  items,
  label,
  errorText,
  showError = false,
  placeholderValue,
  placeholder = false,
  disabled = false,
  searchAble = false,
  createAble = false,
  handleSelectValue,
  name,
  formikValue,
  defaultValue,
}: props) {
  const [showText, SetShowText] = React.useState([])
  const [searchText, setSearchText] = useState('')
  const [isAllSelected, setIsAllSelected] = useState(false)
  const containsText = (text, searchTextVal) => {
    return (
      text.key !== null &&
      text.key !== undefined &&
      searchTextVal !== null &&
      searchTextVal !== undefined &&
      text.key.toLowerCase().indexOf(searchTextVal.toLowerCase()) > -1
    )
  }

  const displayedOptions = useMemo(
    () => items.filter((option) => containsText(option, searchText)),
    [searchText, items]
  )
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event
    if (value[value.length - 1] !== 'all') {
      handleSelectValue(typeof value === 'string' ? value.split(',') : value)
      SetShowText(typeof value === 'string' ? value.split(',') : value)
    }
  }
  useEffect(() => {
    SetShowText([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])
  useEffect(() => {
    if (defaultValue) {
      SetShowText(
        displayedOptions.map((ele) => {
          return defaultValue.includes(ele.value.toString())
            ? ele.value.toString()
            : ''
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])
  console.log(formikValue, defaultValue, showText)
  return (
    <>
      <TextField
        variant="outlined"
        label={label}
        name={name}
        helperText={showError ? errorText : ''}
        onChange={handleChange}
        error={showError}
        disabled={disabled}
        value={showText}
        select
        SelectProps={{
          multiple: true,
          defaultValue,
          name,
          value: showText,
          MenuProps: {
            classes: {
              root: 'primary-menu',
            },
            autoFocus: false,
          },
          native: false,
          renderValue: () => (
            <>
              {items
                .filter((ex) => showText.includes(ex.value.toString()))
                .map((record) => record.key)
                .join(', ')}
            </>
          ),
        }}
      >
        {placeholder && (
          <MenuItem
            disabled
            style={{ display: 'none' }}
            value={placeholderValue}
          >
            <span className="placeholder">{placeholderValue}</span>
          </MenuItem>
        )}
        {searchAble && (
          <MenuItem className="search-box" disableTouchRipple>
            <span>
              <input
                type="text"
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search Here..."
                value={searchText}
              />
              <button type="button">
                <img src={Search} alt="Search" />
              </button>
            </span>
          </MenuItem>
        )}
        {displayedOptions.length > 0 && (
          <MenuItem key="all" value="all">
            <Checkbox
              checked={isAllSelected}
              icon={<img src={Unchecked} alt="Check" />}
              checkedIcon={<img src={Checked} alt="Checked" />}
              onChange={(e) => {
                setIsAllSelected(e.target.checked)
                if (e.target.checked) {
                  handleSelectValue(
                    items.map((ele) => {
                      return ele.value.toString()
                    })
                  )
                  SetShowText(
                    items.map((ele) => {
                      return ele.value.toString()
                    })
                  )
                } else {
                  handleSelectValue([])
                  SetShowText([])
                }
              }}
              indeterminate={
                showText.length > 0 && showText.length < items.length
              }
            />
            <ListItemText primary="Select All" />
          </MenuItem>
        )}
        {displayedOptions.map((element) => {
          return (
            <MenuItem
              key={element.value?.toString()}
              value={element.value?.toString()}
            >
              <Checkbox
                checked={
                  showText?.indexOf(element.value.toString()) > -1 ||
                  defaultValue?.includes(element.value.toString())
                }
                icon={<img src={Unchecked} alt="Check" />}
                checkedIcon={<img src={Checked} alt="Checked" />}
              />
              <ListItemText primary={element.key} />
            </MenuItem>
          )
        })}
        {createAble && (
          <MenuItem className="add-item">
            <span>Add</span> &quot;some section&quot;
          </MenuItem>
        )}
      </TextField>
    </>
  )
}

export default MultiSelect
