import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { Close, ExportNew, FilterNew, RefundImg, View } from 'assets/images'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import Datepicker from 'components/Datepicker/Index'
import Layout from 'components/Layout/Layout'
import MultiPaymentDetailsDialog from 'components/MultiPaymentDetailsDialog/Index'
import PaymentDetailsDialog from 'components/PaymentDetailsDialog/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import CryptoJS from 'crypto-js'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import { IPaymentsSearchRequest } from 'interfaces/payments'
import { useCallback, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import paymentService from 'services/payment.service'
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_SECRET_KEY,
} from 'utility/constants'
import { setDefaultDataGridOptions } from 'utility/helper'

Aos.init({
  duration: 600,
  once: true,
})

function Payments() {
  const [filterToggle, setFilterToggle] = useState(false)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [paymentData, setPaymentData] =
    useState<{ paymentId: number; posOrderId?: string }>()
  const [tabId, setTabId] = useState(null)
  const [paymentList, setPaymentList] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [paymentId, setPaymentId] = useState(0)
  const [frmOrderId, setFrmOrderId] = useState(0)
  const [isApplyOrClear, setIsApplyOrClear] = useState(false)
  const [roleId, setRoleId] = useState(0)
  const [createdDate, setCreatedDate] = useState(null)
  const [updatedDate, setUpdatedDate] = useState(null)
  const [createdEndDate, setCreatedEndDate] = useState(null)
  const [changeDataGridCall, setChangeDataGridCall] = useState(true)
  const [userFilterSelectedPaymentType, setuserFilterSelectedPaymentType] =
    useState(0)
  const FILTER_STATUS = { FAIL: 0, SUCCESS: 1, DEFAULT: 3 }
  const TRANSACTION_STATUS = { ALL: 0, FASTAB: 1, POS: 2 }
  const TIMEZONE_VALUE = { LOCAL: 0, UTC: 1 }
  const [userFilterSelectedStatus, setuserFilterSelectedStatus] = useState(
    FILTER_STATUS.DEFAULT
  )
  const [userSelectedSource, setuserSelectedSource] = useState(
    TRANSACTION_STATUS.FASTAB
  )
  const [timeZoneFilterValue, setTimeZoneFilterValue] = useState<number>(
    TIMEZONE_VALUE.LOCAL
  )
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'restaurantName',
        sort: 'asc',
      },
    ],
  })
  // const [fromTabs] = useState(
  //   localStorage.getItem('fromTabs') ? localStorage.getItem('fromTabs') : false
  // )
  const [openPaymentDetailsDialog, setOpenPaymentDetailsDialog] =
    useState(false)
  const [openMultiPaymentDetailsDialog, setOpenMultiPaymentDetailsDialog] =
    useState(false)
  const closePaymentDetailsDialog = () => {
    setOpenPaymentDetailsDialog(false)
  }
  const closeMultiPaymentDetailsDialog = () => {
    setOpenMultiPaymentDetailsDialog(false)
  }
  const [getPayEdgeOrderId, setPayEdgeOrderId] = useState(null)
  const [getLocationId, setLocationId] = useState(0)
  const [getDisbursementDate, setDisbursementDate] = useState(null)
  const [getDisbursementStatus, setDisbursementStatus] = useState(null)
  const [getRestaurantId, setRestaurantId] = useState(null)
  const [getPageId, setPageId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openRefundNoteDialog, setRefundNoteDialog] = useState(false)
  const [refundNote, setRefundNote] = useState('')
  const [showErrormsgRefund, setErrorMsgRefund] = useState(false)
  const [refundChargeId, setRefundChargeId] = useState('')
  const [refundPaymentId, setRefundPaymentId] = useState(0)
  const [posIdList, setPosIdList] = useState([])
  const [totalRefundAmount, setTotalRefundAmount] = useState(0)
  const [refundDetailsDialog, setRefundDetailsDialog] = useState(false)
  const [isRefundManual, setIsRefundManual] = useState(true)
  const [refundBy, setRefundBy] = useState(null)

  // csv download logic
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  const getYesterdayDate = () => {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    date.setHours(0, 0, 0, 0)
    // const isoDate = date.toISOString()
    return date
  }

  const downloadFile = async () => {
    const data = await paymentService.downloadCsv({
      restaurantId: selectedRestaurantId,
      tabId,
      weekStartDate: createdDate,
      weekEndDate: createdEndDate,
      paymentSourceId: userSelectedSource,
      establishmentId: selectedEstablishmentId,
      payEdgeOrderId: getPayEdgeOrderId,
      pageId: getPageId,
      payOutDisbursementStatus: getDisbursementStatus,
      disbursementRestaurant: getRestaurantId,
      disbursementDate: getDisbursementDate,
      locationId: getLocationId,
      timeZoneFilter: timeZoneFilterValue,
    })

    const headers = {
      Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
      EmailAddress: 'Email'.replace(/,/g, ''),
      WebsiteLink: 'Website'.replace(/,/g, ''),
      CreatedDate: 'Date'.replace(/,/g, ''),
    }
    const fileTitle = 'Payment'
    exportCSVFile(headers, data.data.data, fileTitle)
  }

  const getPaymentList = useCallback(
    async (payload: IPaymentsSearchRequest) => {
      try {
        const data = await paymentService.getAllPayments(payload)
        setPaymentList(data.data.data.data)
        setTotalRecords(data.data.data.totalRecords)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const generatePayload = (isFromApplyOrClear?: boolean) => {
    if (isFromApplyOrClear) {
      setChangeDataGridCall(false)
      const res = setDefaultDataGridOptions(dataGridOptions)
      setDataGridOptions(res)
    }
    const payload: IPaymentsSearchRequest = {
      pageNo: isFromApplyOrClear
        ? DEFAULT_PAGE_NUMBER
        : dataGridOptions.pageNumber,
      pageSize: isFromApplyOrClear
        ? DEFAULT_PAGE_SIZE
        : dataGridOptions.pageSize,
      restaurantId: selectedRestaurantId ?? 0,
      establishmentId: selectedEstablishmentId ?? 0,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
      statusId: FILTER_STATUS.DEFAULT,
      timeZoneFilter: timeZoneFilterValue,
    }
    payload.tabId = tabId !== 0 ? tabId : 0
    payload.orderId = frmOrderId !== 0 ? frmOrderId : 0
    payload.pageId = 1
    payload.weekStartDate =
      createdDate === null
        ? getYesterdayDate().toDateString()
        : new Date(createdDate).toDateString()
    payload.weekEndDate =
      createdEndDate === null
        ? getYesterdayDate().toDateString()
        : new Date(createdEndDate).toDateString()
    if (userFilterSelectedStatus !== FILTER_STATUS.DEFAULT)
      payload.statusId =
        userFilterSelectedStatus === 0
          ? FILTER_STATUS.FAIL
          : FILTER_STATUS.SUCCESS
    if (userFilterSelectedPaymentType !== 0)
      payload.paymentTypeId = userFilterSelectedPaymentType
    if (userSelectedSource !== TRANSACTION_STATUS.ALL)
      payload.paymentSourceId = userSelectedSource

    if (
      !(isFromApplyOrClear || isApplyOrClear) &&
      Number(localStorage.getItem('fromLocationId'))
    ) {
      payload.weekStartDate = new Date(
        localStorage.getItem('weekStartDate')
      ).toDateString()
      payload.weekEndDate = new Date(
        localStorage.getItem('weekEndDate')
      ).toDateString()
      payload.payedgeOrderId =
        localStorage.getItem('payoutPayEdgeOrderId') === 'null'
          ? null
          : localStorage.getItem('payoutPayEdgeOrderId')
      payload.disbursementDate =
        localStorage.getItem('disbursementDate') === 'null'
          ? null
          : localStorage.getItem('disbursementDate')
      payload.locationId = Number(localStorage.getItem('fromLocationId'))
      payload.pageId = 3
      setPayEdgeOrderId(payload.payedgeOrderId)
      setDisbursementDate(payload.disbursementDate)
      setLocationId(payload.locationId)
      setPageId(3)
    }
    if (
      !(isFromApplyOrClear || isApplyOrClear) &&
      localStorage.getItem('fromDisbursement')
    ) {
      payload.weekStartDate = new Date(
        localStorage.getItem('disStartDate')
      ).toDateString()
      payload.payedgeOrderId =
        localStorage.getItem('dispayEdgeId') === 'undefined' ||
        localStorage.getItem('dispayEdgeId') === 'null'
          ? null
          : localStorage.getItem('dispayEdgeId')
      payload.weekEndDate = new Date(
        localStorage.getItem('disEndDate')
      ).toDateString()
      payload.payoutDisbursementStatus = Number(
        localStorage.getItem('disbursementStatus')
      )
      payload.restaurantId = Number(localStorage.getItem('disRestaurantId'))
      payload.pageId = 2
      setPayEdgeOrderId(payload.payedgeOrderId)
      setDisbursementStatus(payload.payoutDisbursementStatus)
      setRestaurantId(payload.restaurantId)
      setPageId(2)
      // payload.pageSize = 0
    }
    return payload
  }
  useEffect(() => {
    const payload = generatePayload()
    // if (fromTabs) {
    //   payload.tabId = localStorage.getItem('frmtabId')
    //     ? Number(localStorage.getItem('frmtabId'))
    //     : 0
    //   payload.pageId = 4
    // }
    if (changeDataGridCall) {
      getPaymentList(payload)
    }
    setChangeDataGridCall(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId, selectedEstablishmentId])

  useEffect(() => {
    if (localStorage.getItem('roleId')) {
      setRoleId(
        +CryptoJS.AES.decrypt(
          localStorage.getItem('roleId'),
          ROLE_SECRET_KEY
        )?.toString(CryptoJS.enc.Utf8)
      )
    }

    setCreatedDate(getYesterdayDate())
    setCreatedEndDate(getYesterdayDate())
    if (localStorage.getItem('fromLocationId')) {
      setCreatedDate(localStorage.getItem('weekStartDate'))
      setCreatedEndDate(localStorage.getItem('weekEndDate'))
    }
    if (localStorage.getItem('fromDisbursement')) {
      setCreatedDate(
        new Date(localStorage.getItem('disStartDate')).toDateString()
      )
      setCreatedEndDate(
        new Date(localStorage.getItem('disEndDate')).toDateString()
      )
    }
    return () => {
      localStorage.removeItem('weekStartDate')
      localStorage.removeItem('weekEndDate')
      localStorage.removeItem('payoutDate')
      localStorage.removeItem('fromLocationId')
      localStorage.removeItem('payoutPayEdgeOrderId')
      localStorage.removeItem('disbursementDate')
      localStorage.removeItem('frmtabId')
      if (localStorage.getItem('fromDisbursement')) {
        localStorage.removeItem('disStartDate')
        localStorage.removeItem('disEndDate')
        localStorage.removeItem('disbursementStatus')
        localStorage.removeItem('fromDisbursement')
        localStorage.removeItem('disRestaurantId')
        localStorage.removeItem('dispayEdgeId')
      }
    }
  }, [])
  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }
  const onFilterApply = async () => {
    setIsApplyOrClear(true)
    const payload = generatePayload(false)
    payload.pageNo = 1
    getPaymentList(payload)
  }

  const getMultiPaymentList = async (id, totalAmount, initiatedBy, date) => {
    try {
      const data = await paymentService.getMultiPaymentsList({
        paymentId: id,
        pageSize: 0,
        pageNo: 0,
        globalFilterRestaurantId: selectedRestaurantId,
      })
      setPosIdList(data.data.data.data.map((e) => e.omnivoreOrderId))
      setTotalRefundAmount(totalAmount)
      setRefundBy(initiatedBy)
      setUpdatedDate(date)
    } catch (e) {
      console.log(e)
    }
  }

  const onFilterClear = () => {
    setIsApplyOrClear(true)
    setTabId(null)
    setFrmOrderId(0)
    setCreatedDate(getYesterdayDate())
    setCreatedEndDate(getYesterdayDate())
    setuserFilterSelectedStatus(FILTER_STATUS.DEFAULT)
    setuserSelectedSource(TRANSACTION_STATUS.FASTAB)
    setTimeZoneFilterValue(TIMEZONE_VALUE.LOCAL)
    setuserFilterSelectedPaymentType(0)
    setDisbursementDate(null)
    setLocationId(0)
    const payload = generatePayload(true)
    payload.tabId = null
    payload.orderId = 0
    payload.weekStartDate = getYesterdayDate().toDateString()
    payload.weekEndDate = getYesterdayDate().toDateString()
    payload.statusId = FILTER_STATUS.DEFAULT
    payload.paymentSourceId = 1
    payload.pageId = 1
    payload.timeZoneFilter = TIMEZONE_VALUE.LOCAL
    getPaymentList(payload)
  }

  // convert date to local time
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    // newDate.setHours(0, 0, 0, 0)
    return newDate
  }
  const saveInStorage = (publicTabId: string) => {
    localStorage.setItem('fromPaymentTabId', publicTabId)
    localStorage.setItem(
      'restaurantIdForDetailRequest',
      selectedRestaurantId.toString()
    )
    localStorage.setItem(
      'establishmentIdForDetailRequest',
      selectedEstablishmentId.toString()
    )
    if (localStorage.getItem('fromGuestGuestId')) {
      localStorage.removeItem('fromGuestGuestId')
    }
  }

  const addManualRefund = async () => {
    const data = await paymentService.addManualRefund({
      orderId: refundPaymentId,
      chargeId: refundChargeId,
      refundNote,
      isManual: isRefundManual,
      refundLoginId: Number(localStorage.getItem('loginId')),
    })
    if (data) {
      setRefundNoteDialog(false)
      setRefundPaymentId(0)
      setRefundChargeId('')
      setRefundNote('')
      setPosIdList([])
      onFilterApply()
      toast.success('Refund Request Successful')
    }
  }
  return (
    <>
      <Layout title="Payments | FasTab">
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Payments</h3>
            <div className="right">
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                size="small"
                title="Export"
                className="btn-h-40"
                onClick={downloadFile}
              >
                <img src={ExportNew} alt="File" />
                <span>Export</span>
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                size="small"
                title="Export"
                className="btn-h-40"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={FilterNew} alt="Filter" />
                <span>Filter</span>
              </Button>
              {/* {fromTabs && (
                <Button
                  variant="contained"
                  color="primary"
                  title="Back"
                  isTypeLink
                  to="/tabs"
                  size="small"
                >
                  <img src={BackArrow} alt="Back" />
                  <span>Back</span>
                </Button>
              )} */}
            </div>
          </div>
          {/* page-top end */}
          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={6}>
                  <Grid item lg={2.4} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="Search Payments"
                        type="number"
                        variant="outlined"
                        value={
                          tabId === null || tabId === undefined ? '' : tabId
                        }
                        onChange={(e) => {
                          setTabId(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={2.4} sm={6} xs={12}>
                    <div className="form-group">
                      <Datepicker
                        datePickerlabel="Payment Start Date"
                        onChange={(date) => {
                          setCreatedDate(
                            convertUTCDateToLocalDate(date).toISOString()
                          )
                        }}
                        value={createdDate}
                        setFilterSelectedDate={setCreatedDate}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={2.4} sm={6} xs={12}>
                    <div className="form-group">
                      <Datepicker
                        datePickerlabel="Payment End Date"
                        onChange={(date) => {
                          setCreatedEndDate(
                            convertUTCDateToLocalDate(date).toISOString()
                          )
                        }}
                        value={createdEndDate}
                        setFilterSelectedDate={setCreatedEndDate}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={2.4} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Payment Source"
                        name="paymentSourceId"
                        items={[
                          {
                            key: 'Both',
                            value: TRANSACTION_STATUS.ALL,
                          },
                          {
                            key: 'FasTab',
                            value: TRANSACTION_STATUS.FASTAB,
                          },
                          {
                            key: 'POS',
                            value: TRANSACTION_STATUS.POS,
                          },
                        ]}
                        formikValue={userSelectedSource}
                        handleSelectValue={(value) => {
                          setuserSelectedSource(value)
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={2.4} sm={6} xs={12}>
                    <div className="form-group">
                      <Select
                        label="Time Zone"
                        name="timeZoneFilter"
                        items={[
                          {
                            key: 'Local',
                            value: TIMEZONE_VALUE.LOCAL,
                          },
                          {
                            key: 'UTC',
                            value: TIMEZONE_VALUE.UTC,
                          },
                        ]}
                        formikValue={timeZoneFilterValue}
                        handleSelectValue={(value) => {
                          setTimeZoneFilterValue(value)
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Apply"
                    onClick={onFilterApply}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear All"
                    onClick={onFilterClear}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="tabPaymentId"
              columnsData={[
                {
                  field: 'restaurantName',
                  headerName: 'Restaurant Name',
                  flex: 1,
                  minWidth: 180,
                },
                {
                  field: 'establishmentName',
                  headerName: 'Location Name',
                  flex: 1,
                  minWidth: 180,
                },
                {
                  field: 'serverName',
                  headerName: 'Server Name',
                  flex: 1,
                  minWidth: 150,
                },
                {
                  field: 'paymentId',
                  headerName: 'Payment Id',
                  flex: 1,
                  minWidth: 120,
                },
                {
                  field: 'omnivoreOrderId',
                  headerName: 'POS Ordrer Id',
                  flex: 1,
                  minWidth: 120,
                  renderCell: (d: GridRenderCellParams<string>) => {
                    return d.value === 'Multi' ? (
                      <Button
                        className="icon-btn no-border rounded"
                        variant="text"
                        color="primary"
                        onClick={() => {
                          setOpenMultiPaymentDetailsDialog(true)
                          setPaymentId(d.row.tabPaymentId)
                        }}
                      >
                        Multi
                      </Button>
                    ) : (
                      d.value
                    )
                  },
                },
                {
                  field: 'publicTabId',
                  headerName: 'Tab Id',
                  flex: 1,
                  minWidth: 100,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return (
                      <Link
                        to="/tabs"
                        onClick={() => saveInStorage(params.row.publicTabId)}
                        style={{
                          color: '#008cff',
                          height: 'auto',
                        }}
                        target="_blank"
                      >
                        {params.value}
                      </Link>
                    )
                  },
                },
                {
                  field: 'createdDate',
                  headerName: 'Date & Time',
                  flex: 1,
                  minWidth: 190,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return params.value === '0001-01-01T00:00:00' ? (
                      ''
                    ) : (
                      <Moment format="MM/DD/YYYY hh:mm A">
                        {params.value}
                      </Moment>
                    )
                  },
                },
                {
                  field: 'subTotal',
                  headerName: 'Subtotal',
                  flex: 1,
                  minWidth: 120,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(Math.round(Number(params.value) * 100) / 100)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                  },
                },
                {
                  field: 'totalTip',
                  headerName: 'Tip',
                  flex: 1,
                  minWidth: 70,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(Math.round(Number(params.value) * 100) / 100)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                  },
                },
                {
                  field: 'totalTax',
                  headerName: 'Tax',
                  flex: 1,
                  minWidth: 70,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(Math.round(Number(params.value) * 100) / 100)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                  },
                },
                {
                  field: 'totalServiceCharge',
                  headerName: 'Service Charge',
                  flex: 1,
                  minWidth: 140,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return params.value
                      ? `$${(Math.round(Number(params.value) * 100) / 100)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                      : '$0.00'
                  },
                },
                {
                  field: 'promocodeAmount',
                  headerName: 'Promo Code Amount',
                  flex: 1,
                  minWidth: 180,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    if (!params.value) {
                      return '-'
                    }
                    return `$${(Math.round(Number(params.value) * 100) / 100)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                  },
                },
                {
                  field: 'amount',
                  headerName: 'Total',
                  flex: 1,
                  minWidth: 80,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return `$${(Math.round(Number(params.value) * 100) / 100)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                  },
                },
                {
                  field: 'payoutAmount',
                  headerName: 'Est. Payout Amount',
                  flex: 1,
                  minWidth: 180,
                  renderCell: (params: GridRenderCellParams<number>) => {
                    return params.value < 0
                      ? `-$${Math.abs(params.value).toFixed(2)}`
                      : `$${(Math.round(Number(params.value) * 100) / 100)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                  },
                },
                Number(roleId) &&
                  (Number(roleId) === 1 ||
                    Number(roleId) === 3 ||
                    Number(roleId) === 6) && {
                    align: 'center',
                    headerAlign: 'center',
                    field: 'action',
                    disableColumnMenu: true,
                    headerName: 'Action',
                    sortable: false,
                    renderCell: (d: GridRenderCellParams<number>) => {
                      return d.row.publicTabId === null ? (
                        <></>
                      ) : (
                        <>
                          <Button
                            className="icon-btn no-border rounded"
                            variant="text"
                            color="inherit"
                            onClick={() => {
                              if (
                                d.row.omnivoreOrderId.toString() !== 'Multi'
                              ) {
                                setOpenPaymentDetailsDialog(true)
                                setPaymentData({
                                  paymentId: d.row.tabPaymentId,
                                  posOrderId: null,
                                })
                              } else {
                                setOpenMultiPaymentDetailsDialog(true)
                                setPaymentId(d.row.tabPaymentId)
                              }
                            }}
                          >
                            <img src={View} alt="More" />
                          </Button>
                          {d.row.transactionId !== null && (
                            <>
                              {d.row.disbursedAt === null &&
                                d.row.isRefunded !== true && (
                                  <Button
                                    className="icon-btn no-border rounded"
                                    variant="text"
                                    color="inherit"
                                    onClick={() => {
                                      setRefundChargeId(d.row.transactionId)
                                      setRefundPaymentId(d.row.tabPaymentId)
                                      getMultiPaymentList(
                                        d.row.tabPaymentId,
                                        d.row.amount,
                                        d.row.refundedBy,
                                        d.row.updatedDate
                                      )
                                      setOpenConfirmDialog(true)
                                    }}
                                  >
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Request a refund"
                                    >
                                      <img
                                        style={{
                                          filter:
                                            'invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)',
                                        }}
                                        src={RefundImg}
                                        alt="Refund"
                                      />
                                    </Tooltip>
                                  </Button>
                                )}
                              {(d.row.disbursedAt !== null ||
                                d.row.isRefunded === true) && (
                                <Button
                                  className="icon-btn no-border rounded"
                                  variant="text"
                                  color="inherit"
                                  onClick={() => {
                                    if (d.row.isRefunded) {
                                      getMultiPaymentList(
                                        d.row.tabPaymentId,
                                        d.row.amount,
                                        d.row.refundedBy,
                                        d.row.updatedDate
                                      )
                                      setRefundNote(d.row.refundNotes)
                                      setRefundDetailsDialog(true)
                                    }
                                  }}
                                >
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title={
                                      d.row.isRefunded === true
                                        ? 'Payment has been refunded'
                                        : 'Payment has been disbursed; please reach out to FasTab for further assistance'
                                    }
                                  >
                                    <img src={RefundImg} alt="Refund" />
                                  </Tooltip>
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )
                    },
                    flex: 1,
                    minWidth: 100,
                  },
              ]}
              rowsData={paymentList}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
              classes="data-table-row-0"
            />
          </div>
          {/* data-table end */}
        </section>
        <></>
        {/* main-content end */}
      </Layout>
      {/* wrapper end */}
      <PaymentDetailsDialog
        open={openPaymentDetailsDialog}
        onClose={closePaymentDetailsDialog}
        paymentData={paymentData}
        // startDate={
        //   createdDate
        //     ? new Date(createdDate)?.toISOString()
        //     : getYesterdayDate()
        // }
        // endDate={
        //   createdEndDate
        //     ? new Date(createdEndDate)?.toISOString()
        //     : getYesterdayDate()
        // }
      />
      <MultiPaymentDetailsDialog
        open={openMultiPaymentDetailsDialog}
        onClose={closeMultiPaymentDetailsDialog}
        paymentId={paymentId}
        // startDate={
        //   createdDate
        //     ? new Date(createdDate)?.toISOString()
        //     : getYesterdayDate()
        // }
        // endDate={
        //   createdEndDate
        //     ? new Date(createdEndDate)?.toISOString()
        //     : getYesterdayDate()
        // }
      />
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={openConfirmDialog}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={() => {
              setOpenConfirmDialog(false)
              setRefundPaymentId(0)
              setRefundChargeId('')
              setRefundNote('')
              setTotalRefundAmount(0)
            }}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <p>
            Are you sure you want to proceed with a refund for this payment?{' '}
          </p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>
              {posIdList.length === 1 ? 'POS Order ID:' : 'POS Order IDs:'}
            </p>
            <p>{posIdList.map((id) => id.toString()).join(', ')}</p>

            <p style={{ fontWeight: 'bold' }}>Total Paid Amount:</p>
            <p>${totalRefundAmount.toFixed(2)}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Yes"
              onClick={() => {
                setOpenConfirmDialog(false)
                setRefundNoteDialog(true)
                setPosIdList([])
              }}
            >
              Yes
            </Button>
            <Button
              title="No"
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenConfirmDialog(false)
                setRefundPaymentId(0)
                setRefundChargeId('')
                setRefundNote('')
                setPosIdList([])
                setTotalRefundAmount(0)
              }}
            >
              <span>No</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        className="primary-dialog"
        maxWidth="xs"
        fullWidth
        open={openRefundNoteDialog}
        onClose={() => {
          setRefundNoteDialog(false)
        }}
      >
        <DialogTitle>
          <h5>Refund Request</h5>
          <Button
            variant="text"
            title="Close"
            onClick={() => {
              setRefundNoteDialog(false)
              setRefundPaymentId(0)
              setRefundChargeId('')
              setRefundNote('')
              setPosIdList([])
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="form-group">
            {Number(roleId) && Number(roleId) === 1 && (
              <div style={{ marginBottom: '20px' }}>
                <Select
                  label="Refund Initiated By"
                  items={[
                    {
                      key: 'FasTab',
                      value: 0,
                    },
                    {
                      key: 'Restaurant',
                      value: 1,
                    },
                  ]}
                  formikValue={isRefundManual ? 1 : 0}
                  handleSelectValue={(value) => {
                    setIsRefundManual(value === 1)
                  }}
                />
              </div>
            )}
            <TextField
              variant="outlined"
              type="text"
              multiline
              rows={2}
              label="Refund Note"
              name="refundNote"
              onChange={(e) => {
                const inputValue = e.target.value
                setRefundNote(inputValue)
                if (inputValue.length >= 251 || inputValue.length === 0) {
                  setErrorMsgRefund(true)
                } else {
                  setErrorMsgRefund(false)
                }
              }}
              error={showErrormsgRefund}
              helperText={
                showErrormsgRefund ? (
                  <small style={{ fontSize: 'small' }}>
                    Refund note can&apos;t be empty or have more than 250
                    characters
                  </small>
                ) : (
                  ''
                )
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              variant="contained"
              color="primary"
              title="Submit"
              onClick={() => {
                if (!showErrormsgRefund) {
                  if (refundNote !== '') {
                    addManualRefund()
                  } else {
                    setErrorMsgRefund(true)
                  }
                }
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                setRefundNoteDialog(false)
                setRefundPaymentId(0)
                setRefundChargeId('')
                setRefundNote('')
              }}
              title="Cancel"
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        className="primary-dialog alert-dialog"
        maxWidth="xs"
        fullWidth
        open={refundDetailsDialog}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={() => {
              setRefundDetailsDialog(false)
              setRefundNote('')
              setPosIdList([])
              setTotalRefundAmount(0)
              setRefundBy(null)
              setUpdatedDate(null)
            }}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              gap: '10px',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Refund Note:</p>
            <p>{refundNote}</p>

            <p style={{ fontWeight: 'bold' }}>
              {posIdList.length === 1 ? 'POS Order ID:' : 'POS Order IDs:'}
            </p>
            <p>{posIdList.map((id) => id.toString()).join(', ')}</p>

            <p style={{ fontWeight: 'bold' }}>Total Paid Amount:</p>
            <p>${totalRefundAmount.toFixed(2)}</p>
            {refundBy && updatedDate && (
              <span
                style={{
                  fontStyle: 'italic',
                  display: 'contents',
                  textAlign: 'center',
                }}
              >
                <Moment format="MM/DD/YYYY hh:mm A">{updatedDate}</Moment> by{' '}
                {refundBy}
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-list">
            <Button
              title="Ok"
              variant="contained"
              color="primary"
              onClick={() => {
                setRefundDetailsDialog(false)
                setRefundNote('')
                setPosIdList([])
                setTotalRefundAmount(0)
                setRefundBy(null)
                setUpdatedDate(null)
              }}
            >
              <span>Ok</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Payments
