/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
import { Collapse, Grid } from '@mui/material'
import Aos from 'aos'
import React, { useCallback, useEffect, useState } from 'react'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { FilterNew } from 'assets/images'
import Button from 'components/Button/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import disbursemetService from 'services/disbursement.service'
import { IDatagrid } from 'interfaces/datagrid'
import Layout from 'components/Layout/Layout'
import { IPayout } from 'interfaces/disbursement'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import Textfield from 'components/Textfield/Index'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { useSelector } from 'react-redux'
import Select from 'components/Select/Index'

Aos.init({
  duration: 600,
  once: true,
})

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, filename: string) => void
  }
}

function PayOut() {
  const [filterToggle, setFilterToggle] = useState(true)
  const [payoutList, setPayoutList] = useState<IPayout[]>([])
  const [totalRecords, setTotalRecords] = useState(0)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [payEdgeOrderId, setPayEdgeOrderId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  const PayoutFilterPeriod = { ALL: 0, CurrentPeriod: 1, LastWeeksPeriod: 2 }
  const [userSelectedPeriod, setUserSelectedPeriod] = useState(
    PayoutFilterPeriod.ALL
  )

  const setLocalStorageParams = (
    payoutInitiationDate,
    locationId,
    pEdegeOrderId
  ) => {
    localStorage.setItem('disbursementDate', payoutInitiationDate)
    localStorage.setItem('fromLocationId', locationId)
    localStorage.setItem('payoutPayEdgeOrderId', pEdegeOrderId)
  }

  const getPayoutListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'payEdgeOrderId',
        headerName: 'PayEdge Order ID',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<string>) => {
          return params.value ? params.value : ' - '
        },
      },
      {
        field: 'restaurantName',
        headerName: 'Restaurant Name',
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'locationName',
        headerName: 'Location Name',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'disbursePeriod',
        headerName: 'Period',
        flex: 1,
        minWidth: 160,
      },
      {
        field: 'noOfPayments',
        headerName: 'No. of Payments',
        flex: 1,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<number>) => {
          return (
            <Link
              to="/payments"
              onClick={() =>
                setLocalStorageParams(
                  params.row.payoutInitiationDate,
                  params.row.locationId,
                  params.row.payEdgeOrderId
                )
              }
              style={{
                color: '#008cff',
              }}
              target="_blank"
            >
              {params.value}
            </Link>
          )
        },
      },
      {
        field: 'grossAmount',
        headerName: 'Net Amount',
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${(Math.round(Number(params.value) * 100) / 100)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
      {
        field: 'totalRestaurantRefund',
        headerName: 'Net Refund',
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${(Math.round(Number(params.value) * 100) / 100)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
      {
        field: 'totalPayout',
        headerName: 'Total Payout',
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${(Math.round(Number(params.value) * 100) / 100)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
      {
        field: 'totalFees',
        headerName: 'Fees',
        flex: 1,
        minWidth: 120,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${(Math.round(Number(params.value) * 100) / 100)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
      {
        field: 'payoutInitiationDate',
        headerName: 'Payout Initiation Date',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<string>) => {
          return params.value ? (
            <Moment format="MM/DD/YYYY">{params.value}</Moment>
          ) : (
            '-'
          )
        },
      },
    ]
  }

  const getPayoutList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      payEdgeOrderID: string,
      paymentID: string,
      currentRestaurantId?: number,
      currentEstablishmentId?: number,
      filterPeriod?: number
    ) => {
      if (localStorage.getItem('isfromPayment')) {
        payEdgeOrderID = localStorage.getItem('payEdgeId')
      }
      const data = await disbursemetService.getPayoutList({
        pageNo,
        pageSize,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        payEdgeOrderId: payEdgeOrderID === 'null' ? null : payEdgeOrderID,
        paymentId: paymentID,
        restaurantId: currentRestaurantId,
        establishmentId: currentEstablishmentId,
        filterPeriod,
      })
      if (data.data.data.data) {
        data.data.data.data.map((element, index) => {
          element.uniqueId = index
          return element
        })
        setPayoutList(data.data.data.data)
        setTotalRecords(data.data.data.totalRecords)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    // getWeekRangeList()
    if (localStorage.getItem('isfromPayment')) {
      setPayEdgeOrderId(localStorage.getItem('payEdgeId'))
    }
    getPayoutList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      payEdgeOrderId,
      paymentId,
      selectedRestaurantId,
      selectedEstablishmentId,
      userSelectedPeriod
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId, selectedEstablishmentId])
  useEffect(() => {
    return () => {
      localStorage.removeItem('isfromPayment')
      localStorage.removeItem('payEdgeId')
    }
  }, [])

  const onFilterApply = async () => {
    getPayoutList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      payEdgeOrderId,
      paymentId,
      selectedRestaurantId,
      selectedEstablishmentId,
      userSelectedPeriod
    )
  }

  const onFilterClear = () => {
    setPaymentId('')
    setPayEdgeOrderId('')
    getPayoutList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      '',
      '',
      selectedRestaurantId,
      selectedEstablishmentId,
      PayoutFilterPeriod.ALL
    )
    setUserSelectedPeriod(PayoutFilterPeriod.ALL)
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  return (
    <>
      <Layout title="Payouts | FasTab">
        {/* page-top start */}
        <div className="page-top">
          <h3 className="page-title">Payouts</h3>
          <div className="right">
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Filter"
              size="small"
              className="btn-h-40"
              onClick={() => {
                setFilterToggle(!filterToggle)
              }}
            >
              <img src={FilterNew} alt="Filter" />
              <span>Filter</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        {/* filter-panel start */}
        <Collapse in={filterToggle}>
          <div className="filter-panel">
            <div className="card">
              <Grid container spacing={5}>
                <Grid item lg={3} sm={6} xs={12}>
                  <div className="form-group">
                    <Textfield
                      label="PayEdge Order ID"
                      variant="outlined"
                      value={
                        payEdgeOrderId === '' || payEdgeOrderId === undefined
                          ? ''
                          : payEdgeOrderId
                      }
                      onChange={(e) => {
                        setPayEdgeOrderId(e.target.value)
                      }}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <div className="form-group">
                    <Select
                      label="Filter Period"
                      items={[
                        {
                          key: 'All Periods',
                          value: PayoutFilterPeriod.ALL,
                        },
                        {
                          key: 'Current Period',
                          value: PayoutFilterPeriod.CurrentPeriod,
                        },
                        {
                          key: 'Last Weeks Period',
                          value: PayoutFilterPeriod.LastWeeksPeriod,
                        },
                      ]}
                      formikValue={userSelectedPeriod}
                      handleSelectValue={(value) => {
                        setUserSelectedPeriod(value)
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <div className="btn-list">
                <Button
                  variant="contained"
                  color="primary"
                  title="Apply"
                  onClick={onFilterApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  title="Clear All"
                  onClick={onFilterClear}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
        {/* filter-panel end */}

        <div className="card mb-30 p-0" data-aos="fade-up">
          {/* data-table start */}
          <FasTabGrid
            uniqueId="uniqueId"
            columnsData={getPayoutListColumnDef()}
            gridData={payoutList}
            onGridPageChange={onPageChange}
            onGridPageSizeChange={onPageSizeChange}
            onGridSortChange={onSortChange}
            totalRecords={totalRecords}
            classes="data-table-row-0"
          />
        </div>
        {/* data-table end */}
      </Layout>
    </>
  )
}

export default PayOut
