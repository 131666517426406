import InfoIcon from '@mui/icons-material/Info'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import Tab from '@mui/material/Tab'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import {
  Calendar2,
  Campaign1,
  CoinPrimary,
  Delete,
  Edit,
  Inactivate,
  SearchGrey,
  Upload,
  UserGrey,
  UserPrimary,
  View,
} from 'assets/images'
import Button from 'components/Button/Index'
import CreateCampaignDialog from 'components/CreateCampaignDialog/Index'
import DateFilter from 'components/Dashboard/DateFilter'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import HeatMap from 'components/GuestMarketing/HeatMap'
import Layout from 'components/Layout/Layout'
import LeadGeneratorFormDialog from 'components/LeadGeneratorFormDialog/Index'
import UpgradeGuestMarketingDialog from 'components/UpgradeGuestMarketingDialog/UpgradeGuestMarketingDialog'
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  sub,
  subDays,
} from 'date-fns'
import { DialogOptions } from 'enums/Common'
import {
  establishmentList,
  selectedEstablishment,
} from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import {
  IGMAnalyticsHeatMapResponse,
  IGMAnalyticsResponse,
  IGMCampaignListResponse,
  IGMGuestRequest,
  IGMGuestResponse,
} from 'interfaces/guestmarketing'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import guestMarketingService from 'services/guest.marketing.service'
import { resolveHTTP } from 'utility/helper'

function GuestMarketing() {
  const [value, setValue] = useState('1')
  const [campaignPageValue, setCampaignPageValue] = useState('1')
  const [analyticsTabTimeToggle, setAnalyticsTabTimeToggle] = useState<
    string | null
  >('today')
  const [guestLocationsTimeToggle, setGuestLocationsTimeToggle] = useState<
    string | null
  >('lastMonth')
  const selectedCurrentEstablishment = useSelector(selectedEstablishment)
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const allEstablishmentList = useSelector(establishmentList)
  const startOfToday = startOfDay(new Date())
  const yesterdays = startOfDay(new Date())
  yesterdays.setDate(yesterdays.getDate() - 1)
  const weekAgo = sub(new Date(), { days: 7 })
  const lastWeek = [startOfISOWeek(weekAgo), endOfISOWeek(weekAgo)]
  const twoWeekAgo = sub(new Date(), { days: 14 })
  const pastTwoWeeks = [startOfISOWeek(twoWeekAgo), endOfISOWeek(weekAgo)]
  const thisMonth = [startOfMonth(startOfToday), endOfMonth(startOfToday)]
  const startOfLastMonth = sub(startOfMonth(new Date()), { months: 1 })
  const lastMonth = [startOfLastMonth, endOfMonth(startOfLastMonth)]
  const allTime = [new Date(2022, 11, 1), startOfToday]
  const [selectedDate, setSelectedDate] = useState([
    startOfToday,
    endOfDay(startOfToday),
  ])
  const [selectedDateHM, setSelectedDateHM] = useState(lastMonth)
  const [reportingData, setReportingData] = useState<IGMAnalyticsResponse>()
  const [campReportingData, setCampReportingData] =
    useState<IGMCampaignListResponse[]>()
  const [reportingGuestData, setReportingGuestData] =
    useState<IGMGuestResponse[]>()
  const [reportingHeatMapData, setReportingHeatMapData] =
    useState<IGMAnalyticsHeatMapResponse[]>()
  const fileInputRef = useRef(null)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'totalSpent',
        sort: 'desc',
      },
    ],
  })
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [newGuests, setNewGuests] = useState(0)
  const dayBeforeyesterday = startOfDay(new Date())
  dayBeforeyesterday.setDate(dayBeforeyesterday.getDate() - 2)
  const yesterday = startOfDay(new Date())
  yesterday.setDate(yesterday.getDate() - 1)

  const updateDate = (dates: Date[]) => {
    setSelectedDate(dates)
  }
  const getDateRange = (period) => {
    const now = new Date()
    switch (period) {
      case 'today': {
        return [startOfDay(now), endOfDay(now)]
      }
      case 'yesterday': {
        const yesterday1 = subDays(now, 1)
        return [startOfDay(yesterday1), endOfDay(yesterday1)]
      }
      case 'lastWeek': {
        return lastWeek
      }
      case 'lastTwoWeeks': {
        return pastTwoWeeks
      }
      case 'thisMonth': {
        return thisMonth
      }
      case 'lastMonth': {
        return lastMonth
      }
      default:
        return [null, null]
    }
  }

  const getGuestData = useCallback(
    async (payload: IGMGuestRequest) => {
      await Promise.all([
        await guestMarketingService.getGuestPageData(payload),
        await guestMarketingService.getAnalyticsData({
          restaurantId: payload.restaurantId,
          establishmentId: payload.establishmentId,
          startDate: moment()
            .subtract(7, 'days')
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .toISOString(),
          endDate: moment()
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .set('millisecond', 999)
            .toISOString(),
        }),
      ])
        .then(([data, newGuess]) => {
          setReportingGuestData(data?.data?.data?.data)
          setTotalRecords(data?.data?.data?.totalRecords)
          setNewGuests(newGuess?.data?.data?.newGuests)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId]
  )

  const generateGuestDataPayload = () => {
    const guestDatapayload: IGMGuestRequest = {
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      restaurantId: selectedRestaurantId,
      establishmentId:
        selectedCurrentEstablishment > 0
          ? [selectedCurrentEstablishment]
          : allEstablishmentList
              .filter((x) => x.restaurantId === selectedRestaurantId)
              .map((x) => x.establishmentId),
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
    }
    return guestDatapayload
  }

  const handleFileUpload = async (event) => {
    const fileInput = event.target
    const file = fileInput.files[0]

    if (!file) {
      toast.error('No file selected')
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    const allowedExtensions = ['csv', 'xlsx']
    const fileExtension = file.name.split('.').pop().toLowerCase()
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error('Invalid file type. Only CSV and XLSX files are allowed.')
      return
    }

    try {
      formData.append('restaurantId', selectedRestaurantId.toString())
      formData.append(
        'establishmentId',
        JSON.stringify(
          selectedCurrentEstablishment > 0
            ? [selectedCurrentEstablishment]
            : allEstablishmentList
                .filter((x) => x.restaurantId === selectedRestaurantId)
                .map((x) => x.establishmentId)
        )
      )
      const response = await guestMarketingService.uploadGuestData(formData)

      if (response?.data?.data === 'success') {
        toast.success('File processed successfully.')
        const payload = generateGuestDataPayload()
        getGuestData(payload)
      } else {
        toast.error(response?.data?.data)
      }
    } catch (error) {
      toast.error('Error uploading file.')
    }

    // Clear the file input value to allow re-upload of the same file
    fileInput.value = ''
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(new Date(date))
  }

  const getData = useCallback(
    async (
      restaurantId: number,
      establishmentId: number,
      startDate: Date,
      endDate: Date
    ) => {
      await Promise.all([
        await guestMarketingService.getAnalyticsData({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: moment(startDate)
            .set('date', startDate.getDate())
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .toISOString(),
          endDate: moment(endDate)
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .set('millisecond', 999)
            .toISOString(),
        }),
      ])
        .then(([data]) => {
          setReportingData(data?.data?.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId]
  )

  const getHeatMapData = useCallback(
    async (
      restaurantId: number,
      establishmentId: number,
      startDate: Date,
      endDate: Date
    ) => {
      await Promise.all([
        await guestMarketingService.getAnalyticsHeatMapData({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
          startDate: moment(startDate)
            .set('date', startDate.getDate())
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
            .toISOString(),
          endDate: moment(endDate)
            .set('date', endDate.getDate())
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .set('millisecond', 999)
            .toISOString(),
        }),
      ])
        .then(([data]) => {
          setReportingHeatMapData(data?.data?.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId]
  )

  const getCampaignData = useCallback(
    async (restaurantId: number, establishmentId: number) => {
      await Promise.all([
        await guestMarketingService.getCampaignList({
          restaurantId,
          establishmentId:
            establishmentId > 0
              ? [establishmentId]
              : allEstablishmentList
                  .filter((x) => x.restaurantId === selectedRestaurantId)
                  .map((x) => x.establishmentId),
        }),
      ])
        .then(([data]) => {
          setCampReportingData(data?.data?.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId]
  )

  const [openDeleteCampaignDialog, setOpenDeleteCampaignDialog] =
    useState<boolean>(false)
  const [openInactivateCampaignDialog, setOpenInactivateCampaignDialog] =
    useState<boolean>(false)
  const [campaignIdForDelete, setCampaignIdForDelete] = useState<number>(0)
  const [campaignIdForInactivate, setCampaignIdForInactivate] =
    useState<number>(0)
  const [campaignIdForEdit, setCampaignIdForEdit] = useState<number>(0)
  const [isForEdit, setIsForEdit] = useState<boolean>(false)
  const [isForView, setIsForView] = useState<boolean>(false)

  const [deletedRowCount, setDeletedRowCount] = useState<number>(0)
  const [inactiveRowCount, setInactiveRowCount] = useState<number>(0)
  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = false, id: number) => {
      setOpenDeleteCampaignDialog(isOpen)
      setCampaignIdForDelete(id)
      if (isSubmit && !isOpen && id > 0) {
        const [result] = await resolveHTTP(
          guestMarketingService.deleteCampaign({
            campaignId: Number(id),
          })
        )
        if (result) {
          setDeletedRowCount(deletedRowCount + 1)
          toast.success('Campaign Deleted Successfully')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const toggleInactiveConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = false, id: number) => {
      setOpenInactivateCampaignDialog(isOpen)
      setCampaignIdForInactivate(id)
      if (isSubmit && !isOpen && id > 0) {
        const [result] = await resolveHTTP(
          guestMarketingService.inactivateCampaign({
            campaignId: Number(id),
          })
        )
        if (result) {
          setInactiveRowCount(inactiveRowCount + 1)
          toast.success('Campaign Inactivated Successfully')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (selectedRestaurantId > 0 && selectedCurrentEstablishment > 0) {
      getData(
        selectedRestaurantId,
        selectedCurrentEstablishment,
        selectedDate[0],
        selectedDate[1]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, selectedCurrentEstablishment, selectedDate])

  useEffect(() => {
    if (
      selectedRestaurantId > 0 &&
      selectedCurrentEstablishment > 0 &&
      value === '1'
    ) {
      getHeatMapData(
        selectedRestaurantId,
        selectedCurrentEstablishment,
        selectedDateHM[0],
        selectedDateHM[1]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, selectedCurrentEstablishment, selectedDateHM])

  useEffect(() => {
    if (selectedRestaurantId > 0 && selectedCurrentEstablishment > 0) {
      getCampaignData(selectedRestaurantId, selectedCurrentEstablishment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRestaurantId,
    selectedCurrentEstablishment,
    deletedRowCount,
    inactiveRowCount,
  ])

  useEffect(() => {
    const payload = generateGuestDataPayload()
    if (selectedRestaurantId > 0 && selectedCurrentEstablishment > 0) {
      getGuestData(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions, selectedRestaurantId, selectedCurrentEstablishment])

  const handleAnalyticsTabTimeToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setAnalyticsTabTimeToggle(newValue)
      if (newValue !== 'custom') {
        const [start, end] = getDateRange(newValue)
        setSelectedDate([start, end])
      }
    }
  }

  const handleGuestLocationsTimeToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setGuestLocationsTimeToggle(newValue)
      switch (newValue) {
        case 'lastMonth':
          setSelectedDateHM(lastMonth)
          break
        case 'allTime':
          setSelectedDateHM(allTime)
          break
        default:
          setSelectedDateHM(newValue === 'allTime' ? lastMonth : allTime)
          break
      }
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleCampaignChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setCampaignPageValue(newValue)
  }

  const handleDatepickerOpen = () => {
    setAnalyticsTabTimeToggle('custom')
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
  }

  const filteredData = useMemo(() => {
    if (!searchQuery) return reportingGuestData

    return reportingGuestData?.filter((item) => {
      const queryLowerCase = searchQuery.toLowerCase()

      const name = item.nameAndEmail?.name?.toLowerCase() || ''
      const email = item.nameAndEmail?.email?.toLowerCase() || ''
      const birthDate = item.birthDate?.toLowerCase() || ''
      const postalCode = item.postalCode?.toLowerCase() || ''

      return (
        name.includes(queryLowerCase) ||
        email.includes(queryLowerCase) ||
        birthDate.includes(queryLowerCase) ||
        postalCode.includes(queryLowerCase)
      )
    })
  }, [searchQuery, reportingGuestData])

  const onPageChange = (values: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = values
    setDataGridOptions(options)
  }

  const onPageSizeChange = (values: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = values
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const getGuestListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'nameAndEmail',
        headerName: 'Full Name & Email',
        flex: 1,
        minWidth: 300,
        renderCell: (
          params: GridRenderCellParams<{ name: string; email: string }>
        ) => {
          return (
            <span className="username">
              <span className="icon">
                <img src={UserGrey} alt="User" />
              </span>
              <span>
                <strong>{params.value.name}</strong>
                <span>{params.value.email}</span>
              </span>
            </span>
          )
        },
      },
      {
        field: 'postalCode',
        headerName: 'Postal Code',
        flex: 1,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<string>) => {
          return params.value === null || params.value === ''
            ? '-'
            : params.value.toUpperCase()
        },
      },
      {
        field: 'birthDate',
        headerName: 'Birth Date',
        flex: 1,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<string>) => {
          return params.value === '' || params.value === null
            ? '-'
            : params.value
        },
      },
      {
        field: 'noOfVisits',
        headerName: 'Number of Visits',
        flex: 1,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `${Number(params.value)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
      {
        field: 'daysSinceLastVisit',
        headerName: 'Days Since Last Visit',
        flex: 1,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<number>) => {
          return params.value === null
            ? '-'
            : `${Number(params.value)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
      {
        field: 'totalSpent',
        headerName: 'Total $ Spent',
        flex: 1,
        minWidth: 140,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${(Math.round(Number(params.value) * 100) / 100)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
        },
      },
    ]
  }

  const getLeadGeneratorListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'fullName',
        headerName: 'Full Name',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'birthDate',
        headerName: 'Birth Date',
        flex: 1,
        minWidth: 140,
      },
      {
        field: 'numberOfVisits',
        headerName: 'Number of Visits',
        flex: 1,
        minWidth: 140,
      },
    ]
  }

  const leadGeneratorListRowsData = [
    {
      id: 1,
      fullName: 'Arlene McCoy',
      email: 'alma.lawson@example.com',
      birthDate: 'Jun. 24, 1980',
      numberOfVisits: '12',
    },
    {
      id: 2,
      fullName: 'Marvin McKinney',
      email: 'nathan.roberts@example.com',
      birthDate: 'Feb. 22, 1980',
      numberOfVisits: '10',
    },
    {
      id: 3,
      fullName: 'Annette Black',
      email: 'sara.cruz@example.com',
      birthDate: 'Oct. 12, 1980',
      numberOfVisits: '9',
    },
    {
      id: 4,
      fullName: 'Dianne Russell',
      email: 'jessica.hanson@example.com',
      birthDate: 'Jul. 08, 1980',
      numberOfVisits: '7',
    },
    {
      id: 5,
      fullName: 'Devon Lane',
      email: 'michelle.rivera@example.com',
      birthDate: 'Jul. 02, 1980',
      numberOfVisits: '7',
    },
    {
      id: 6,
      fullName: 'Courtney Henry',
      email: 'bill.sanders@example.com',
      birthDate: 'Jul. 12, 1980',
      numberOfVisits: '5',
    },
    {
      id: 7,
      fullName: 'Kathryn Murphy',
      email: 'debbie.baker@example.com',
      birthDate: 'Jun. 24, 1980',
      numberOfVisits: '5',
    },
    {
      id: 8,
      fullName: 'Floyd Miles',
      email: 'jackson.graham@example.com',
      birthDate: 'Feb. 22, 1980',
      numberOfVisits: '5',
    },
    {
      id: 9,
      fullName: 'Robert Fox',
      email: 'kenzi.lawson@example.com',
      birthDate: '1 Feb, 1980',
      numberOfVisits: '5',
    },
    {
      id: 10,
      fullName: 'Jane Cooper',
      email: 'willie.jennings@example.com',
      birthDate: '17 Oct, 1980',
      numberOfVisits: '5',
    },
  ]

  const [openCreateCampaignDialog, setOpenCreateCampaignDialog] =
    React.useState(false)
  const closeCreateCampaignDialog = () => {
    setOpenCreateCampaignDialog(false)
    setCampaignIdForEdit(0)
    getCampaignData(selectedRestaurantId, selectedCurrentEstablishment)
  }

  const [openUpgradeGuestMarketingDialog, setOpenUpgradeGuestMarketingDialog] =
    React.useState(false)
  const closeUpgradeGuestMarketingDialog = () => {
    setOpenUpgradeGuestMarketingDialog(false)
  }

  const [openLeadGeneratorFormDialog, setOpenLeadGeneratorFormDialog] =
    React.useState(false)
  const closeLeadGeneratorFormDialog = () => {
    setOpenLeadGeneratorFormDialog(false)
  }

  return (
    <>
      <Layout title="Guest Marketing | FasTab">
        <></>
        <>
          {selectedRestaurantId > 0 && selectedCurrentEstablishment > 0 ? (
            <>
              {/* guest marketing start */}
              <div className="card tabs-wrapper guest-marketing mt-20 mb-20">
                <TabContext value={value}>
                  <TabList
                    className="primary-tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                  >
                    <Tab label="Analytics Page" value="1" />
                    <Tab label="Guest Page" value="2" />
                    <Tab label="Campaign Page" value="3" />
                    <Tab label="Lead Generator" value="4" />
                  </TabList>
                  <TabPanel value="1">
                    <div className="tabs-header analytics">
                      <div className="left">
                        <h6>Guest Marketing</h6>
                      </div>
                      <div className="right">
                        <ToggleButtonGroup
                          className="secondary-toggle-buttons"
                          value={analyticsTabTimeToggle}
                          exclusive
                          onChange={handleAnalyticsTabTimeToggleButton}
                          aria-label="text alignment"
                        >
                          <ToggleButton value="today" title="Today">
                            Today
                          </ToggleButton>
                          <ToggleButton value="yesterday" title="Yesterday">
                            Yesterday
                          </ToggleButton>
                          <ToggleButton value="lastWeek" title="Last week">
                            Last week
                          </ToggleButton>
                          <ToggleButton
                            value="lastTwoWeeks"
                            title="Last Two Weeks"
                          >
                            Last Two Weeks
                          </ToggleButton>
                          <ToggleButton value="thisMonth" title="This month">
                            This month
                          </ToggleButton>
                          <ToggleButton value="lastMonth" title="Last month">
                            Last month
                          </ToggleButton>
                          <ToggleButton
                            value="custom"
                            title="Custom"
                            onClick={() => {
                              setAnalyticsTabTimeToggle('custom')
                              handleDatepickerOpen()
                            }}
                          >
                            <img src={Calendar2} alt="Calendar" />
                            Custom
                          </ToggleButton>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateFilter
                              setFilterSelectedDate={updateDate}
                              clear={false}
                              displayText={null}
                              isDashboard={false}
                              isCompare={false}
                              daysInBetween={null}
                              onClose={() => {
                                setAnalyticsTabTimeToggle('custom')
                              }}
                            />
                          </LocalizationProvider>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                    <Grid container spacing={{ md: 6, xs: 5 }}>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="inner-card stats">
                          <ul>
                            <li>
                              <div className="card-header">
                                <p className="title">New Guests</p>
                              </div>
                              <div className="value">
                                <div className="icon">
                                  <img src={UserPrimary} alt="User" />
                                </div>
                                <strong>
                                  {(reportingData?.newGuests ?? 0)
                                    .toString()
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </strong>
                              </div>
                            </li>
                            <li>
                              <div className="card-header">
                                <p className="title">Returning Guests</p>
                              </div>
                              <div className="value">
                                <div className="icon">
                                  <img src={UserPrimary} alt="User" />
                                </div>
                                <strong>
                                  {(reportingData?.returningGuests ?? 0)
                                    .toString()
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </strong>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="inner-card stats">
                          <ul>
                            <li>
                              <div className="card-header">
                                <p className="title">New Guests Spend</p>
                              </div>
                              <div className="value">
                                <div className="icon">
                                  <img src={CoinPrimary} alt="User" />
                                </div>
                                <strong>
                                  $
                                  {(reportingData?.newGuestSpend ?? 0)
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </strong>
                              </div>
                            </li>
                            <li>
                              <div className="card-header">
                                <p className="title">Returning Guests Spend</p>
                              </div>
                              <div className="value">
                                <div className="icon">
                                  <img src={CoinPrimary} alt="User" />
                                </div>
                                <strong>
                                  $
                                  {(reportingData?.returningGuestSpend ?? 0)
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </strong>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="inner-card list">
                          <div className="card-header">
                            <p className="title">Top Postal Codes</p>
                          </div>
                          <ul>
                            {reportingData?.topPostalCodes &&
                            Object.keys(reportingData.topPostalCodes).length >
                              0 ? (
                              <>
                                {Object.entries(
                                  reportingData?.topPostalCodes
                                ).map(([postalCode, amount]) => (
                                  <li>
                                    <strong>{postalCode}</strong>
                                    <span>
                                      $
                                      {amount
                                        .toFixed(2)
                                        .replace(
                                          /(\d)(?=(\d{3})+(?!\d))/g,
                                          '$1,'
                                        )}
                                    </span>
                                  </li>
                                ))}
                              </>
                            ) : (
                              <>
                                <li>
                                  <span>No data available</span>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={12}>
                        <div className="inner-card list">
                          <div className="card-header">
                            <p className="title">Top Spending Guests</p>
                          </div>
                          <ul>
                            {reportingData?.topSpendingGuests &&
                            Object.keys(reportingData.topSpendingGuests)
                              .length > 0 ? (
                              <>
                                {Object.entries(
                                  reportingData?.topSpendingGuests
                                ).map(([name, amount]) => (
                                  <li>
                                    <strong>{name}</strong>
                                    <span>
                                      $
                                      {amount
                                        .toFixed(2)
                                        .replace(
                                          /(\d)(?=(\d{3})+(?!\d))/g,
                                          '$1,'
                                        )}
                                    </span>
                                  </li>
                                ))}
                              </>
                            ) : (
                              <>
                                <li>
                                  <span>No data available</span>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {/* guest location start */}
                        <div className="inner-card guest-locations">
                          <div className="card-header">
                            <p className="title semibold">Guest Locations</p>
                            <ul>
                              <li className="blue">
                                <span>$1,000 + Spent</span>
                              </li>
                              <li className="orange">
                                <span>$500 - $999 Spent</span>
                              </li>
                              <li className="pink">
                                <span>$100 - $499 Spent</span>
                              </li>
                              <li className="sky-blue">
                                <span>Less than $100 spent</span>
                              </li>
                            </ul>
                            <ToggleButtonGroup
                              value={guestLocationsTimeToggle}
                              className="secondary-toggle-buttons"
                              exclusive
                              onChange={handleGuestLocationsTimeToggleButton}
                              aria-label="text alignment"
                            >
                              <ToggleButton
                                value="lastMonth"
                                title="Last Month"
                              >
                                Last Month
                              </ToggleButton>
                              <ToggleButton value="allTime" title="All Time">
                                All Time
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                          <HeatMap
                            postalCodeData={reportingHeatMapData}
                            storePostalCode={
                              selectedCurrentEstablishment > 0 &&
                              allEstablishmentList
                                .filter(
                                  (x) =>
                                    x.establishmentId ===
                                    selectedCurrentEstablishment
                                )
                                .map((x) => x.postalCode)[0]
                            }
                          />
                        </div>
                        {/* guest location end */}
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <div className="tabs-header">
                      <div className="left">
                        <h6>Guest Marketing</h6>
                      </div>
                      <div
                        className="right"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <input
                          type="file"
                          accept=".csv, .xlsx"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          onChange={handleFileUpload}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          title="Upload Guest Contacts"
                          size="medium"
                          onClick={handleButtonClick}
                        >
                          <img src={Upload} alt="Upload" />
                          <span>Upload Guest Contacts</span>
                        </Button>
                        <Tooltip
                          title="Ensure your file includes the exact following header names: FirstName, LastName, Email, Phone, PostalCode, DateOfBirth"
                          arrow
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="inner-card grid">
                      <div className="card-header">
                        <p className="title semibold">
                          Guests: {totalRecords}{' '}
                          <span>
                            (<span className="green">+{newGuests}</span> in last
                            7 days)
                          </span>
                        </p>
                        <div className="form-group search-input">
                          <TextField
                            variant="outlined"
                            InputProps={{
                              autoComplete: 'on',
                              placeholder: 'Search here',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={SearchGrey} alt="Search" />
                                </InputAdornment>
                              ),
                            }}
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                      <FasTabGrid
                        uniqueId="guestId"
                        columnsData={getGuestListColumnDef()}
                        gridData={filteredData}
                        totalRecords={totalRecords}
                        onGridPageChange={onPageChange}
                        onGridPageSizeChange={onPageSizeChange}
                        onGridSortChange={onSortChange}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div className="tabs-header">
                      <div className="left">
                        <h6>Guest Marketing</h6>
                      </div>
                      <div className="right">
                        <Button
                          variant="contained"
                          color="primary"
                          title="Create a Campaign"
                          size="large"
                          onClick={() => {
                            setIsForEdit(false)
                            setIsForView(false)
                            setOpenCreateCampaignDialog(true)
                          }}
                        >
                          <span>Create a Campaign</span>
                        </Button>
                      </div>
                    </div>
                    {/* campaign list start */}
                    <div className="card tabs-wrapper guest-marketing mt-20 mb-20">
                      <TabContext value={campaignPageValue}>
                        <TabList
                          className="primary-tabs"
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={handleCampaignChange}
                        >
                          <Tab label="Active Campaigns" value="1" />
                          <Tab label="Inactive Campaigns" value="2" />
                          <Tab label="Draft Campaigns" value="3" />
                        </TabList>
                        <TabPanel value="1">
                          <ul className="campaign-list">
                            {campReportingData?.filter(
                              (camp) =>
                                camp?.campaign?.isActive &&
                                !camp?.campaign?.isDraft
                            )?.length > 0 ? (
                              campReportingData
                                ?.filter(
                                  (camp) =>
                                    camp?.campaign?.isActive &&
                                    !camp?.campaign?.isDraft
                                )
                                ?.map((camp) => (
                                  <li className="inner-card">
                                    <div className="card-header campaign-header">
                                      <div className="left">
                                        <p className="title semibold">
                                          Campaign
                                        </p>
                                        <Chip
                                          className="primary-chip"
                                          size="small"
                                          label={
                                            camp?.campaign?.isActive
                                              ? 'Active'
                                              : 'Inactive'
                                          }
                                          color={
                                            camp?.campaign?.isActive
                                              ? 'success'
                                              : 'info'
                                          }
                                        />
                                      </div>
                                      <div className="right right-icon">
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="View"
                                          size="large"
                                          onClick={() => {
                                            setIsForView(true)
                                            setIsForEdit(false)
                                            setCampaignIdForEdit(
                                              camp?.campaign?.campaignId
                                            )
                                            setOpenCreateCampaignDialog(true)
                                          }}
                                        >
                                          <img src={View} alt="View" />
                                        </Button>
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="Edit"
                                          size="large"
                                          onClick={() => {
                                            setIsForView(false)
                                            setIsForEdit(true)
                                            setCampaignIdForEdit(
                                              camp?.campaign?.campaignId
                                            )
                                            setOpenCreateCampaignDialog(true)
                                          }}
                                        >
                                          <img src={Edit} alt="Edit" />
                                        </Button>
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="Deactivate"
                                          size="large"
                                          onClick={() =>
                                            toggleInactiveConfirmationDialog(
                                              true,
                                              false,
                                              Number(camp?.campaign?.campaignId)
                                            )
                                          }
                                        >
                                          <img
                                            src={Inactivate}
                                            alt="Deactivate"
                                          />
                                        </Button>
                                      </div>
                                      {/* <div className="right">
                              <div className="form-group">
                                <Textfield
                                  variant="outlined"
                                  label="Location"
                                  withAdornment
                                  adormentComponent={
                                    <img src={LocationGrey} alt="Location" />
                                  }
                                />
                              </div>
                              <Button
                                variant="contained"
                                color="primary"
                                title="Edit"
                                size="small"
                              >
                                <span>Edit</span>
                              </Button>
                            </div> */}
                                    </div>
                                    <div className="campaign-body">
                                      <div className="campaign-details">
                                        <img
                                          src={Campaign1}
                                          alt="Bringing class to cuisine"
                                        />
                                        <div className="inner">
                                          <div className="campaign-type">
                                            <small>Campaign Type :</small>
                                            <Chip
                                              className="primary-chip"
                                              size="small"
                                              label={
                                                camp?.campaign
                                                  ?.campaignTypeId === 1
                                                  ? 'Trigger'
                                                  : 'Scheduled'
                                              }
                                              color="info"
                                            />
                                            <span>
                                              {formatDate(
                                                camp?.campaign?.createdDate
                                              )}
                                            </span>
                                          </div>
                                          <p>{camp?.campaign?.campaignName}</p>
                                          <span>
                                            {
                                              camp?.campaign
                                                ?.campaignDescription
                                            }
                                          </span>
                                          {/* <a href="www.loremipsum.com">
                                    <img src={LinkPrimary} alt="Link" />
                                    <span>www.loremipsum.com</span>
                                  </a> */}
                                          <div className="campaign-date">
                                            <small>Campaign Date :</small>
                                            <span>
                                              {formatDate(
                                                camp?.campaign?.startDate
                                              )}{' '}
                                              to{' '}
                                              {camp?.campaign?.hasEndDate
                                                ? formatDate(
                                                    camp?.campaign?.endDate
                                                  )
                                                : 'Forever Until Turned Off'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <ul>
                                        <li>
                                          <span>New Customers</span>
                                          <small>
                                            {(camp?.newCustomers ?? 0)
                                              .toString()
                                              .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Customer Returns</span>
                                          <small>
                                            {(camp?.customerReturns ?? 0)
                                              .toString()
                                              .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Customers Spend</span>
                                          <small>
                                            $
                                            {camp?.customerSpend
                                              ? camp.customerSpend
                                                  .toFixed(2)
                                                  .replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1,'
                                                  )
                                              : '0.00'}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Top Spending Customers</span>
                                          <small>
                                            $
                                            {camp?.topSpendingCustomers
                                              ? camp.topSpendingCustomers
                                                  .toFixed(2)
                                                  .replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1,'
                                                  )
                                              : '0.00'}
                                          </small>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <span>
                                            Open Rate ({camp?.openCount})
                                          </span>
                                          <small>
                                            {camp?.openRate.toFixed(2)}%
                                          </small>
                                        </li>
                                        <li>
                                          <span>
                                            Click Rate ({camp?.clickCount})
                                          </span>
                                          <small>
                                            {camp?.clickRate.toFixed(2)}%
                                          </small>
                                        </li>
                                        <li>
                                          <span>Email Sent</span>
                                          <small>{camp?.totalSent}</small>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                ))
                            ) : (
                              <li className="inner-card">
                                <span style={{ paddingLeft: '20px' }}>
                                  No Active Campaigns Available
                                </span>
                              </li>
                            )}
                          </ul>
                        </TabPanel>
                        <TabPanel value="2">
                          {' '}
                          <ul className="campaign-list">
                            {campReportingData?.filter(
                              (camp) =>
                                !camp?.campaign?.isActive &&
                                !camp?.campaign?.isDraft
                            )?.length > 0 ? (
                              campReportingData
                                ?.filter(
                                  (camp) =>
                                    !camp?.campaign?.isActive &&
                                    !camp?.campaign?.isDraft
                                )
                                ?.map((camp) => (
                                  <li className="inner-card">
                                    <div className="card-header campaign-header">
                                      <div className="left">
                                        <p className="title semibold">
                                          Campaign
                                        </p>
                                        <Chip
                                          className="primary-chip"
                                          size="small"
                                          label={
                                            camp?.campaign?.isActive
                                              ? 'Active'
                                              : 'Inactive'
                                          }
                                          color={
                                            camp?.campaign?.isActive
                                              ? 'success'
                                              : 'info'
                                          }
                                        />
                                      </div>
                                      <div className="right right-icon">
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="View"
                                          size="large"
                                          onClick={() => {
                                            setIsForView(true)
                                            setIsForEdit(false)
                                            setCampaignIdForEdit(
                                              camp?.campaign?.campaignId
                                            )
                                            setOpenCreateCampaignDialog(true)
                                          }}
                                        >
                                          <img src={View} alt="View" />
                                        </Button>
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="Edit"
                                          size="large"
                                          onClick={() => {
                                            setIsForView(false)
                                            setIsForEdit(true)
                                            setCampaignIdForEdit(
                                              camp?.campaign?.campaignId
                                            )
                                            setOpenCreateCampaignDialog(true)
                                          }}
                                        >
                                          <img src={Edit} alt="Edit" />
                                        </Button>
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="Delete"
                                          size="large"
                                          onClick={() =>
                                            toggleDeleteConfirmationDialog(
                                              true,
                                              false,
                                              Number(camp.campaign.campaignId)
                                            )
                                          }
                                        >
                                          <img src={Delete} alt="Delete" />
                                        </Button>
                                      </div>
                                      {/* <div className="right">
                              <div className="form-group">
                                <Textfield
                                  variant="outlined"
                                  label="Location"
                                  withAdornment
                                  adormentComponent={
                                    <img src={LocationGrey} alt="Location" />
                                  }
                                />
                              </div>
                              <Button
                                variant="contained"
                                color="primary"
                                title="Edit"
                                size="small"
                              >
                                <span>Edit</span>
                              </Button>
                            </div> */}
                                    </div>
                                    <div className="campaign-body">
                                      <div className="campaign-details">
                                        <img
                                          src={Campaign1}
                                          alt="Bringing class to cuisine"
                                        />
                                        <div className="inner">
                                          <div className="campaign-type">
                                            <small>Campaign Type :</small>
                                            <Chip
                                              className="primary-chip"
                                              size="small"
                                              label={
                                                camp?.campaign
                                                  ?.campaignTypeId === 1
                                                  ? 'Trigger'
                                                  : 'Scheduled'
                                              }
                                              color="info"
                                            />
                                            <span>
                                              {formatDate(
                                                camp?.campaign?.createdDate
                                              )}
                                            </span>
                                          </div>
                                          <p>{camp?.campaign?.campaignName}</p>
                                          <span>
                                            {
                                              camp?.campaign
                                                ?.campaignDescription
                                            }
                                          </span>
                                          {/* <a href="www.loremipsum.com">
                                    <img src={LinkPrimary} alt="Link" />
                                    <span>www.loremipsum.com</span>
                                  </a> */}
                                          <div className="campaign-date">
                                            <small>Campaign Date :</small>
                                            <span>
                                              {formatDate(
                                                camp?.campaign?.startDate
                                              )}{' '}
                                              to{' '}
                                              {camp?.campaign?.hasEndDate
                                                ? formatDate(
                                                    camp?.campaign?.endDate
                                                  )
                                                : 'Forever Until Turned Off'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <ul>
                                        <li>
                                          <span>New Customers</span>
                                          <small>
                                            {(camp?.newCustomers ?? 0)
                                              .toString()
                                              .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Customer Returns</span>
                                          <small>
                                            {(camp?.customerReturns ?? 0)
                                              .toString()
                                              .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Customers Spend</span>
                                          <small>
                                            $
                                            {camp?.customerSpend
                                              ? camp.customerSpend
                                                  .toFixed(2)
                                                  .replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1,'
                                                  )
                                              : '0.00'}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Top Spending Customers</span>
                                          <small>
                                            $
                                            {camp?.topSpendingCustomers
                                              ? camp.topSpendingCustomers
                                                  .toFixed(2)
                                                  .replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1,'
                                                  )
                                              : '0.00'}
                                          </small>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <span>
                                            Open Rate ({camp?.openCount})
                                          </span>
                                          <small>
                                            {camp?.openRate.toFixed(2)}%
                                          </small>
                                        </li>
                                        <li>
                                          <span>
                                            Click Rate ({camp?.clickCount})
                                          </span>
                                          <small>
                                            {camp?.clickRate.toFixed(2)}%
                                          </small>
                                        </li>
                                        <li>
                                          <span>Email Sent</span>
                                          <small>{camp?.totalSent}</small>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                ))
                            ) : (
                              <li className="inner-card">
                                <span style={{ paddingLeft: '20px' }}>
                                  No Inactive Campaigns Available
                                </span>
                              </li>
                            )}
                          </ul>
                        </TabPanel>
                        <TabPanel value="3">
                          <ul className="campaign-list">
                            {campReportingData?.filter(
                              (camp) => camp?.campaign?.isDraft
                            )?.length > 0 ? (
                              campReportingData
                                ?.filter((camp) => camp?.campaign?.isDraft)
                                ?.map((camp) => (
                                  <li className="inner-card">
                                    <div className="card-header campaign-header">
                                      <div className="left">
                                        <p className="title semibold">
                                          Campaign
                                        </p>
                                        <Chip
                                          className="primary-chip"
                                          size="small"
                                          label={
                                            camp?.campaign?.isActive
                                              ? 'Active'
                                              : 'Inactive'
                                          }
                                          color={
                                            camp?.campaign?.isActive
                                              ? 'success'
                                              : 'info'
                                          }
                                        />
                                      </div>
                                      <div className="right right-icon">
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="View"
                                          size="large"
                                          onClick={() => {
                                            setIsForView(true)
                                            setIsForEdit(false)
                                            setCampaignIdForEdit(
                                              camp?.campaign?.campaignId
                                            )
                                            setOpenCreateCampaignDialog(true)
                                          }}
                                        >
                                          <img src={View} alt="View" />
                                        </Button>
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="Edit"
                                          size="large"
                                          onClick={() => {
                                            setIsForView(false)
                                            setIsForEdit(true)
                                            setCampaignIdForEdit(
                                              camp?.campaign?.campaignId
                                            )
                                            setOpenCreateCampaignDialog(true)
                                          }}
                                        >
                                          <img src={Edit} alt="Edit" />
                                        </Button>
                                        <Button
                                          variant="text"
                                          color="inherit"
                                          title="Delete"
                                          size="large"
                                          onClick={() =>
                                            toggleDeleteConfirmationDialog(
                                              true,
                                              false,
                                              Number(camp.campaign.campaignId)
                                            )
                                          }
                                        >
                                          <img src={Delete} alt="Delete" />
                                        </Button>
                                      </div>
                                      {/* <div className="right">
                              <div className="form-group">
                                <Textfield
                                  variant="outlined"
                                  label="Location"
                                  withAdornment
                                  adormentComponent={
                                    <img src={LocationGrey} alt="Location" />
                                  }
                                />
                              </div>
                              <Button
                                variant="contained"
                                color="primary"
                                title="Edit"
                                size="small"
                              >
                                <span>Edit</span>
                              </Button>
                            </div> */}
                                    </div>
                                    <div className="campaign-body">
                                      <div className="campaign-details">
                                        <img
                                          src={Campaign1}
                                          alt="Bringing class to cuisine"
                                        />
                                        <div className="inner">
                                          <div className="campaign-type">
                                            <small>Campaign Type :</small>
                                            <Chip
                                              className="primary-chip"
                                              size="small"
                                              label={
                                                camp?.campaign
                                                  ?.campaignTypeId === 1
                                                  ? 'Trigger'
                                                  : 'Scheduled'
                                              }
                                              color="info"
                                            />
                                            <span>
                                              {formatDate(
                                                camp?.campaign?.createdDate
                                              )}
                                            </span>
                                          </div>
                                          <p>{camp?.campaign?.campaignName}</p>
                                          <span>
                                            {
                                              camp?.campaign
                                                ?.campaignDescription
                                            }
                                          </span>
                                          {/* <a href="www.loremipsum.com">
                                    <img src={LinkPrimary} alt="Link" />
                                    <span>www.loremipsum.com</span>
                                  </a> */}
                                          <div className="campaign-date">
                                            <small>Campaign Date :</small>
                                            <span>
                                              {formatDate(
                                                camp?.campaign?.startDate
                                              )}{' '}
                                              to{' '}
                                              {camp?.campaign?.hasEndDate
                                                ? formatDate(
                                                    camp?.campaign?.endDate
                                                  )
                                                : 'Forever Until Turned Off'}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <ul>
                                        <li>
                                          <span>New Customers</span>
                                          <small>
                                            {(camp?.newCustomers ?? 0)
                                              .toString()
                                              .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Customer Returns</span>
                                          <small>
                                            {(camp?.customerReturns ?? 0)
                                              .toString()
                                              .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Customers Spend</span>
                                          <small>
                                            $
                                            {camp?.customerSpend
                                              ? camp.customerSpend
                                                  .toFixed(2)
                                                  .replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1,'
                                                  )
                                              : '0.00'}
                                          </small>
                                        </li>
                                        <li>
                                          <span>Top Spending Customers</span>
                                          <small>
                                            $
                                            {camp?.topSpendingCustomers
                                              ? camp.topSpendingCustomers
                                                  .toFixed(2)
                                                  .replace(
                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                    '$1,'
                                                  )
                                              : '0.00'}
                                          </small>
                                        </li>
                                      </ul>
                                      <ul>
                                        <li>
                                          <span>
                                            Open Rate ({camp?.openCount})
                                          </span>
                                          <small>
                                            {camp?.openRate.toFixed(2)}%
                                          </small>
                                        </li>
                                        <li>
                                          <span>
                                            Click Rate ({camp?.clickCount})
                                          </span>
                                          <small>
                                            {camp?.clickRate.toFixed(2)}%
                                          </small>
                                        </li>
                                        <li>
                                          <span>Email Sent</span>
                                          <small>{camp?.totalSent}</small>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                ))
                            ) : (
                              <li className="inner-card">
                                <span style={{ paddingLeft: '20px' }}>
                                  No Draft Campaigns Available
                                </span>
                              </li>
                            )}
                          </ul>
                        </TabPanel>
                      </TabContext>
                    </div>

                    {/* campaign list end */}
                  </TabPanel>
                  <TabPanel value="4">
                    <div className="tabs-header">
                      <div className="left">
                        <h6>Lead Generator</h6>
                      </div>
                      <div className="right">
                        <Button
                          variant="contained"
                          color="primary"
                          title="Create a Form"
                          size="large"
                          onClick={() => setOpenLeadGeneratorFormDialog(true)}
                        >
                          <span>Create a Form</span>
                        </Button>
                      </div>
                    </div>
                    <div className="inner-card grid">
                      <div className="card-header">
                        <p className="title semibold">Lead Generator</p>
                        <div className="form-group search-input">
                          <TextField
                            variant="outlined"
                            InputProps={{
                              autoComplete: 'off',
                              placeholder: 'Search here',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={SearchGrey} alt="Search" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <FasTabGrid
                        checkboxSelection
                        uniqueId="id"
                        columnsData={getLeadGeneratorListColumnDef()}
                        gridData={leadGeneratorListRowsData}
                      />
                    </div>
                  </TabPanel>
                </TabContext>
              </div>
              {/* guest marketing end */}

              {/* create campaign dialog start */}
              <CreateCampaignDialog
                open={openCreateCampaignDialog}
                isEdit={isForEdit}
                isView={isForView}
                campaignIdForEdit={campaignIdForEdit}
                onClose={closeCreateCampaignDialog}
                closeDialog={closeCreateCampaignDialog}
                loginId={Number(localStorage.getItem('loginId'))}
              />
              {/* create campaign dialog end */}

              {/* upgrade guest marketing dialog start */}
              <UpgradeGuestMarketingDialog
                open={openUpgradeGuestMarketingDialog}
                onClose={closeUpgradeGuestMarketingDialog}
              />
              {/* upgrade guest marketing dialog end */}

              {/* lead generator form dialog start */}
              <LeadGeneratorFormDialog
                open={openLeadGeneratorFormDialog}
                onClose={closeLeadGeneratorFormDialog}
              />
              {/* lead generator form dialog end */}
              {/* Delete Campaign dialog start */}
              <DeleteConfirmationDialog
                open={openDeleteCampaignDialog}
                idFieldValue={campaignIdForDelete}
                message="Are you sure you want to delete this Campaign?"
                title="Delete Campaign Confirmation"
                callBack={(reason: DialogOptions, idFieldValue: number) => {
                  toggleDeleteConfirmationDialog(
                    false,
                    reason === DialogOptions.Yes,
                    idFieldValue
                  )
                }}
              />
              <DeleteConfirmationDialog
                open={openInactivateCampaignDialog}
                idFieldValue={campaignIdForInactivate}
                message="Are you sure you want to deactivate this Campaign?"
                title="Deactivate Campaign Confirmation"
                callBack={(reason: DialogOptions, idFieldValue: number) => {
                  toggleInactiveConfirmationDialog(
                    false,
                    reason === DialogOptions.Yes,
                    idFieldValue
                  )
                }}
              />
              {/* Delete Campaign dialog end */}
            </>
          ) : (
            <>
              <div>
                <section className="main-content no-data">
                  <p>
                    Please select Restaurant and Location to view Guest
                    Marketing Page
                  </p>
                </section>
              </div>
            </>
          )}
        </>
      </Layout>
    </>
  )
}

export default GuestMarketing
