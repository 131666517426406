/* eslint-disable no-unused-vars */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import { useFormik } from 'formik'
import { ICountry } from 'interfaces/country'
import { IState } from 'interfaces/state'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import countryService from 'services/country.service'
import stateService from 'services/state.service'
import { resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  editMode?: boolean
  state?: IState
  closeDialog?: (event: boolean) => void
  getNewList?: () => void
}
function AddEditStateDialog(props: dialogProps) {
  const { open, onClose, editMode, closeDialog, getNewList, state } = props
  const [countryList, setCountryList] = useState<ICountry[]>([])
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      stateName: yup.string().required('State name is required'),
      stateCode: yup.string().required('State code is required'),
      countryId: yup.number().required('Country is required'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSave = async (formdata: IState) => {
    if (editMode) {
      const [data] = await resolveHTTP(stateService.UpdateState(formdata))

      if (data) {
        toast.success('State Updated Successfully')
        closeDialog(false)
        getNewList()
      }
    } else {
      const [data] = await resolveHTTP(
        stateService.AddState({
          stateName: formdata.stateName,
          countryId: formdata.countryId,
          stateCode: formdata.stateCode,
        })
      )
      if (data) {
        toast.success('State Added Successfully')
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formik.resetForm()
        closeDialog(false)
        getNewList()
      }
    }
    // eslint-disable-next-line no-unused-expressions
  }

  const fillCountry = async () => {
    const [data] = await resolveHTTP(countryService.getCountries())
    setCountryList(data.data)
  }

  useEffect(() => {
    fillCountry()
    // eslint-disable-next-line
  }, [])
  const formik = useFormik<IState>({
    initialValues: {
      countryId: null,
      stateId: 0,
      stateName: '',
      countryName: '',
      stateCode: '',
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })

  const handleClose = () => {
    formik.resetForm()
  }

  useEffect(() => {
    if (editMode) {
      formik.setFieldValue('countryId', state.countryId)
      formik.setFieldValue('stateId', state.stateId)
      formik.setFieldValue('stateName', state.stateName)
      formik.setFieldValue('stateCode', state.stateCode)
    } else {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Dialog
        className="primary-dialog user-dialog"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
          handleClose()
          onClose(e)
        }}
      >
        <DialogTitle>
          <h5 className="bold">{editMode ? 'Update State' : 'Add State'} </h5>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              onClose(e)
              handleClose()
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="pb-0">
            <div className="form-group">
              <Select
                label="Country"
                showError={
                  !!formik.errors.countryId && formik.touched.countryId
                }
                errorText={
                  !!formik.errors.countryId && formik.touched.countryId
                    ? 'Country is required'
                    : ''
                }
                handleSelectValue={(value) => {
                  console.log(value)
                  formik.setFieldValue('countryId', value)
                }}
                formikValue={formik.values?.countryId}
                items={countryList.map((d) => {
                  return {
                    key: d.countryName,
                    value: d.countryId,
                  }
                })}
              />
            </div>
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="text"
                label="State Name"
                name="stateName"
                value={formik.values?.stateName}
                onChange={formik.handleChange}
                error={!!formik.errors.stateName && formik.touched.stateName}
                helperText={
                  !!formik.errors.stateName && formik.touched.stateName
                    ? formik.errors.stateName
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="text"
                label="State Code"
                name="stateCode"
                value={formik.values?.stateCode}
                onChange={formik.handleChange}
                error={!!formik.errors.stateCode && formik.touched.stateCode}
                helperText={
                  !!formik.errors.stateCode && formik.touched.stateCode
                    ? formik.errors.stateCode
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
          </DialogContent>
          {/* dialog-content end */}
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title={editMode ? 'Update' : 'Add'}
                type="submit"
              >
                {editMode ? 'Update' : 'Add'}
              </Button>

              <Button
                onClick={(e) => {
                  onClose(e)
                  handleClose()
                }}
                title="Cancel"
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default AddEditStateDialog
