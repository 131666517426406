import React from 'react'
import { FormControlLabel, Switch as MaterialSwitch } from '@mui/material'

interface props {
  label?: string
  placement?: 'bottom' | 'end' | 'start' | 'top'
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  defaultChecked?: boolean
  name?: string
  key?: string
  checked?: boolean
  className?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

function Switch({
  label,
  placement,
  onChange,
  onClick,
  defaultChecked,
  name,
  key,
  checked,
  className,
  disabled,
}: props) {
  return (
    <>
      <FormControlLabel
        className={`primary-switch ${className}`}
        value={label}
        control={
          <MaterialSwitch
            defaultChecked={defaultChecked}
            key={key}
            onChange={onChange}
            checked={checked}
            onClick={onClick}
            disabled={disabled}
          />
        }
        label={label}
        labelPlacement={placement}
        name={name}
      />
    </>
  )
}

export default Switch
