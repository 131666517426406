/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useCallback } from 'react'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker'
import {
  format,
  sub,
  startOfDay,
  endOfDay,
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  endOfMonth,
  addDays,
} from 'date-fns'

import Box from '@mui/material/Box'

export default function DateFilter({
  setFilterSelectedDate,
  clear,
  displayText,
  isDashboard,
  isCompare,
  daysInBetween,
  onClose,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    onClose()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    onClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const startOfToday = startOfDay(new Date())
  const today = [startOfToday, endOfDay(startOfToday)]
  const startOfYesterday = startOfDay(sub(new Date(), { days: 1 }))
  const yesterday = [startOfYesterday, endOfDay(startOfYesterday)]
  const thisWeek = [startOfISOWeek(startOfToday), endOfISOWeek(startOfToday)]
  const weekAgo = sub(new Date(), { days: 7 })
  const lastWeek = [startOfISOWeek(weekAgo), endOfISOWeek(weekAgo)]
  const twoWeekAgo = sub(new Date(), { days: 14 })
  const pastTwoWeeks = [startOfISOWeek(twoWeekAgo), endOfISOWeek(weekAgo)]
  const thisMonth = [startOfMonth(startOfToday), endOfMonth(startOfToday)]
  const startOfLastMonth = sub(startOfMonth(new Date()), { months: 1 })
  const lastMonth = [startOfLastMonth, endOfMonth(startOfLastMonth)]

  const [value, setValue] = useState([null, null])
  const [displayValue, setDisplayValue] = useState(
    isDashboard ? 'Yesterday' : 'Today'
  )

  const [key, setKey] = useState(0)

  const compareDates = (dates1, dates2) => {
    let formattedOne = []
    let formattedTwo = []
    if (Array.isArray(dates1) && dates1.every((x) => x != null)) {
      formattedOne = dates1.map((x) => format(x, 'MM/dd/yyyy'))
    }
    if (Array.isArray(dates2) && dates2.every((x) => x != null)) {
      formattedTwo = dates2.map((x) => format(x, 'MM/dd/yyyy'))
    }
    return JSON.stringify(formattedOne) === JSON.stringify(formattedTwo)
  }

  const setDisplayDate = (dates) => {
    let text = ''
    if (isDashboard === true) {
      if (compareDates(dates, yesterday)) {
        text = 'Yesterday'
      } else if (compareDates(dates, thisWeek)) {
        text = 'This Week'
      } else if (compareDates(dates, lastWeek)) {
        text = 'Last Week'
      } else if (compareDates(dates, pastTwoWeeks)) {
        text = 'Past Two Weeks'
      } else if (compareDates(dates, thisMonth)) {
        text = 'This Month'
      } else if (compareDates(dates, lastMonth)) {
        text = 'Last Month'
      } else {
        text = `${format(dates[0], 'MMM d')} - ${format(dates[1], 'MMM d')}`
      }
    } else if (isDashboard === false) {
      if (compareDates(dates, today)) {
        text = 'Today'
      } else if (compareDates(dates, yesterday)) {
        text = 'Yesterday'
      } else if (compareDates(dates, thisWeek)) {
        text = 'This Week'
      } else if (compareDates(dates, lastWeek)) {
        text = 'Last Week'
      } else if (compareDates(dates, pastTwoWeeks)) {
        text = 'Past Two Weeks'
      } else if (compareDates(dates, thisMonth)) {
        text = 'This Month'
      } else if (compareDates(dates, lastMonth)) {
        text = 'Last Month'
      } else {
        text = `${format(dates[0], 'MMM d')} - ${format(dates[1], 'MMM d')}`
      }
    }
    setDisplayValue(text)
  }

  const selectDates = useCallback((dates) => {
    setValue(dates)
    setFilterSelectedDate(dates)
    setDisplayDate(dates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (clear) {
      const sod = startOfDay(new Date())
      selectDates([sod, endOfDay(sod)])
    }
    if (displayText) {
      selectDates(displayText)
    }
  }, [clear, selectDates, displayText])
  // eslint-disable-next-line @typescript-eslint/no-empty-function, prettier/prettier
  const handleSelect = () => {}

  const handleChangec = (newValue) => {
    if (daysInBetween > 1) {
      const startDate = newValue[0]
      const endDate = addDays(newValue[0], daysInBetween - 1)
      selectDates([startDate, endOfDay(endDate)])
      handleClose()
    } else {
      const startDate = newValue[0]
      const endDate = addDays(newValue[0], daysInBetween)
      selectDates([startDate, endOfDay(endDate)])
      handleClose()
    }
  }

  const handleSelectc = (newValue) => {
    if (daysInBetween > 1) {
      const startDate = newValue[0]
      const endDate = addDays(newValue[0], daysInBetween - 1)
      const updatedValue = [startDate, endOfDay(endDate)]
      setValue(updatedValue)
      handleChangec(updatedValue)
    } else {
      const startDate = newValue[0]
      const endDate = addDays(newValue[0], daysInBetween)
      const updatedValue = [startDate, endOfDay(endDate)]
      setValue(updatedValue)
      handleChangec(updatedValue)
    }
  }

  const handleChange = (newValue) => {
    const startDate = newValue[0]
    const endDate = endOfDay(newValue[1])
    selectDates([startDate, endDate])
    handleClose()
  }

  const setToday = () => {
    selectDates(today)
    setKey(key + 1)
    handleClose()
  }

  const setYesterday = () => {
    selectDates(yesterday)
    setKey(key + 1)
    handleClose()
  }

  const setThisWeek = () => {
    selectDates(thisWeek)
    setKey(key + 1)
    handleClose()
  }

  const setLastWeek = () => {
    selectDates(lastWeek)
    setKey(key + 1)
    handleClose()
  }

  const setPastTwo = () => {
    selectDates(pastTwoWeeks)
    setKey(key + 1)
    handleClose()
  }

  const setThisMonth = () => {
    selectDates(thisMonth)
    setKey(key + 1)
    handleClose()
  }

  const setLastMonth = () => {
    selectDates(lastMonth)
    setKey(key + 1)
    handleClose()
  }

  // const setThisYear = () => {
  //   const today = new Date()
  //   const dates = [startOfYear(today), endOfYear(today)]
  //   selectDates(dates)
  //   setKey(key + 1)
  //   handleClose()
  // }

  // const setLastYear = () => {
  //   const start = sub(startOfYear(new Date()), { years: 1 })
  //   const dates = [start, endOfYear(start)]
  //   selectDates(dates)
  //   setKey(key + 1)
  //   handleClose()
  // }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <input
        value={displayValue}
        onClick={handleClick}
        className="dateFilterInput"
        style={{ cursor: 'pointer' }}
        readOnly
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="dateFilter">
          {!isCompare ? (
            <>
              <ul>
                {!isDashboard && (
                  <li>
                    <button type="button" onClick={setToday}>
                      Today
                    </button>
                  </li>
                )}
                <li>
                  <button type="button" onClick={setYesterday}>
                    Yesterday
                  </button>
                </li>
                <li>
                  <button type="button" onClick={setThisWeek}>
                    This Week
                  </button>
                </li>
                <li>
                  <button type="button" onClick={setLastWeek}>
                    Last Week
                  </button>
                </li>
                <li>
                  <button type="button" onClick={setPastTwo}>
                    Past Two Weeks
                  </button>
                </li>
                <li>
                  <button type="button" onClick={setThisMonth}>
                    This Month
                  </button>
                </li>
                <li>
                  <button type="button" onClick={setLastMonth}>
                    Last Month
                  </button>
                </li>
                {/* <li>
              <button type="button" onClick={setThisYear}>
                This Year
              </button>
            </li>
            <li>
              <button type="button" onClick={setLastYear}>
                Last Year
              </button>
            </li> */}
              </ul>
              <StaticDateRangePicker
                key={key}
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={(newValue) => {
                  handleSelect(newValue)
                }}
                minDate={null}
                maxDate={isDashboard ? startOfYesterday : startOfToday}
                onAccept={(newValue) => {
                  handleChange(newValue)
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </>
          ) : (
            <>
              <StaticDateRangePicker
                key={key}
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={handleSelectc}
                minDate={null}
                maxDate={isDashboard ? startOfYesterday : startOfToday}
                onAccept={handleChangec}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </>
                )}
              />
            </>
          )}
        </div>
      </Popover>
    </LocalizationProvider>
  )
}
