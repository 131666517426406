/* eslint-disable no-unused-vars */
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/Index'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import Select from 'components/Select/Index'
import Switch from 'components/Switch/Index'
import Textfield from 'components/Textfield/Index'
import { DialogOptions } from 'enums/Common'
import { restaurantList } from 'features/restaurant/restaurant'
import { useFormik } from 'formik'
import { IUserAddUpdateRequest, IUserListResponse } from 'interfaces/user'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import establishmentService from 'services/establishment.service'
import userService from 'services/user.service'
import { ADMIN_ROLE_ID, PHONE_NUMBER_REGEX } from 'utility/constants'
import { formatPhoneNumber, resolveHTTP } from 'utility/helper'
import * as yup from 'yup'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  editMode?: boolean
  userData?: IUserListResponse
  closeDialog?: (event: boolean) => void
  getNewList?: () => void
  restId?: number
  isFromRestaurant?: boolean
  isFromLocation?: boolean
  estId?: number[]
  restaurantIdForEstUser?: number
  isFromMyProfile?: boolean
}
function AddEditUserDialog(props: dialogProps) {
  const {
    open,
    onClose,
    editMode,
    userData,
    closeDialog,
    getNewList,
    restId,
    isFromRestaurant,
    isFromLocation,
    estId,
    restaurantIdForEstUser,
    isFromMyProfile,
  } = props
  const STATUS = { INACTIVE: 2, ACTIVE: 3, DEFAULT: 0 }
  const restuarantList = useSelector(restaurantList)
  const [establishmentLists, setEstablishmentLists] = useState([])
  const [selectedEstIds, setSelectedEstIds] = useState([])
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [roleList, setRoleList] = useState([])
  const [restaurantVisibility, setRestaurantVisibility] = useState(false)
  const [locationVisibility, setLocationVisibility] = useState(false)
  const [userId, setUserId] = useState(0)
  const [selectedRestraunt, setSelectedRestraunt] = useState(null)
  const [disableRestaurant, setDisableRestaurant] = useState(false)
  const [disableLocation, setDisableLocation] = useState(false)
  const RestaurantRoles = [3, 4, 6, 7]
  const EstablishmentRoles = [6, 7]

  // setDisableOnMyProfile(true)
  const toggleDeleteConfirmationDialog = useCallback(
    async (isOpen: boolean, isSubmit = true, id: number) => {
      setOpenDeleteConfirmation(isOpen)
      setUserId(id)
      if (isSubmit && !isOpen && id > 0) {
        try {
          await userService.deleteUser({
            loginId: id,
          })
          toast.success('User deleted successfully.')
          closeDialog(false)
          getNewList()
        } catch (error) {
          toast.error('Something wrong with deleting the records.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getRoleList = useCallback(
    async () => {
      try {
        const data = await userService.getRoleList()
        const result = data.data.data
        let filtered
        // const indexOfObject = result.findIndex((object: { roleId: number }) => {
        //   return object.roleId === 5
        // })
        // result.splice(indexOfObject, 1)
        if (isFromRestaurant) {
          filtered = result.filter(
            (obj: { roleId: number }) => obj.roleId === 3 || obj.roleId === 4
          )
        } else if (isFromLocation) {
          filtered = result.filter(
            (obj: { roleId: number }) =>
              // obj.roleId === 3 ||
              // obj.roleId === 4 ||
              obj.roleId === 6 || obj.roleId === 7
          )
        } else {
          filtered = result.filter(
            (obj: { roleId: number }) =>
              obj.roleId === 3 ||
              obj.roleId === 4 ||
              obj.roleId === 6 ||
              obj.roleId === 7 ||
              obj.roleId === 1 ||
              obj.roleId === 2
          )
        }

        setRoleList(filtered)
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const validationSchema = useMemo(() => {
    return yup.object().shape({
      loginName: yup
        .string()
        .email('Email is not valid')
        .when('roleId', {
          is: ADMIN_ROLE_ID,
          then: yup
            .string()
            .matches(
              /@(tatvasoft|fastabpayments)\.com$/,
              'Email is not valid for Admin'
            )
            .required('Email is required'),
          otherwise: yup.string().required('Email is required'),
        }),
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      roleId: yup.number().required('Role is required'),
      restaurantId: yup.number().when('roleId', {
        is: (val: number) => RestaurantRoles.includes(val),
        then: yup.number().moreThan(0, 'Restaurant is required'),
        otherwise: yup.number(),
      }),
      establishmentId: yup.array().when('roleId', {
        is: (val: number) => EstablishmentRoles.includes(val),
        then: yup.array().min(1, 'Location is required'),
        otherwise: yup.array(),
      }),
      phoneNumber: yup
        .string()
        .required('Phone number is required')
        .matches(PHONE_NUMBER_REGEX, 'Phone number is not valid'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSave = async (formdata: IUserAddUpdateRequest) => {
    try {
      if (editMode || isFromMyProfile) {
        if (isFromMyProfile) {
          formdata.loginId = Number(localStorage.getItem('loginId'))
          localStorage.removeItem('firstName')
          localStorage.setItem('firstName', formdata.firstName)
          localStorage.removeItem('lastName')
          localStorage.setItem('lastName', formdata.lastName)
        } else {
          formdata.loginId = userData.loginId
        }
        const [data] = await resolveHTTP(userService.updateUser(formdata))

        if (data) {
          toast.success(
            `${
              isFromMyProfile
                ? 'Profile Updated Successfully'
                : 'User Updated Successfully'
            }
        `
          )
          closeDialog(false)
          getNewList()
        }
      } else {
        const [data] = await resolveHTTP(userService.addUser(formdata))
        if (data) {
          toast.success('User added successfully')
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          formik.resetForm()
          closeDialog(false)
          setDisableRestaurant(false)
          setDisableLocation(false)
          getNewList()
        }
      }
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line no-unused-expressions
  }

  const handleVisibility = (value: number) => {
    if (value === 3 || value === 4 || value === 7 || value === 6) {
      setRestaurantVisibility(true)
    } else {
      setRestaurantVisibility(false)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.setFieldValue('restaurantId', 0)
    }
    if (value === 7 || value === 6) {
      setLocationVisibility(true)
    } else {
      setLocationVisibility(false)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.setFieldValue('establishmentId', [])
    }
  }
  const formik = useFormik<IUserAddUpdateRequest>({
    initialValues: {
      loginId: 0,
      restaurantId: 0,
      firstName: '',
      lastName: '',
      isActive: true,
      loginName: '',
      isLockedOut: false,
      roleId: null,
      phoneNumber: '',
      establishmentId: [],
      loginTypeId: 2,
      isTwoFactorEnabled: false,
      isGuestMarketingEnable: false,
    },
    validationSchema,
    onSubmit: (values) => handleSave(values),
  })
  const getEstablishmentList = useCallback(
    async () => {
      try {
        const data = await establishmentService.getAllEstablishments({
          pageNo: 1,
          pageSize: 0,
          sortGridModels: [{ field: 'restaurantName', sort: 0 }],
          restaurantId: selectedRestraunt,
        })

        const list = data.data.data.data.map((e) => {
          return {
            key: e.establishmentName,
            value: e.establishmentId,
          }
        })
        setEstablishmentLists(list)
        formik.setFieldValue('establishmentId', selectedEstIds)
      } catch (e) {
        if (localStorage.length !== 0) {
          toast.error('Failed to retrieve establishment list.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestraunt, selectedEstIds]
  )

  const getUserDetail = useCallback(
    async (loginId: number) => {
      const data = await userService.getUserById({
        loginId,
      })
      const userDetailData = data?.data?.data
      if (editMode || isFromMyProfile) {
        formik.setFieldValue('firstName', userDetailData.firstName)
        formik.setFieldValue('lastName', userDetailData.lastName)
        formik.setFieldValue('loginName', userDetailData.loginName)
        formik.setFieldValue('phoneNumber', userDetailData.phoneNumber)
        formik.setFieldValue(
          'isTwoFactorEnabled',
          userDetailData.isTwoFactorEnabled
        )
        formik.setFieldValue('restaurantId', userDetailData.restaurantId)
        setSelectedRestraunt(userDetailData.restaurantId)
        formik.setFieldValue('roleId', userDetailData.roleId)
        handleVisibility(userDetailData.roleId)
        formik.setFieldValue(
          'establishmentId',
          userDetailData.establishmentId ?? []
        )
        setSelectedEstIds(userDetailData.establishmentId ?? [])
        formik.setFieldValue('isActive', userDetailData.isActive)
        formik.setFieldValue('isLockedOut', userDetailData.isLockedOut)
        formik.setFieldValue(
          'isGuestMarketingEnable',
          userDetailData.isGuestMarketingEnable
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik]
  )
  const handleClose = () => {
    formik.resetForm()
    setRestaurantVisibility(false)
    setLocationVisibility(false)
    setDisableRestaurant(false)
    setDisableLocation(false)
    setSelectedEstIds([])
  }
  const getRestaurantId = () => {
    if (restId !== 0 && isFromRestaurant) {
      formik.setFieldValue('restaurantId', restId)
      setRestaurantVisibility(true)
      setDisableRestaurant(true)
    } else {
      setRestaurantVisibility(false)
      formik.setFieldValue('restaurantId', 0)
    }
  }
  const getEstablishmentId = () => {
    if (estId?.length && isFromLocation) {
      formik.setFieldValue('restaurantId', restaurantIdForEstUser)
      setSelectedRestraunt(restaurantIdForEstUser)
      formik.setFieldValue('establishmentId', estId)
      setSelectedEstIds(estId)
      setRestaurantVisibility(true)
      setLocationVisibility(true)
      setDisableLocation(true)
      setDisableRestaurant(true)
    } else {
      setLocationVisibility(false)
      formik.setFieldValue('establishmentId', selectedEstIds)
    }
  }
  useEffect(() => {
    if (selectedRestraunt !== null) {
      getEstablishmentList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestraunt])

  useEffect(() => {
    getRoleList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (editMode) {
      getUserDetail(userData.loginId)
    } else {
      formik.resetForm()
      if (open) {
        if (isFromMyProfile) {
          getUserDetail(Number(localStorage.getItem('loginId')))
        }
        getRestaurantId()
        getEstablishmentId()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Dialog
        className="primary-dialog user-dialog"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
          handleClose()
          onClose(e)
        }}
      >
        <DialogTitle>
          <h5 className="bold">
            {editMode || isFromMyProfile ? 'Update' : 'Add'}{' '}
            {isFromMyProfile ? 'Profile' : 'User'}
          </h5>
          <Button
            variant="text"
            title="Close"
            onClick={(e) => {
              onClose(e)
              handleClose()
            }}
            className="icon-btn rounded"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        {/* dialog-content start */}
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="pb-0">
            <div className="form-group">
              <Select
                label="Role"
                disabled={isFromMyProfile}
                formikValue={formik.values?.roleId}
                showError={!!formik.errors.roleId && formik.touched.roleId}
                errorText={
                  !!formik.errors.roleId && formik.touched.roleId
                    ? 'Role is required'
                    : ''
                }
                handleSelectValue={(value) => {
                  formik.setFieldValue('roleId', value)
                  handleVisibility(value)
                }}
                items={
                  roleList &&
                  roleList.map((d) => {
                    return {
                      key: d.displayValue,
                      value: d.roleId,
                    }
                  })
                }
              />
            </div>
            {restaurantVisibility && (
              <div className="form-group">
                <Select
                  label="Restaurant"
                  disabled={disableRestaurant || isFromMyProfile}
                  showError={
                    !!formik.errors.restaurantId && formik.touched.restaurantId
                  }
                  errorText={
                    !!formik.errors.restaurantId && formik.touched.restaurantId
                      ? 'Restaurant is required'
                      : ''
                  }
                  handleSelectValue={(value) => {
                    formik.setFieldValue('restaurantId', value)
                    setSelectedRestraunt(value)
                  }}
                  formikValue={formik.values?.restaurantId}
                  items={restuarantList.map((d) => {
                    return {
                      key: d.name,
                      value: d.restaurantId,
                    }
                  })}
                />
              </div>
            )}
            {locationVisibility && (
              <div className="form-group">
                <MultiSelect
                  label="Location"
                  disabled={disableLocation || isFromMyProfile}
                  showError={
                    !!formik.errors.establishmentId &&
                    formik.touched.establishmentId
                  }
                  errorText={
                    !!formik.errors.establishmentId &&
                    formik.touched.establishmentId
                      ? 'Location is required'
                      : ''
                  }
                  defaultValue={
                    formik.values.establishmentId?.length > 0
                      ? formik.values.establishmentId?.map((xx) =>
                          xx.toString()
                        )
                      : selectedEstIds?.map((xx) => xx.toString())
                  }
                  items={establishmentLists}
                  handleSelectValue={(value) => {
                    formik.setFieldValue(
                      'establishmentId',
                      value?.map((x) => Number(x)).filter((x) => x > 0) ?? []
                    )
                    setSelectedEstIds(
                      value.map((x) => Number(x)).filter((x) => x > 0)
                    )
                  }}
                />
              </div>
            )}
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="email"
                disable={isFromMyProfile}
                label="Email"
                name="loginName"
                value={formik.values?.loginName}
                onChange={formik.handleChange}
                error={!!formik.errors.loginName && formik.touched.loginName}
                helperText={
                  !!formik.errors.loginName && formik.touched.loginName
                    ? formik.errors.loginName
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="text"
                label="First Name"
                name="firstName"
                value={formik.values?.firstName}
                onChange={formik.handleChange}
                error={!!formik.errors.firstName && formik.touched.firstName}
                helperText={
                  !!formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="text"
                label="Last Name"
                name="lastName"
                value={formik.values?.lastName}
                onChange={formik.handleChange}
                error={!!formik.errors.lastName && formik.touched.lastName}
                helperText={
                  !!formik.errors.lastName && formik.touched.lastName
                    ? formik.errors.lastName
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group">
              <Textfield
                variant="outlined"
                type="text"
                label="Phone Number"
                name="phoneNumber"
                value={
                  formik.values?.phoneNumber === null ||
                  formik.values?.phoneNumber === undefined
                    ? ''
                    : formik.values?.phoneNumber
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    'phoneNumber',
                    formatPhoneNumber(e.target.value)
                  )
                }}
                error={
                  !!formik.errors.phoneNumber && formik.touched.phoneNumber
                }
                helperText={
                  !!formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? formik.errors.phoneNumber
                    : ''
                }
                handleBlur={formik.handleBlur}
              />
            </div>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Select
                    label="Status"
                    disabled={isFromMyProfile}
                    items={[
                      {
                        key: 'Active',
                        value: STATUS.ACTIVE,
                      },
                      {
                        key: 'Inactive',
                        value: STATUS.INACTIVE,
                      },
                    ]}
                    formikValue={formik.values.isActive === true ? 3 : 2}
                    handleSelectValue={(value) => {
                      formik.setFieldValue('isActive', value === STATUS.ACTIVE)
                    }}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Select
                    label="Account Locked"
                    disabled={isFromMyProfile}
                    items={[
                      {
                        key: 'Yes',
                        value: 1,
                      },
                      {
                        key: 'No',
                        value: 2,
                      },
                    ]}
                    formikValue={formik.values.isLockedOut ? 1 : 2}
                    handleSelectValue={(value) => {
                      formik.setFieldValue('isLockedOut', value === 1)
                    }}
                  />
                </div>
              </Grid>
              {formik.values?.roleId !== 1 && (
                <Grid item xs={12}>
                  <div className="form-group">
                    <span className="enable-two-fa">
                      Enable Guest Marketing Page
                    </span>
                    {'  '}
                    <Switch
                      name="isGuestMarketingEnable"
                      placement="bottom"
                      onChange={(e) => {
                        formik.setFieldValue(
                          'isGuestMarketingEnable',
                          e.target.checked
                        )
                      }}
                      checked={formik.values.isGuestMarketingEnable}
                    />
                  </div>
                </Grid>
              )}
              <Grid item sm={6} xs={12}>
                <div className="form-group">
                  <Checkbox
                    color="primary"
                    checked={formik.values.isTwoFactorEnabled}
                    onChange={formik.handleChange}
                    name="isTwoFactorEnabled"
                  />{' '}
                  <span className="enable-two-fa">Enable Two Factor?</span>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          {/* dialog-content end */}
          <DialogActions>
            <div className="btn-list">
              <Button
                variant="contained"
                color="primary"
                title={editMode || isFromMyProfile ? 'Update' : 'Add'}
                type="submit"
              >
                {editMode || isFromMyProfile ? 'Update' : 'Add'}
              </Button>
              {editMode && (
                <Button
                  variant="contained"
                  color="primary"
                  title="Delete"
                  onClick={() =>
                    toggleDeleteConfirmationDialog(
                      true,
                      false,
                      userData.loginId
                    )
                  }
                >
                  Delete
                </Button>
              )}
              <Button
                onClick={(e) => {
                  onClose(e)
                  handleClose()
                }}
                title="Cancel"
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
      <DeleteConfirmationDialog
        open={openDeleteConfirmation}
        idFieldValue={userId}
        message="Are you sure you want to delete this User?"
        callBack={(reason: DialogOptions, idFieldValue: number) => {
          toggleDeleteConfirmationDialog(
            false,
            reason === DialogOptions.Yes,
            idFieldValue
          )
        }}
      />
    </>
  )
}

export default AddEditUserDialog
