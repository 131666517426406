export const newsLetterContent = {
  counters: {
    u_column: 10,
    u_row: 7,
    u_content_heading: 3,
    u_content_image: 7,
    u_content_text: 8,
  },
  body: {
    id: 'VerTgZlmxG',
    rows: [
      {
        id: 'ZuFM1FImCs',
        cells: [1],
        columns: [
          {
            id: 'vxfCnBjMZe',
            contents: [
              {
                id: 'OpsJ1ZHfYq',
                type: 'heading',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  headingType: 'h1',
                  fontSize: '22px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_heading_1',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span>Company Logo</span>',
                  _languages: {},
                },
              },
              {
                id: '-u26yaLqQ_',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://hs-47920320.f.hubspotfree.net/hs/hsstatic/content_shared_assets/static-1.4079/img/hs_default_template_images/email_dnd_template_images/newsletter-square.png',
                    width: 300,
                    height: 268,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  pending: false,
                },
              },
              {
                id: 'aHD9qIkfgd',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '18px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><strong>Share your latest news and updates</strong></p>',
                  _languages: {},
                },
              },
              {
                id: 'aHxvpIoUEJ',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_1',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Set the stage by telling the reader what the main topic is. Your main story should be at the top of the email with a CTA button that links to a corresponding web page or blog post. Don’t include the entire story in your email.</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_1',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
            customPosition: ['50%', '50%'],
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_1',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'DxFxfhZXEJ',
        cells: [1, 1],
        columns: [
          {
            id: 'CaVCJ0leU0',
            contents: [
              {
                id: '4KPOoYy_5M',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://hs-47920320.f.hubspotfree.net/hs/hsstatic/content_shared_assets/static-1.4079/img/hs_default_template_images/email_dnd_template_images/newsletter-square.png',
                    width: 300,
                    height: 268,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_image_2',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  pending: false,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_3',
                htmlClassNames: 'u_column',
              },
            },
          },
          {
            id: 'ypFySoY75V',
            contents: [
              {
                id: 'o7YQ6kSSGu',
                type: 'heading',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  headingType: 'h1',
                  fontSize: '22px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_heading_2',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span>Spread the word</span>',
                  _languages: {},
                },
              },
              {
                id: 'pSUtOB9UE4',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_3',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">What interesting projects are you working on? What new products are you releasing? Share a brief description and then include a link to a web page or blog post.</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_4',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_3',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: '5DYwT08fs1',
        cells: [1, 1],
        columns: [
          {
            id: '305sB2XGCB',
            contents: [
              {
                id: '4N4IZ7Cxrx',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://hs-47920320.f.hubspotfree.net/hs/hsstatic/content_shared_assets/static-1.4079/img/hs_default_template_images/email_dnd_template_images/newsletter-square.png',
                    width: 300,
                    height: 268,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_image_3',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  pending: false,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_7',
                htmlClassNames: 'u_column',
              },
            },
          },
          {
            id: 'hB0lF-buUp',
            contents: [
              {
                id: 'GAIVnLTjKC',
                type: 'heading',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  headingType: 'h1',
                  fontSize: '22px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_heading_3',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<span>Spread the word</span>',
                  _languages: {},
                },
              },
              {
                id: 'N59i8qNt5R',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_4',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">What interesting projects are you working on? What new products are you releasing? Share a brief description and then include a link to a web page or blog post.</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_8',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_5',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'k2-XK5JlRt',
        cells: [1],
        columns: [
          {
            id: '8pQ3vGhYBj',
            contents: [
              {
                id: 'U8n4dnvWEx',
                type: 'text',
                values: {
                  containerPadding: '7px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_5',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Invite people to share your newsletter with their colleagues or on social media.</p>\n<p style="line-height: 140%;"> </p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_9',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '#ffffff',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_31',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'V0iDJFwxRW',
        cells: [1],
        columns: [
          {
            id: '9E63q9AVJE',
            contents: [
              {
                id: 'pXIaGza31W',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_22',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">You received this email because you opted in to receive emails in our location(s), website, reservations, online ordering, payments, or social media. If you wish to unsubscribe, click unsubscribe below.</p>',
                  _languages: {},
                },
              },
              {
                id: 'VmIyMCaHsD',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: false,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                    body: false,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_23',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><a rel="noopener" href="{{unsubscribe_link}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVfbGlua319IiwidGFyZ2V0IjoiX2JsYW5rIn19">Unsubscribe</a></p>',
                  _languages: {},
                },
              },
              {
                id: 'V5k64cuws4',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '13px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_25',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: false,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">{{location_address}}</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              _meta: {
                htmlID: 'u_column_46',
                htmlClassNames: 'u_column',
              },
              border: {},
              padding: '0px',
              borderRadius: '0px',
              backgroundColor: '',
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_34',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: false,
          hideable: true,
        },
      },
    ],
    headers: [],
    footers: [],
    values: {
      _styleGuide: null,
      popupPosition: 'bottom',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: {
        label: 'Arial',
        value: 'arial,helvetica,sans-serif',
      },
      textColor: '#000000',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      language: {},
      backgroundColor: '#F9F9F9',
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      _meta: {
        htmlID: 'u_body',
        description: '',
        htmlClassNames: 'u_body',
      },
    },
  },
  schemaVersion: 17,
}
export const simpleContent = {
  counters: {
    u_column: 2,
    u_row: 2,
    u_content_text: 8,
    u_content_social: 1,
    u_content_image: 1,
  },
  body: {
    id: 'I1b1P93pod',
    rows: [
      {
        id: '553Eekvrbh',
        cells: [1],
        columns: [
          {
            id: 'CowWGgh-JL',
            contents: [
              {
                id: 'N07HnFWuIV',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_1',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Hello CONTACT.FIRSTNAME,</p>',
                  _languages: {},
                },
              },
              {
                id: 'CA5E3EbWHe',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #000000; line-height: 19.6px;">Plain text emails have minimal formatting so your reader can really focus on what you have to say. Introduce yourself and explain why you’re reaching out.</span></p>\n<p style="line-height: 140%;"><span style="color: #000000; line-height: 19.6px;">Every email should try to lead the reader to some kind of action. Use this space to describe why the reader should want to click on the link below. Put the link on its own line to really draw their eye to it.</span></p>',
                  _languages: {},
                },
              },
              {
                id: 'eWgbwUDqll',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_4',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="text-decoration: underline; line-height: 19.6px; color: #3598db;">Link Text</span></p>',
                  _languages: {},
                },
              },
              {
                id: 'qu7nQN838-',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 400,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_5',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #000000; line-height: 19.6px;">Now it’s time to wrap up your email. Before your signature, thank the recipient for reading. You can also invite them to send this email to any of their colleagues who might be interested.</span></p>\n<p style="line-height: 140%;"><span style="color: #000000; line-height: 19.6px;">All the best,</span><br /><span style="color: #000000; line-height: 19.6px;">Your full name</span><br /><span style="color: #000000; line-height: 19.6px;">Your job title</span><br /><span style="color: #000000; line-height: 19.6px;">Other contact information</span></p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_1',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '#ffffff',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_31',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'V0iDJFwxRW',
        cells: [1],
        columns: [
          {
            id: '9E63q9AVJE',
            contents: [
              {
                id: 'pXIaGza31W',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_22',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">You received this email because you opted in to receive emails in our location(s), website, reservations, online ordering, payments, or social media. If you wish to unsubscribe, click unsubscribe below.</p>',
                  _languages: {},
                },
              },
              {
                id: 'VmIyMCaHsD',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: false,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                    body: false,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_23',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><a rel="noopener" href="{{unsubscribe_link}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVfbGlua319IiwidGFyZ2V0IjoiX2JsYW5rIn19">Unsubscribe</a></p>',
                  _languages: {},
                },
              },
              {
                id: 'V5k64cuws4',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '13px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_25',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: false,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">{{location_address}}</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              _meta: {
                htmlID: 'u_column_46',
                htmlClassNames: 'u_column',
              },
              border: {},
              padding: '0px',
              borderRadius: '0px',
              backgroundColor: '',
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_34',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: false,
          hideable: true,
        },
      },
    ],
    headers: [],
    footers: [],
    values: {
      _styleGuide: null,
      popupPosition: 'bottom',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: {
        label: 'Arial',
        value: 'arial,helvetica,sans-serif',
      },
      textColor: '#000000',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      language: {},
      backgroundColor: '#F9F9F9',
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      _meta: {
        htmlID: 'u_body',
        description: '',
        htmlClassNames: 'u_body',
      },
    },
  },
  schemaVersion: 17,
}

export const WelcomeContent = {
  counters: {
    u_column: 4,
    u_row: 4,
    u_content_heading: 1,
    u_content_text: 12,
    u_content_image: 1,
  },
  body: {
    id: 'KdMUktq9q5',
    rows: [
      {
        id: 'h856mqIjrN',
        cells: [1],
        columns: [
          {
            id: 'qrDbebOYfz',
            contents: [
              {
                id: 'XkkxF8H9Vo',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '28px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_1',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #e67e23; line-height: 39.2px;">Company Logo</span></p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_2',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_2',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: '8XJXCK4Ov6',
        cells: [1],
        columns: [
          {
            id: 'PedvGTFjRV',
            contents: [
              {
                id: 'QOeWgyOdty',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Welcome friend, thank the reader for signing up to your newsletter and welcome them on board. Below your introduction, add a few links to some popular pages or posts on your website to give the reader an idea of what’s to come.</p>',
                  _languages: {},
                },
              },
              {
                id: 'fW_K7ViFSk',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_3',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #236fa1; line-height: 19.6px;">1. Showcase your best stories</span></p>',
                  _languages: {},
                },
              },
              {
                id: 'Rz54h8ub_v',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_4',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Give an overview of an existing blog post or a popular story from a previous newsletter. Be sure to add a <span style="color: #0000ee; line-height: 19.6px;">link</span> so the reader can learn more.</p>',
                  _languages: {},
                },
              },
              {
                id: 'Xxd6Jn6ab7',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_5',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #236fa1; line-height: 19.6px;">2. Help people get to know you</span></p>',
                  _languages: {},
                },
              },
              {
                id: 'E1nnBcmDe3',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_6',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Share a link to your website’s<span style="color: #0000ee; line-height: 19.6px;"> about us</span> page where the reader can learn more about you and your community.</p>',
                  _languages: {},
                },
              },
              {
                id: 'ZfhN9yS5Xl',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_7',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #236fa1; line-height: 19.6px;">3. Keep the conversation going</span></p>',
                  _languages: {},
                },
              },
              {
                id: '-8E02tpyoX',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_8',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Tell your reader how they can get in touch if they have questions.</p>',
                  _languages: {},
                },
              },
              {
                id: 'MBCtnD9YXe',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_9',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Thanks,<br />The Your Company Name team</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_3',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '#ffffff',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_31',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'V0iDJFwxRW',
        cells: [1],
        columns: [
          {
            id: '9E63q9AVJE',
            contents: [
              {
                id: 'pXIaGza31W',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_22',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">You received this email because you opted in to receive emails in our location(s), website, reservations, online ordering, payments, or social media. If you wish to unsubscribe, click unsubscribe below.</p>',
                  _languages: {},
                },
              },
              {
                id: 'VmIyMCaHsD',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: false,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                    body: false,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_23',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><a rel="noopener" href="{{unsubscribe_link}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVfbGlua319IiwidGFyZ2V0IjoiX2JsYW5rIn19">Unsubscribe</a></p>',
                  _languages: {},
                },
              },
              {
                id: 'V5k64cuws4',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '13px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_25',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: false,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">{{location_address}}</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              _meta: {
                htmlID: 'u_column_46',
                htmlClassNames: 'u_column',
              },
              border: {},
              padding: '0px',
              borderRadius: '0px',
              backgroundColor: '',
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_34',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: false,
          hideable: true,
        },
      },
    ],
    headers: [],
    footers: [],
    values: {
      _styleGuide: null,
      popupPosition: 'bottom',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: {
        label: 'Arial',
        value: 'arial,helvetica,sans-serif',
      },
      textColor: '#000000',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      language: {},
      backgroundColor: '#F9F9F9',
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      _meta: {
        htmlID: 'u_body',
        description: '',
        htmlClassNames: 'u_body',
      },
    },
  },
  schemaVersion: 17,
}

export const promotionTemplateContent = {
  counters: {
    u_column: 14,
    u_row: 9,
    u_content_text: 15,
    u_content_image: 7,
    u_content_button: 1,
    u_content_divider: 1,
  },
  body: {
    id: 'FiVfm0Gjr_',
    rows: [
      {
        id: 'WOp8E4rycc',
        cells: [1],
        columns: [
          {
            id: 'JF0oqvVurm',
            contents: [
              {
                id: '9mYVRuokYf',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '29px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_1',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #e67e23; line-height: 40.6px;">Comapny Logo</span></p>',
                  _languages: {},
                },
              },
              {
                id: 'iVoAOmsQsd',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://hs-47920320.f.hubspotfree.net/hs/hsstatic/TemplateAssets/static-1.329/img/hs_default_template_images/email_dnd_template_images/promotion-square.png',
                    width: 300,
                    height: 268,
                    autoWidth: false,
                    maxWidth: '52%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  pending: false,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_1',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
            customPosition: ['50%', '50%'],
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_1',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'Z9bJQcT0xZ',
        cells: [1],
        columns: [
          {
            id: 'cU2YzgTsYn',
            contents: [
              {
                id: 'PWrWa4jy4S',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_2',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><span style="color: #236fa1; line-height: 19.6px;">Promote your goods and services</span></p>',
                  _languages: {},
                },
              },
              {
                id: '42YsgJqv6R',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_3',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Use an attention-grabbing headline that explains how your sale will work. You should also include a CTA with a link to your online store or website right away so your contacts can easily start shopping.</p>',
                  _languages: {},
                },
              },
              {
                id: 'jlGpI7jj59',
                type: 'button',
                values: {
                  href: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  buttonColors: {
                    color: '#FFFFFF',
                    backgroundColor: '#3AAEE0',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: {
                    autoWidth: true,
                    width: '100%',
                  },
                  fontSize: '14px',
                  lineHeight: '120%',
                  textAlign: 'center',
                  padding: '10px 20px',
                  border: {},
                  borderRadius: '4px',
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  containerPadding: '10px',
                  anchor: '',
                  _meta: {
                    htmlID: 'u_content_button_1',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Button Text',
                  _languages: {},
                  calculatedWidth: 110,
                  calculatedHeight: 37,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_2',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_2',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'udo2br7o0k',
        cells: [1, 1],
        columns: [
          {
            id: 'gOPpJyrBNc',
            contents: [
              {
                id: 'cYDvjBH31_',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://hs-47920320.f.hubspotfree.net/hs/hsstatic/TemplateAssets/static-1.329/img/hs_default_template_images/email_dnd_template_images/promotion-square.png',
                    width: 300,
                    height: 268,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_image_5',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  pending: false,
                },
              },
              {
                id: 'BSDDhtfcHj',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '18px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_5',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Highlight your best deals</p>',
                  _languages: {},
                },
              },
              {
                id: 'O2Hwt8zs2o',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_8',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Add a brief description for a product or service that’s available in your promotion. Include a link to where the reader can <span style="color: #3598db; line-height: 19.6px;">learn more or shop.</span></p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_9',
                htmlClassNames: 'u_column',
              },
            },
          },
          {
            id: '0Jd_MKTdVN',
            contents: [
              {
                id: 'feKKWxgzGq',
                type: 'image',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  src: {
                    url: 'https://hs-47920320.f.hubspotfree.net/hs/hsstatic/TemplateAssets/static-1.329/img/hs_default_template_images/email_dnd_template_images/promotion-square.png',
                    width: 300,
                    height: 268,
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_image_6',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  pending: false,
                },
              },
              {
                id: '4JGXNo0ytg',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontWeight: 700,
                  fontSize: '18px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_10',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Highlight your best deals</p>',
                  _languages: {},
                },
              },
              {
                id: 'esQjcjSX8Q',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'left',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_12',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Add a brief description for a product or service that’s available in your promotion. Include a link to where the reader can <span style="color: #3598db; line-height: 19.6px;">learn more or shop.</span></p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_10',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_6',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'cDLOiB7xTg',
        cells: [1],
        columns: [
          {
            id: 'ZFMmn3Hrtl',
            contents: [
              {
                id: 'PIosFH21YE',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_9',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="line-height: 140%;">Have questions? Either respond to this email or contact the sender on <span style="color: #3598db; line-height: 19.6px;">youremail@example.com</span></p>',
                  _languages: {},
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: {
                htmlID: 'u_column_13',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '#ffffff',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_31',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'V0iDJFwxRW',
        cells: [1],
        columns: [
          {
            id: '9E63q9AVJE',
            contents: [
              {
                id: 'pXIaGza31W',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_22',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">You received this email because you opted in to receive emails in our location(s), website, reservations, online ordering, payments, or social media. If you wish to unsubscribe, click unsubscribe below.</p>',
                  _languages: {},
                },
              },
              {
                id: 'VmIyMCaHsD',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '14px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: false,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                    body: false,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_23',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;"><a rel="noopener" href="{{unsubscribe_link}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVfbGlua319IiwidGFyZ2V0IjoiX2JsYW5rIn19">Unsubscribe</a></p>',
                  _languages: {},
                },
              },
              {
                id: 'V5k64cuws4',
                type: 'text',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  fontSize: '13px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _styleGuide: null,
                  _meta: {
                    htmlID: 'u_content_text_25',
                    description: '',
                    htmlClassNames: 'u_content_text',
                  },
                  selectable: false,
                  draggable: true,
                  duplicatable: true,
                  deletable: false,
                  hideable: true,
                  text: '<p style="line-height: 140%;">{{location_address}}</p>',
                  _languages: {},
                },
              },
            ],
            values: {
              _meta: {
                htmlID: 'u_column_46',
                htmlClassNames: 'u_column',
              },
              border: {},
              padding: '0px',
              borderRadius: '0px',
              backgroundColor: '',
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          _styleGuide: null,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_34',
            description: '',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: false,
          hideable: true,
        },
      },
    ],
    headers: [],
    footers: [],
    values: {
      _styleGuide: null,
      popupPosition: 'bottom',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: {
        label: 'Arial',
        value: 'arial,helvetica,sans-serif',
      },
      textColor: '#000000',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      language: {},
      backgroundColor: '#F9F9F9',
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      _meta: {
        htmlID: 'u_body',
        description: '',
        htmlClassNames: 'u_body',
      },
    },
  },
  schemaVersion: 17,
}
