import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  ICurrency,
  ICurrencyAdd,
  ICurrencyId,
  ICurrencyListRequest,
  ICurrencyListResponse,
} from 'interfaces/currency'
import httpClient from './base-service'

const endPointBaseURL = `/currency`

const getAllCurrency = async (
  requestBody: ICurrencyListRequest
): Promise<AxiosResponse<IApiSuccessResponse<ICurrencyListResponse>>> =>
  httpClient.post<IApiSuccessResponse<ICurrencyListResponse>>(
    `${endPointBaseURL}/get-currency-list`,
    requestBody
  )

const AddCurrency = async (
  requestBody: ICurrencyAdd
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-currency`,
    requestBody
  )

const UpdateCurrency = async (
  requestBody: ICurrency
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-currency`,
    requestBody
  )

const getCurrencyById = async (
  requestBody: ICurrencyId
): Promise<AxiosResponse<IApiSuccessResponse<ICurrency>>> =>
  httpClient.post<IApiSuccessResponse<ICurrency>>(
    `${endPointBaseURL}/get-currency-by-id`,
    requestBody
  )

const getCurrencies = async (): Promise<
  AxiosResponse<IApiSuccessResponse<ICurrency[]>>
> =>
  httpClient.get<IApiSuccessResponse<ICurrency[]>>(
    `${endPointBaseURL}/get-all-currency`
  )

export default {
  getAllCurrency,
  UpdateCurrency,
  getCurrencyById,
  AddCurrency,
  getCurrencies,
}
