import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'

const DonutChart = ({ x = 0, y = 0 }) => {
  // Ensure x and y are numbers
  const safeX = Number(x) || 0
  const safeY = Number(y) || 0

  let series = [safeX, safeY]
  const options: ApexOptions = {
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 170,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: ['Positive', 'Negative'],
    dataLabels: {
      enabled: true,
      formatter: (val) => `${Number(val)?.toFixed(1)}%`,
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
      style: {
        fontSize: '10px',
        colors: ['#FFFFFF'],
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '12px',
              offsetY: 0,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 'bold',
              offsetY: 0,
            },
          },
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: ['#5ecda5', '#ef818f'],
  }

  if (safeX === 0 && safeY === 0) {
    series = [100]
    options.colors = ['#cccccc']
    options.dataLabels.enabled = false
    options.plotOptions.pie.donut.labels.show = false
  }

  return (
    <div className="app">
      <div className="donut">
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={140}
        />
      </div>
    </div>
  )
}
export default DonutChart
