import Aos from 'aos'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
// import { Checkbox } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Logo } from 'assets/images'
import Button from 'components/Button/Index'
import Textfield from 'components/Textfield/Index'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import userService from 'services/user.service'
import { resolveHTTP } from 'utility/helper'
import CryptoJS from 'crypto-js'
import Checkbox from 'components/Checkbox/Index'
// import ReCAPTCHA from 'react-google-recaptcha'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { setUserName } from '../../features/user/user'
import { ILoginWithEmail } from '../../interfaces/login'
import { EMAIL_REGEX, ROLE_SECRET_KEY } from '../../utility/constants'
import authService from '../../services/auth.service'

Aos.init({
  duration: 600,
  once: true,
})

function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [apikey, setApiKey] = useState('')
  const [isCaptcha, setIsCaptcha] = useState(false)
  const [disableLogin, setDisableLogin] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const initialData: ILoginWithEmail = {
    username: '',
    password: '',
    rememberMe: false,
  }

  const getCaptchaDetails = async () => {
    const data = await authService.getCaptcha()
    if (data.data.data.isCaptchaEnable) {
      setApiKey(data.data.data.captchaKey)
      setIsCaptcha(data.data.data.isCaptchaEnable)
    }
  }
  useEffect(() => {
    getCaptchaDetails()
  }, [])

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: yup
          .string()
          .required('Username is required')
          .matches(EMAIL_REGEX, 'Username is not valid'),
        rememberMe: yup.boolean(),
        password: yup.string().required('Password is required'),
      }),
    []
  )

  const getToken = async () => {
    const token = await authService.getAuthorizationToken()
    return token
  }

  useEffect(() => {
    const token = getToken()
    if (token) {
      navigate('dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = useCallback(
    async (data: ILoginWithEmail) => {
      const { username, password } = data
      const [result] = await resolveHTTP(
        authService.login({ username, password })
      )
      localStorage.clear()
      if (result) {
        const userData = userService.getUserById({
          loginId: result.data.loginId,
        })
        const encrypted = CryptoJS.AES.encrypt(
          (await userData).data.data.roleId.toString(),
          ROLE_SECRET_KEY
        )
        localStorage.setItem('roleId', encrypted.toString())
        if ((await userData).data.data.isTwoFactorEnabled) {
          navigate('/two-factor', {
            state: {
              phoneNumber: (await userData).data.data.phoneNumber,
              data: result.data,
            },
          })
        } else {
          authService.setLoginDetails(
            {
              token: result.data.token,
              refreshToken: result.data.refreshToken,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              loginId: result.data.loginId,
              lastActive: result.data.lastActive,
              isGuestMarketingEnable: result.data.isGuestMarketingEnable,
            },
            data.rememberMe
          )
          toast.success('Logged in successfully')
          dispatch(setUserName({ userName: username }))
          navigate('/dashboard')
        }
      }
    },
    [dispatch, navigate]
  )

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSave(values)
    },
  })

  const enableLoginBtn = () => {
    setDisableLogin(false)
  }

  return (
    <>
      <Helmet>
        <title>Login | FasTab</title>
      </Helmet>
      {/* login-wrapper start */}
      <section className="login-wrapper">
        <section className="left">
          <h1 data-aos="fade-up">Dine the way</h1>
          <p data-aos="fade-up" data-aos-delay="100">
            You want!
          </p>
        </section>
        <section className="right">
          {/* login-box start */}
          <div className="login-box" data-aos="fade-up" data-aos-delay="200">
            <img src={Logo} alt="FasTab" />
            <small>Welcome</small>
            <h4>Login to your account</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <Textfield
                  label="Email"
                  name="username"
                  autofocus
                  value={formik.values?.username}
                  onChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  error={!!formik.errors.username && formik.touched.username}
                  helperText={
                    !!formik.errors.username && formik.touched.username
                      ? formik.errors.username
                      : ''
                  }
                  variant="outlined"
                />
              </div>
              <div className="form-group">
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  variant="outlined"
                  error={!!formik.errors.password && formik.touched.password}
                  helperText={
                    !!formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : ''
                  }
                  value={formik.values?.password}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="remember-section">
                <Checkbox
                  name="rememberMe"
                  label="Remember me"
                  checked={formik.values.rememberMe}
                  onChange={formik.handleChange}
                />
                <Link
                  to="/forgot-password"
                  className="primary-link"
                  title="Forgot password?"
                >
                  Forgot password?
                </Link>
              </div>
              {isCaptcha && (
                <GoogleReCaptchaProvider reCaptchaKey={apikey}>
                  <GoogleReCaptcha onVerify={enableLoginBtn} />
                </GoogleReCaptchaProvider>
              )}
              <br />
              <Button
                variant="contained"
                type="submit"
                title="Login"
                color="primary"
                disabled={isCaptcha ? disableLogin : false}
              >
                Login
              </Button>
            </form>
          </div>
          {/* login-box end */}
        </section>
      </section>
      {/* login-wrapper end */}
    </>
  )
}

export default Login
