import React, { useEffect, useState } from 'react'
import {
  MapContainer,
  TileLayer,
  Polygon,
  Popup,
  Marker,
  useMap,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import guestMarketingService from 'services/guest.marketing.service'
import { GMRestaurant } from 'assets/images'

interface IPostalCodeDetails {
  postalCode: string
  amount: number
  lat: number
  lng: number
}

// Utility function to calculate the distance between two points
const getDistance = (lat1, lng1, lat2, lng2) => {
  const R = 6371 // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLng = ((lng2 - lng1) * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

const createHexagon = (lat, lng, radius) => {
  const angleOffset = 60
  const coordinates = []
  for (let i = 0; i < 6; i += 1) {
    const angle = (angleOffset * i * Math.PI) / 180
    const latOffset = (radius * Math.sin(angle)) / 110.574
    const lngOffset =
      (radius * Math.cos(angle)) / (111.32 * Math.cos((lat * Math.PI) / 180))
    coordinates.push([lat + latOffset, lng + lngOffset])
  }
  coordinates.push(coordinates[0]) // Close the hexagon
  return coordinates
}

async function getCoordinates(postalCode, pcDetails?: IPostalCodeDetails) {
  try {
    if (pcDetails) {
      return { lat: pcDetails.lat, lng: pcDetails.lng }
    }
    const data = await guestMarketingService.getAnalyticsHeatMapDetails({
      postalCode,
    })
    return data?.data?.data
  } catch (error) {
    console.error('Error fetching coordinates:', error)
    return null
  }
}
// Merging hexagons if they are close enough
const mergeHexagons = (hexagons, radius) => {
  const mergedHexagons = []
  hexagons.forEach((hex) => {
    let merged = false

    for (let i = 0; i < mergedHexagons.length; i += 1) {
      const existingHex = mergedHexagons[i]
      const distance = getDistance(
        hex.lat,
        hex.lng,
        existingHex.lat,
        existingHex.lng
      )
      if (distance < radius * 2) {
        // If the hexagons are close enough to be merged
        // Merge hexagons by averaging positions and summing the amounts
        existingHex.lat = (existingHex.lat + hex.lat) / 2
        existingHex.lng = (existingHex.lng + hex.lng) / 2
        existingHex.amount += hex.amount
        existingHex.postalCodes.push(hex.postalCode)
        merged = true
        break
      }
    }

    if (!merged) {
      mergedHexagons.push({ ...hex, postalCodes: [hex.postalCode] })
    }
  })

  return mergedHexagons
}

const HeatMapLayer = ({ postalCodeData, storeLocation }) => {
  const [customerLocations, setCustomerLocations] = useState([])
  const [postalCodeDetails, setPostalCodeData] = useState<IPostalCodeDetails[]>(
    []
  )
  const map = useMap()
  const calculateRadius = () => {
    const zoomLevel = map.getZoom()
    return 2.5 / 2 ** (zoomLevel - 10) // Adjust radius based on zoom level
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const zoom = map.getZoom()

        if (postalCodeData) {
          const zoomLevel = map.getZoom()
          const radius = 2.5 / 2 ** (zoomLevel - 10) // Adjust radius based on zoom
          const customerCoordsPromises = postalCodeData.map(
            async ({ postalCode, amount }) => {
              if (postalCode != null) {
                const pcodeDetails: IPostalCodeDetails = postalCodeDetails.find(
                  (x) => x.postalCode === postalCode
                )
                let coords
                try {
                  coords = await getCoordinates(postalCode, pcodeDetails)
                } catch {
                  console.log('error')
                }
                if (coords) {
                  const currentDetails: IPostalCodeDetails = {
                    postalCode,
                    amount,
                    lat: coords.lat,
                    lng: coords.lng,
                  }
                  if (!pcodeDetails) {
                    const tempDetails = postalCodeDetails
                    tempDetails.push(currentDetails)
                    setPostalCodeData(tempDetails)
                  }
                  if (coords) {
                    return { ...coords, postalCode, amount }
                  }
                }
              }
              return null
            }
          )
          const customerCoords = await Promise.all(customerCoordsPromises)
          const filteredCoords = customerCoords.filter(Boolean)

          const mergedCoords = mergeHexagons(filteredCoords, radius)

          setCustomerLocations(mergedCoords)
        }
      } catch (error) {
        console.error('Error in fetchData:', error)
      }
    }

    fetchData()
    map.on('zoomend', fetchData) // Recalculate on zoom change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCodeData, map])

  const getColorName = (amount: number) => {
    let colorName = '#008cff'
    if (amount > 0 && amount < 100) {
      colorName = '#9ee8f0'
    } else if (amount >= 100 && amount < 499) {
      colorName = '#e7b3ff'
    } else if (amount >= 500 && amount < 999) {
      colorName = '#ff9980'
    }
    return colorName
  }

  return (
    <>
      {customerLocations.map((x) => {
        return (
          <Polygon
            key={`${x.lat}-${x.lng}`}
            pathOptions={{
              color: getColorName(x.amount),
              fillColor: getColorName(x.amount),
              fillOpacity: 0.5,
            }}
            positions={createHexagon(x.lat, x.lng, calculateRadius())}
          >
            <Popup>
              Postal Code:{' '}
              {x.postalCodes.map((code) => code.toUpperCase()).join(', ')}
              <br />
              Amount: {`$${x.amount.toFixed(1)}`}
            </Popup>
          </Polygon>
        )
      })}

      {storeLocation && (
        <Marker
          position={[storeLocation.lat, storeLocation.lng]}
          icon={
            new L.Icon({
              iconUrl: GMRestaurant,
              iconSize: [38, 38],
              iconAnchor: [19, 19],
              popupAnchor: [0, -38],
            })
          }
        >
          <Popup>Store Location</Popup>
        </Marker>
      )}
    </>
  )
}

const HeatMap = ({ postalCodeData, storePostalCode }) => {
  const [storeLocation, setStoreLocation] = useState(null)
  const [storePostalCodeSave, setStorePostalCode] = useState<
    IPostalCodeDetails[]
  >([])

  useEffect(() => {
    const fetchStoreData = async () => {
      const pcodeDetails: IPostalCodeDetails = storePostalCodeSave.find(
        (x) => x.postalCode === storePostalCode
      )
      const storeCoords = await getCoordinates(storePostalCode, pcodeDetails)
      if (!pcodeDetails) {
        const currentDetails: IPostalCodeDetails = {
          postalCode: storePostalCode,
          lat: storeCoords.lat,
          lng: storeCoords.lng,
          amount: 0,
        }
        setStorePostalCode([...storePostalCodeSave, currentDetails])
      }
      setStoreLocation(storeCoords)
    }
    fetchStoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storePostalCode])

  return (
    <div style={{ position: 'relative', width: '100%', height: '500px' }}>
      {storeLocation && (
        <MapContainer
          center={[storeLocation.lat, storeLocation.lng]}
          zoom={13}
          minZoom={3}
          maxZoom={18}
          scrollWheelZoom
          style={{ width: '100%', height: '500px' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <HeatMapLayer
            postalCodeData={postalCodeData}
            storeLocation={storeLocation}
          />
        </MapContainer>
      )}
    </div>
  )
}

export default HeatMap
