import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IState,
  IStateAdd,
  IStateId,
  IStateListRequest,
  IStateListResponse,
} from 'interfaces/state'
import httpClient from './base-service'

const endPointBaseURL = `/state`

const getAllState = async (
  requestBody: IStateListRequest
): Promise<AxiosResponse<IApiSuccessResponse<IStateListResponse>>> =>
  httpClient.post<IApiSuccessResponse<IStateListResponse>>(
    `${endPointBaseURL}/get-state-list`,
    requestBody
  )

const AddState = async (
  requestBody: IStateAdd
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/add-state`,
    requestBody
  )

const UpdateState = async (
  requestBody: IStateAdd
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.put<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-state`,
    requestBody
  )

const getStateById = async (
  requestBody: IStateId
): Promise<AxiosResponse<IApiSuccessResponse<IState>>> =>
  httpClient.post<IApiSuccessResponse<IState>>(
    `${endPointBaseURL}/get-state-by-id`,
    requestBody
  )

export default { getAllState, UpdateState, getStateById, AddState }
