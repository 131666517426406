import { Collapse, Grid } from '@mui/material'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro'
import Aos from 'aos'
import { Edit, FilterNew, PlusWhite } from 'assets/images'
import Button from 'components/Button/Index'
import DataTable from 'components/DataTable/Index'
import Layout from 'components/Layout/Layout'
import Textfield from 'components/Textfield/Index'
import { IDatagrid } from 'interfaces/datagrid'
import { useCallback, useEffect, useState } from 'react'
import { ICountry, ICountryListRequest } from 'interfaces/country'
import countryService from 'services/country.service'
import AddEditCountryDialog from './AddEditCountry'

Aos.init({
  duration: 600,
  once: true,
})

function Country() {
  const [filterToggle, setFilterToggle] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [countryName, setCountryName] = useState('')
  const [editCountryData, setEditCountryData] = useState<ICountry>()
  const [totalRecords, setTotalRecords] = useState(0)
  const [editMode, setEditMode] = useState(false)

  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 50,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })

  const getCountryList = useCallback(
    async (
      pageNo: number,
      pageSize: number,
      sortOrder: GridSortModel,
      currName: string
    ) => {
      const response = await countryService.getAllCountry({
        pageNo,
        pageSize,
        sortBy: 'string',
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        countryName: currName,
      })

      setCountryList(response.data.data.data)
      setTotalRecords(response.data.data.totalRecords)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const generatePayload = () => {
    const payload: ICountryListRequest = {
      pageNo: dataGridOptions.pageNumber,
      pageSize: dataGridOptions.pageSize,
      sortGridModels: dataGridOptions.sortOrder.map((d) => {
        return {
          field: d.field,
          sort: d.sort === 'asc' ? 1 : 0,
        }
      }),
      countryName,
    }
    return payload
  }
  useEffect(() => {
    getCountryList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      countryName
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridOptions])

  const getNewCountryList = () => {
    getCountryList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      countryName
    )
  }

  const [openAddEditCountryDialog, setOpenAddEditCountryDialog] =
    useState(false)
  const closeAddEditCountryDialog = () => {
    setOpenAddEditCountryDialog(false)
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }

  const onFilterApply = async () => {
    const payload = generatePayload()
    payload.pageNo = 1
    const data = await countryService.getAllCountry(payload)
    setCountryList(data.data.data.data)

    setTotalRecords(data.data.data.totalRecords)
  }

  const onFilterClear = () => {
    setCountryName('')
    getCountryList(
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      dataGridOptions.sortOrder,
      ''
    )
  }

  return (
    <>
      <Layout title="Country | FasTab">
        {/* main-content start */}
        <section className="main-content">
          {/* page-top start */}
          <div className="page-top">
            <h3 className="page-title">Country</h3>
            <div className="right">
              <Button
                variant="outlined"
                color="inherit"
                disableFocusRipple
                title="Filter"
                size="small"
                className="btn-h-40"
                onClick={() => {
                  setFilterToggle(!filterToggle)
                }}
              >
                <img src={FilterNew} alt="Filter" />
                <span>Filter</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                title="New Country"
                size="small"
                onClick={() => {
                  setEditMode(false)
                  setOpenAddEditCountryDialog(true)
                }}
              >
                <img src={PlusWhite} alt="ADD" />
                <span>New Country</span>
              </Button>
            </div>
          </div>
          {/* page-top end */}
          {/* filter-panel start */}
          <Collapse in={filterToggle}>
            <div className="filter-panel">
              <div className="card">
                <Grid container spacing={5}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <div className="form-group">
                      <Textfield
                        label="countryName"
                        type="countryName"
                        variant="outlined"
                        value={countryName}
                        onChange={(e) => {
                          setCountryName(e.target.value)
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="btn-list">
                  <Button
                    variant="contained"
                    color="primary"
                    title="Apply"
                    onClick={onFilterApply}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    title="Clear All"
                    onClick={onFilterClear}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
            </div>
          </Collapse>
          {/* filter-panel end */}

          <div className="card mb-30 p-0" data-aos="fade-up">
            {/* data-table start */}
            <DataTable
              uniqueId="countryId"
              columnsData={[
                {
                  field: 'countryName',
                  headerName: 'Country Name',
                  flex: 1,
                  minWidth: 250,
                },
                {
                  align: 'center',
                  headerAlign: 'center',
                  field: 'action',
                  disableColumnMenu: true,
                  headerName: 'Action',
                  sortable: false,
                  renderCell: (d: GridRenderCellParams<number>) => {
                    return (
                      <div className="action-buttons">
                        <Button
                          className="icon-btn light-bordered small rounded"
                          title="Edit"
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            setEditMode(true)
                            setEditCountryData(d.row)
                            setOpenAddEditCountryDialog(true)
                          }}
                        >
                          <img src={Edit} alt="Edit" />
                        </Button>
                      </div>
                    )
                  },
                  flex: 1,
                  minWidth: 120,
                },
              ]}
              rowsData={countryList}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortChange}
              totalRecords={totalRecords}
            />
          </div>
          {/* data-table end */}
        </section>
        <></>
        {/* main-content end */}
      </Layout>
      {/* wrapper end */}
      <AddEditCountryDialog
        open={openAddEditCountryDialog}
        onClose={closeAddEditCountryDialog}
        editMode={editMode}
        country={editCountryData}
        closeDialog={closeAddEditCountryDialog}
        getNewList={getNewCountryList}
      />
    </>
  )
}

export default Country
