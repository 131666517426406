/* eslint-disable prefer-template */
import { Collapse, Grid } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import Aos from 'aos'
import {
  DownloadDisbursement,
  ExportNew,
  FilterNew,
  GenerateDisbursement,
} from 'assets/images'
import AddNoteDialog from 'components/AddNoteDialog/Index'
import AlertDialog from 'components/AlertDialog/Index'
import Button from 'components/Button/Index'
import Checkbox from 'components/Checkbox/Index'
import Datepicker from 'components/Datepicker/Index'
import FasTabGrid from 'components/FasTabGrid/FasTabGrid'
import Layout from 'components/Layout/Layout'
import MarkAsDisbursedConfirmation from 'components/MarkAsDisbursedConfirmation/Index'
import Select from 'components/Select/Index'
import Textfield from 'components/Textfield/Index'
import { selectedEstablishment } from 'features/establishment/establishment'
import { selectedRestaurant } from 'features/restaurant/restaurant'
import { IDatagrid } from 'interfaces/datagrid'
import { IDisbursement, IDisbursementRequest } from 'interfaces/disbursement'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux/es/exports'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import disbursemetService from 'services/disbursement.service'
import restaurantService from 'services/restaurant.service'
import { STRIPE_CONNECT_FILTER_OPTIONS } from 'utility/constants'
import { getPrevMonday, getPrevSunday } from 'utility/helper'

Aos.init({
  duration: 600,
  once: true,
})

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, filename: string) => void
  }
}

function Disbursement() {
  const [filterToggle, setFilterToggle] = useState(true)
  const [restaurantList, setRestaurantList] = useState<IDisbursement[]>([])
  const [status, setStatus] = useState([])
  const [isCurrentData, setIsCurrentData] = useState(true)
  const [restaurantIds, setRestaurnatIds] = useState([])
  const [establishmentIds, setEstablishmentIds] = useState([])
  const [payEdgeOrders, setPayEdgeOrders] = useState([])
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [disbursementFilterSelectedDate, setDisbursementFilterSelectedDate] =
    useState(null)
  const [
    disbursementFilterSelectedStartDate,
    setDisbursementFilterSelectedStartDate,
  ] = useState(null)
  const [
    disbursementFilterSelectedEndDate,
    setDisbursementFilterSelectedEndDate,
  ] = useState(null)

  const [restaurantFilterSelectedStatus, setRestaurantFilterSelectedStatus] =
    useState(2)
  const [totalRecords, setTotalRecords] = useState(0)
  const [dataGridOptions, setDataGridOptions] = useState<IDatagrid>({
    pageNumber: 1,
    pageSize: 0,
    sortOrder: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  })
  const selectedRestaurantId = useSelector(selectedRestaurant)
  const selectedEstablishmentId = useSelector(selectedEstablishment)
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const [payEdgeOrderNumber, setPayEdgeOrderNumber] = useState(null)
  const [openNoteDialog, setOpenNoteDialog] = useState(false)
  const [
    openMarkAsDisbursedConfirmationDialog,
    setOpenMarkAsDisbursedConfirmationDialog,
  ] = useState(false)

  const [
    stripeConnectFilterSelectedStatus,
    setStripeConnectFilterSelectedStatus,
  ] = useState(0)
  const closeAlertDialog = () => {
    setOpenAlertDialog(false)
  }
  const closeNoteDialog = () => {
    setOpenNoteDialog(false)
  }
  const closeMarkAsDisbursedConfirmationDialog = () => {
    setOpenMarkAsDisbursedConfirmationDialog(false)
  }
  const setLocalStorageParams = (restaurantId, payEdgeId) => {
    localStorage.setItem('fromDisbursement', 'true')
    localStorage.setItem('dispayEdgeId', payEdgeId)
    localStorage.setItem('disRestaurantId', restaurantId)
    localStorage.setItem(
      'disbursementStatus',
      restaurantFilterSelectedStatus.toString()
    )
    localStorage.setItem(
      'disStartDate',
      disbursementFilterSelectedStartDate || getPrevMonday()
    )
    localStorage.setItem(
      'disEndDate',
      disbursementFilterSelectedEndDate || getPrevSunday()
    )
  }
  const getRestaurantListColumnDef = (): GridColDef[] => {
    return [
      {
        field: 'restaurnatName',
        headerName: 'Restaurant Name',
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'establishmentName',
        headerName: 'Location',
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'disbursementPeriod',
        headerName: 'Disbursement Period',
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'noOfPayment',
        headerName: 'Number of Payments',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<number>) => {
          return (
            <Link
              to="/payments"
              onClick={() =>
                setLocalStorageParams(
                  params.row.restaurantId,
                  params.row.payEdgeOrderId
                )
              }
              style={{
                color: '#008cff',
              }}
              target="_blank"
            >
              {params.value}
            </Link>
          )
        },
      },
      {
        field: 'numberOfStripePayouts',
        headerName: 'Number of Stripe Payouts',
        flex: 1,
        minWidth: 230,
      },
      {
        field: 'totalToDisburse',
        headerName: 'Total to Disburse ',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<number>) => {
          return `$${params.value.toFixed(2)}`
        },
      },
      {
        field: 'payEdgeOrderId',
        headerName: 'PayEdge Order Id ',
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<number>) => {
          return params.value ? params.value : '-'
        },
      },
      {
        field: 'distbursmentStatus',
        headerName: 'Disbursement Status',
        flex: 1,
        minWidth: 180,
      },
    ]
  }

  const getDisbursementList = useCallback(
    async (
      date: Date,
      endDate: Date,
      id: number,
      isRecentData: boolean,
      pageNo: number,
      pageSize: number,
      payEdgeOrderId,
      sortOrder: GridSortModel,
      stripeConnectFilter?: number,
      establishmentId?: number
    ) => {
      const payload: IDisbursementRequest = {
        weekStartDate: date.toDateString(),
        weekEndDate: endDate.toDateString(),
        restaurantId: selectedRestaurantId,
        establishmentId: selectedEstablishmentId || establishmentId,
        disbursmentStatusId: isRecentData ? 2 : id,
        pageNo,
        pageSize,
        payEdgeOrderId: isRecentData ? null : payEdgeOrderId,
        sortGridModels: sortOrder.map((d) => {
          return {
            field: d.field,
            sort: d.sort === 'asc' ? 1 : 0,
          }
        }),
        isCurrent: isRecentData,
        stripeConnectFilter,
      }
      const data = await disbursemetService.getDisbursmentList(payload)
      if (data.data.data.data) {
        data.data.data.data.map((element, index) => {
          element.uniqueId = index
          return element
        })
        setRestaurantList(data.data.data.data)
      }
      setTotalRecords(data.data.data.totalRecords)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId, selectedEstablishmentId]
  )
  const getNewDisbursementList = () => {
    getDisbursementList(
      disbursementFilterSelectedStartDate || getPrevMonday(),
      disbursementFilterSelectedEndDate || getPrevSunday(),
      restaurantFilterSelectedStatus,
      isCurrentData,
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      payEdgeOrderNumber,
      dataGridOptions.sortOrder,
      stripeConnectFilterSelectedStatus
    )
  }

  const getStatusList = useCallback(
    async () => {
      const data = await disbursemetService.getAllDisbursementStatus()
      const list = data.data.data.disbursmenStatustResponse.map((e) => {
        return {
          key: e.name,
          value: e.payoutDisbursementStatusId,
        }
      })
      list.splice(0, 0, {
        key: 'All',
        value: 0,
      })
      setStatus(list)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRestaurantId]
  )

  useEffect(() => {
    getDisbursementList(
      disbursementFilterSelectedStartDate || getPrevMonday(),
      disbursementFilterSelectedEndDate || getPrevSunday(),
      restaurantFilterSelectedStatus,
      isCurrentData,
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      payEdgeOrderNumber,
      dataGridOptions.sortOrder,
      stripeConnectFilterSelectedStatus,
      selectedEstablishmentId
    )
    setSelectionModel([])
    getStatusList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataGridOptions.pageNumber,
    dataGridOptions.sortOrder,
    selectedRestaurantId,
    selectedEstablishmentId,
  ])

  useEffect(() => {
    async function restaurantListFilter() {
      let pageNo = 1
      const pageSize = 10
      let data = await restaurantService.getAllResturants({
        pageNo,
        pageSize,
        sortGridModels: [
          {
            field: 'name',
            sort: 1,
          },
        ],
      })
      let restaurantData = data.data.data.data
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let { totalRecords } = data.data.data
      if (totalRecords > pageSize) {
        while (totalRecords >= 0) {
          pageNo += 1
          // eslint-disable-next-line no-await-in-loop
          data = await restaurantService.getAllResturants({
            pageNo,
            pageSize,
            sortGridModels: [
              {
                field: 'name',
                sort: 1,
              },
            ],
          })
          restaurantData = restaurantData.concat(data.data.data.data)
          totalRecords -= pageSize
        }
      }
    }
    if (filterToggle) restaurantListFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterToggle, totalRecords])

  const onFilterApply = async () => {
    getDisbursementList(
      disbursementFilterSelectedStartDate || getPrevMonday(),
      disbursementFilterSelectedEndDate || getPrevSunday(),
      restaurantFilterSelectedStatus,
      isCurrentData,
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      payEdgeOrderNumber,
      dataGridOptions.sortOrder,
      stripeConnectFilterSelectedStatus,
      selectedEstablishmentId
    )
  }

  const onFilterClear = () => {
    const prevMon = getPrevMonday()
    const prevSunday = getPrevSunday()
    setDisbursementFilterSelectedStartDate(prevMon)
    setDisbursementFilterSelectedEndDate(prevSunday)
    setDisbursementFilterSelectedDate(null)
    setPayEdgeOrderNumber(null)
    setIsCurrentData(true)
    setRestaurantFilterSelectedStatus(2)
    setStripeConnectFilterSelectedStatus(0)
    getDisbursementList(
      prevMon,
      prevSunday,
      2,
      isCurrentData,
      dataGridOptions.pageNumber,
      dataGridOptions.pageSize,
      null,
      dataGridOptions.sortOrder,
      stripeConnectFilterSelectedStatus,
      selectedEstablishmentId
    )
  }

  const onPageChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageNumber = value
    setDataGridOptions(options)
  }

  const onPageSizeChange = (value: number) => {
    const options = { ...dataGridOptions }
    options.pageSize = value
    setDataGridOptions(options)
  }

  const onSortChange = (sortModel: GridSortModel) => {
    const options = { ...dataGridOptions }
    options.sortOrder = sortModel
    setDataGridOptions(options)
  }
  const onSelectionModelChange = (selectionModelData: GridSelectionModel) => {
    setSelectionModel(selectionModelData)
    setRestaurnatIds(
      selectionModelData.map((d) => restaurantList[d].restaurantId)
    )
    setEstablishmentIds(
      selectionModelData.map((d) => restaurantList[d].establishmentId)
    )
    setPayEdgeOrders(
      selectionModelData.map((d) => restaurantList[d].payEdgeOrderId)
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exportCSVFile = (
    headers: {
      Name: string // remove commas to avoid errors
      EmailAddress: string
      WebsiteLink: string
      CreatedDate: string
    },
    csv:
      | string
      | Blob
      | import('../../interfaces/restaurant').ICsvDownload
      | ArrayBufferView
      | ArrayBuffer,
    fileTitle: string
  ) => {
    const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

    const blob = new Blob([csv as BlobPart], {
      type: 'text/csv;charset=utf-8;',
    })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilenmae)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  const handleFileGenerate = () => {
    if (selectionModel.map((d) => Number(d)).length === 0) {
      toast.error('Please select Restaurant(s) to Generate Disbursement File.')
    } else {
      setOpenNoteDialog(true)
    }
  }

  const markAsDisbursed = async () => {
    if (selectionModel.map((d) => Number(d)).length === 0) {
      toast.error('Please select Restaurant(s) to mark as Disbursed.')
    } else {
      const validData = selectionModel
        .map(
          (d) =>
            !!restaurantList.find(
              (g) =>
                g.uniqueId === d && g.distbursmentStatus === 'Pending disbursed'
            )
        )
        .every((element) => element === true)
      if (validData) {
        setOpenMarkAsDisbursedConfirmationDialog(true)
      } else {
        toast.error(
          'Selected Restaurant(s) should be with Pending Disbursement status.'
        )
      }
    }
  }

  const downloadFile = async () => {
    if (selectionModel.map((d) => Number(d)).length === 0) {
      toast.error('Please select Restaurant(s) to download CSV file.')
    } else {
      const data = await disbursemetService.downloadCsv({
        restaurantDisbursementStatus: selectionModel.map((d) => {
          const currentData = restaurantList.find((res) => res.uniqueId === d)
          let disbursementStatusId = 0
          switch (currentData.distbursmentStatus) {
            case 'Pending disbursed':
              disbursementStatusId = 2
              break
            case 'Disbursed':
              disbursementStatusId = 5
              break
            default:
              break
          }
          return {
            disbursementStatusId: isCurrentData ? 2 : disbursementStatusId,
            restaurantId: currentData.restaurantId,
            establishmentId: currentData.establishmentId,
            payEdgeOrderId: currentData.payEdgeOrderId,
          }
        }),
        payEdgeOrderIds: payEdgeOrders,
        statusId: isCurrentData ? 2 : restaurantFilterSelectedStatus,
        paymentDate:
          disbursementFilterSelectedDate ||
          new Date(Date.now() - 86400000).toISOString().split('T')[0],
        weekStartDate: new Date(
          disbursementFilterSelectedStartDate || getPrevMonday()
        ).toDateString(),
        weekEndDate: new Date(
          disbursementFilterSelectedEndDate || getPrevSunday()
        ).toDateString(),
        globalFilterRestaurantId: selectedRestaurantId,
        globalFilterEstablishmentId: selectedEstablishmentId,
        isCurrent: isCurrentData,
      })

      const headers = {
        Name: 'Name'.replace(/,/g, ''), // remove commas to avoid errors
        EmailAddress: 'Email'.replace(/,/g, ''),
        WebsiteLink: 'Website'.replace(/,/g, ''),
        CreatedDate: 'Date'.replace(/,/g, ''),
      }
      const date = new Date(
        disbursementFilterSelectedDate ||
          new Date(Date.now() - 86400000).toISOString().split('T')[0]
      )
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const fileTitle = `FT_Disbursement_${month}${day}${year}_${date.getHours()}${date.getMinutes()}`
      exportCSVFile(headers, data.data.data, fileTitle)
    }
  }

  // convert date to local time
  const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    return newDate
  }

  return (
    <>
      <Layout title="Disbursements | FasTab">
        {/* page-top start */}
        <div className="page-top long-right-content">
          <h3 className="page-title">Disbursements</h3>
          <div className="right">
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Mark as disbursed"
              size="small"
              className="btn-h-40"
              onClick={() => markAsDisbursed()}
            >
              <img src={DownloadDisbursement} alt="File" />
              <span>Mark as disbursed</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Generate disbursement file"
              size="small"
              className="btn-h-40"
              onClick={() => handleFileGenerate()}
            >
              <img src={GenerateDisbursement} alt="File" />
              <span>Generate disbursement file</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Export"
              size="small"
              className="btn-h-40"
              onClick={() => downloadFile()}
            >
              <img src={ExportNew} alt="File" />
              <span>Export</span>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              disableFocusRipple
              title="Filter"
              size="small"
              className="btn-h-40"
              onClick={() => {
                setFilterToggle(!filterToggle)
              }}
            >
              <img src={FilterNew} alt="Filter" />
              <span>Filter</span>
            </Button>
          </div>
        </div>
        {/* page-top end */}
        {/* filter-panel start */}
        <Collapse in={filterToggle}>
          <div className="filter-panel">
            <div className="card">
              <Grid container spacing={5}>
                <Grid item lg={2.4} sm={6} xs={12}>
                  <div className="form-group">
                    <Datepicker
                      datePickerlabel="Start Date"
                      value={
                        disbursementFilterSelectedStartDate || getPrevMonday()
                      }
                      onChange={(date) => {
                        setDisbursementFilterSelectedStartDate(
                          convertUTCDateToLocalDate(date).toISOString()
                        )
                      }}
                      setFilterSelectedDate={
                        setDisbursementFilterSelectedStartDate
                      }
                      disabled={isCurrentData}
                    />
                  </div>
                </Grid>
                <Grid item lg={2.4} sm={6} xs={12}>
                  <div className="form-group">
                    <Datepicker
                      datePickerlabel="End Date"
                      value={
                        disbursementFilterSelectedEndDate || getPrevSunday()
                      }
                      onChange={(date) => {
                        setDisbursementFilterSelectedEndDate(
                          convertUTCDateToLocalDate(date).toISOString()
                        )
                      }}
                      setFilterSelectedDate={
                        setDisbursementFilterSelectedEndDate
                      }
                      disabled={isCurrentData}
                    />
                  </div>
                </Grid>
                <Grid item lg={2.4} sm={6} xs={12}>
                  <div className="form-group">
                    <Select
                      label="Disbursement Status"
                      items={status?.length > 0 ? status : []}
                      formikValue={restaurantFilterSelectedStatus}
                      handleSelectValue={(value) => {
                        setRestaurantFilterSelectedStatus(value)
                      }}
                      disabled={isCurrentData}
                    />
                  </div>
                </Grid>
                <Grid item lg={2.4} sm={6} xs={12}>
                  <div className="form-group">
                    <Textfield
                      label="PayEdge order Id"
                      variant="outlined"
                      value={
                        payEdgeOrderNumber === null ||
                        payEdgeOrderNumber === undefined
                          ? ''
                          : payEdgeOrderNumber
                      }
                      onChange={(e) => {
                        setPayEdgeOrderNumber(e.target.value)
                      }}
                      disable={isCurrentData}
                    />
                  </div>
                </Grid>
                <Grid item lg={2.4} sm={6} xs={12}>
                  <div className="form-group">
                    <Select
                      label="Stripe Connect Filter"
                      items={STRIPE_CONNECT_FILTER_OPTIONS}
                      formikValue={stripeConnectFilterSelectedStatus}
                      handleSelectValue={(value) => {
                        setStripeConnectFilterSelectedStatus(value)
                      }}
                      disabled={isCurrentData}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <Checkbox
                    name="recentData"
                    label="Current Week's Disbursment List"
                    classes="include-pos-checkbox"
                    checked={isCurrentData}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setIsCurrentData(event.target.checked)
                    }
                  />
                </Grid>
              </Grid>
              <div className="btn-list">
                <Button
                  variant="contained"
                  color="primary"
                  title="Apply"
                  onClick={onFilterApply}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  title="Clear All"
                  onClick={onFilterClear}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
        {/* filter-panel end */}

        <div className="card mb-30 p-0" data-aos="fade-up">
          {/* data-table start */}
          <FasTabGrid
            checkboxSelection
            uniqueId="uniqueId"
            columnsData={getRestaurantListColumnDef()}
            gridData={restaurantList}
            totalRecords={totalRecords}
            onGridPageChange={onPageChange}
            onGridPageSizeChange={onPageSizeChange}
            onGridSortChange={onSortChange}
            onSelectionModelChange={onSelectionModelChange}
          />
        </div>
        {/* data-table end */}
      </Layout>
      {/* wrapper end */}

      {/* user-alert-dialog */}
      <AlertDialog
        onClose={closeAlertDialog}
        open={openAlertDialog}
        isFromRestaurant
        isFromLocation={false}
      />
      <AddNoteDialog
        onClose={closeNoteDialog}
        open={openNoteDialog}
        selectedRestaurants={restaurantIds}
        selectedEstablishmets={establishmentIds}
        weekStartDate={disbursementFilterSelectedStartDate || getPrevMonday()}
        weekEndDate={disbursementFilterSelectedEndDate || getPrevSunday()}
        isCurrent={isCurrentData}
      />
      <MarkAsDisbursedConfirmation
        onClose={closeMarkAsDisbursedConfirmationDialog}
        open={openMarkAsDisbursedConfirmationDialog}
        selectedRestaurants={restaurantIds}
        selectedEstablishments={establishmentIds}
        paymentDate={
          disbursementFilterSelectedDate || new Date(Date.now() - 86400000)
        }
        getNewList={getNewDisbursementList}
        weekStartDate={disbursementFilterSelectedStartDate || getPrevMonday()}
        weekEndDate={disbursementFilterSelectedEndDate || getPrevSunday()}
        isCurrent={isCurrentData}
      />
    </>
  )
}

export default Disbursement
